/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import styled from "styled-components";
import anime from "animejs";
import { device } from "../../utils/variables";
import { detect } from "detect-browser";
import { GreenLine } from "../UI";
import { Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../store/configureStore";
import { connect } from "react-redux";
import { AppActions } from "../../types";
import {
  setCountLimit,
  increaseMultiCardCount,
  decreaseMultiCardCount,
  setMultiCardCount,
  setMultiLineCardLineCount,
} from "../../actions";
import DOMPurify from "dompurify";

const browser = detect();

interface OldMultiLineCardProps {
  title: string;
  nextPageAnimation: boolean;
  selectedCard: boolean;
  // animate_text: boolean;
  lastPage: boolean;
  enableCardNavigation: () => void;
  backClick: () => void;
  nextClick: () => void;
  directionFlow: string;
  setDirection: (direction: string) => void;
  showAllLines: boolean;
}

interface OldMultiLineCardState {
  lineCount: number;
  lines: any[];
}

type Props = OldMultiLineCardProps & LinkDispatchProps & LinkStateProps;

class OldMultiLineCard extends React.Component<Props, OldMultiLineCardState> {
  textTransition = React.createRef() as any;

  constructor(props: Props) {
    super(props);
    this.state = {
      lineCount: 0,
      lines: [],
    };
  }

  componentDidMount = () => {
    const {
      setCountLimit,
      directionFlow,
      setMultiCardCount,
      setMultiLineCardLineCount,
    } = this.props;
    let pTags = document.getElementsByTagName("p");
    let lines = [];
    for (let i = 0, length = pTags.length; i < length; i++) {
      if (pTags[i].className.indexOf("line") >= 0) {
        lines.push(pTags[i]);
      }
    }
    this.setState({
      lineCount: lines.length,
      lines: lines,
    });
    console.log("lines.lenght", lines.length);
    setCountLimit(lines.length);
    setMultiLineCardLineCount(lines.length);
    if (directionFlow === "right") {
      setMultiCardCount(0);
    }
    if (directionFlow === "left") {
      setMultiCardCount(lines.length);
    }
  };
  componentDidUpdate = (prevProps: OldMultiLineCardProps, prevState: any) => {
    if (
      this.props.selectedCard &&
      prevProps.selectedCard !== this.props.selectedCard
    ) {
      setMultiLineCardLineCount(this.state.lineCount);
      if (this.props.directionFlow === "right") {
        setMultiCardCount(0);
      }
      if (this.props.directionFlow === "left") {
        setMultiCardCount(this.state.lineCount);
      }
    }
  };

  handleAnimation = () => {
    anime({
      targets: this.textTransition.current,
      duration: 150,
      translateX: this.props.nextPageAnimation ? [0, 15] : [0, -15],
      direction: "reverse",
      easing: "linear",
    });
  };

  increaseClickCount = () => {
    if (this.props.limit > this.props.clickCount) {
      this.props.increaseCount();
    }
  };

  decreaseClickCount = () => {
    if (this.props.clickCount > 0) {
      this.props.decreaseCount();
    }
  };

  clickNextHandler = () => {
    this.props.setDirection("right");
    // this.increaseClickCount();
    if (this.props.clickCount < this.props.limit) {
      let className = ".line" + (this.props.clickCount + 1);
      document.querySelector(className)?.classList.remove("slideOutAnimation");
      document.querySelector(className)?.classList.add("slideInAnimation");
    }

    if (this.props.clickCount >= this.props.limit) {
      this.props.enableCardNavigation();
    }
  };

  clickBackHandler = () => {
    if (this.props.clickCount === 0) {
      for (let i = 1, length = this.state.lineCount; i <= length; i++) {
        let className = ".line" + i;
        document.querySelector(className)?.classList.remove("slideInAnimation");
        document
          .querySelector(className)
          ?.classList.remove("slideOutAnimation");
      }
      this.props.backClick();
      this.props.enableCardNavigation();
    }
    let lineNumber = this.props.limit - this.props.clickCount;
    this.props.setDirection("left");

    // this.decreaseClickCount();
    if (lineNumber <= this.props.limit) {
      let className = ".line" + this.props.clickCount;
      document.querySelector(className)?.classList.remove("slideInAnimation");
      document.querySelector(className)?.classList.add("slideOutAnimation");
    }
  };

  render() {
    const {
      title,
      nextPageAnimation,
      lastPage,
      directionFlow,
      clickCount,
      limit,
    } = this.props;

    return (
      <TextContainer>
        <HiddenContainer>
          <HiddenBackContainer onClick={this.clickBackHandler} />
          <HiddenNextContainer onClick={this.clickNextHandler} />
        </HiddenContainer>
        <FirstLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
            disabled={clickCount !== 0 || clickCount < limit}
          />
        </FirstLineAnimationDiv>
        {/* <Box ref={this.textTransition} /> */}
        <TextWrapper ref={this.textTransition}>
          {title && (
            <TextDiv
              lastPage={lastPage}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(title),
              }}
            />
          )}
        </TextWrapper>
        <SecondLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
            disabled={clickCount !== 0 || clickCount < limit}
          />
        </SecondLineAnimationDiv>
      </TextContainer>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

const HiddenContainer = styled.div`
  z-index: 55;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media ${device.minDesktop} {
    left: 50%;
    width: 400px;
    margin-left: -200px;
  }
  @media ${device.ipadLandScape} {
    width: 360px;
  }
`;
const HiddenNextContainer = styled.div`
  z-index: 55;
  height: 100%;
  width: 63%;
  /* background-color: lightgreen;
  opacity: 0.5; */
  @media ${device.mobileL} {
    width: 63%;
  }
  @media ${device.minDesktop} {
    width: 66%;
  }
`;
const HiddenBackContainer = styled.div`
  z-index: 55;
  height: 100%;
  width: 37%;
  background: transparent;
  /* background-color: lightpink;
  opacity: 0.5; */
  @media ${device.mobileL} {
  }
  @media ${device.minDesktop} {
    width: 34%;
  }
`;

export const Box = styled.div`
  height: 50px;
  width: 50px;
  background-color: red;
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: -1;
  position: relative;
  /* height: 40%; */
  width: 100%;
  height: 100%;
  align-items: center;
  /* background-color: lightcoral; */
  /* @media ${device.laptop} {
    margin-top: 45vh;
  }
  @media ${device.minDesktop} {
    margin-top: 45vh;
  } */
`;

interface TextDivProps {
  lastPage: boolean;
}

export const TextDiv = styled.div<TextDivProps>`
  z-index: -1;
  height: 100%;
  font-family: SentinelSSm-Book;
  /* Disables text highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

export const TextWrapper = styled.div`
  z-index: -1;
  position: absolute;
  width: 90%;
  padding: 0px 20px;
`;

export const FirstLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  top: 2.5vh;
  position: absolute;
`;

export const SecondLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  /* margin-top: 375px; */
  position: absolute;
  bottom: 2.5vh;
  /* @media ${device.ultraWidemin} {
    margin-top: 30vh;
  }
  @media ${device.ultraWidemin +
  "and (min-height: 1500px)"} {
    margin-top: 18vh;
  } */
`;

interface LinkDispatchProps {
  setCountLimit: (count: number) => void;
  increaseCount: () => void;
  decreaseCount: () => void;
  setMultiCardCount: (count: number) => void;
  setMultiLineCardLineCount: (lines: number) => void;
}

interface LinkStateProps {
  clickCount: number;
  limit: number;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setCountLimit: bindActionCreators(setCountLimit, dispatch),
  increaseCount: bindActionCreators(increaseMultiCardCount, dispatch),
  decreaseCount: bindActionCreators(decreaseMultiCardCount, dispatch),
  setMultiCardCount: bindActionCreators(setMultiCardCount, dispatch),
  setMultiLineCardLineCount: bindActionCreators(
    setMultiLineCardLineCount,
    dispatch
  ),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  clickCount: state.nodeReducer.multiLineClickCount,
  limit: state.nodeReducer.multiLineLimit,
});

export default connect(mapStateToProps, mapDispatchToProps)(OldMultiLineCard);
