import styled from "styled-components";

export interface AppTextProps {
  color?: string | undefined;
  fontSize?: string;
  fontWeight?: string | undefined;
  lineHeight?: string | undefined;
  textAlign?: string | undefined;
  margin?: string | undefined;
  ellipsis?: boolean;
  clickable?: boolean;
}

export const AppText = styled.p<AppTextProps>`
  color: ${props => props.color};
  font-weight: ${(props: AppTextProps) => props.fontWeight};
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.textAlign};
  font-family: ${props => props.theme.PRIMARY_FONT};
  font-size: ${(props: AppTextProps) => props.fontSize};
  margin: ${(props: AppTextProps) => props.margin};
  font-style: "";
  text-overflow: ${(props: AppTextProps) =>
    props.ellipsis ? "ellipsis" : "inherit"};
  white-space: ${(props: AppTextProps) =>
    props.ellipsis ? "nowrap" : "inherit"};
  overflow: ${(props: AppTextProps) => (props.ellipsis ? "hidden" : "inherit")};
  :hover {
    cursor: ${props => (props.clickable ? "pointer" : "normal")};
  }
`;

AppText.defaultProps = {
  margin: "0",
  color: "#000",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "normal",
  textAlign: "left",
  ellipsis: false
};
