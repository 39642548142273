import React from "react";
import { useField, ErrorMessage } from "formik";
import { AppInput, AppInputProps, ErrorText } from "../UI";
import styled from "styled-components";

interface FormInputFieldProps extends AppInputProps {
  name: string;
  placeholder?: string;
  style?: React.CSSProperties;
  onFocus?: () => void;
}

const FormInputField = ({ style, ...props }: FormInputFieldProps) => {
  const [field] = useField(props.name);
  return (
    <FormContainer>
      <AppInput
        onFocus={props.onFocus}
        {...field}
        {...props}
        // error={meta.touched && meta.error}
      />
      <ErrorMessage name={field.name} component={ErrorText} />
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export { FormInputField };
