import firebase, { databaseRef } from "../firebase";
import { CardNode, Highlight } from "../responseTypes";
import { store } from "../../store/configureStore";
import {
  fetchNodeChildren,
  startFetchingNodeChildren,
  disableWeb,
  setBranchLink,
  setBook,
  setChapter,
  setBookNode,
} from "../../actions";
import { findAndReplaceHighlight } from "../../utils/reformatHTML";

export const firebaseFetchNodeChildren = (parentID: string) => {
  const nodeRef = databaseRef.child("/Nodes");
  const pageQuery = nodeRef.orderByChild("parentid").equalTo(parentID);

  let values = [] as CardNode[];
  store.dispatch(startFetchingNodeChildren);

  pageQuery
    .once("value", (snapshot: any) => {
      snapshot.forEach((childSnapshot: any) => {
        let value = childSnapshot.val();
        value.key = childSnapshot.key;
        values.push(value);
      });
    })
    .then(() => {
      // below if we need card types
      //   if (values.length > 0) {
      //     type = values[0].type;
      //   }

      let finalValues = values
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((nodes) => {
          if (!!nodes.highlights) {
            nodes.title = findAndReplaceHighlight(
              nodes.title as string,
              nodes.highlights as { [name: string]: Highlight }
            );
          }

          if (!!nodes.dropdowns) {
            const dropdowns = nodes.dropdowns;
            const dropdownKeys = Object.keys(nodes.dropdowns);
            let finalTitle = nodes.title;
            dropdownKeys.forEach((key) => {
              finalTitle = finalTitle.replace(
                `{dropdown-${key}}`,
                `${dropdowns[key].correct_answer}`
              );
            });
            nodes.title = finalTitle;
          }

          return nodes;
        });
      store.dispatch(fetchNodeChildren(finalValues));
    })
    .catch((error: any) => {
      alert(error);
    });

  nodeRef
    .orderByKey()
    .equalTo(parentID)
    .once("value", (snapshot: any) => {
      try {
        const value = snapshot.val()[parentID];
        store.dispatch(disableWeb(value.disable_web_link));
        store.dispatch(setChapter(value));
        const bookRef = nodeRef.orderByKey().equalTo(value.parentid);
        const chapters = nodeRef
          .orderByChild("parentid")
          .equalTo(value.parentid);
        bookRef.once("value", async (snapshot: any) => {
          const book = snapshot.val()[value.parentid];
          // console.log("bookRef snap", snapshot.val()[value.parentid]);
          store.dispatch(setBookNode(book));
          // console.log("book----", book);
          store.dispatch(setBranchLink(book.branch_link));
        });
        chapters.once("value", (snapshot: any) => {
          let chapterArray: any[] = [];
          // console.log("chapter snap", snapshot.val()[value.parentid]);
          snapshot.forEach((chapterval: any) => {
            let chapter = chapterval.val();
            chapter["key"] = chapterval.key;
            chapterArray.push(chapter);
          });
          chapterArray.sort((a, b) => {
            return a.order - b.order;
          });
          store.dispatch(setBook(chapterArray));
          // console.log("chapter array", chapterArray);
        });
      } catch (err) {
        // Using replace instead of href causes user to not be able to go back to incorrect page.
        window.location.replace("https://imprintapp.com/");
        // console.log("Node doesn't exist, redirecting to imprintapp.com")
      }
    });
};

export const createNewEmailCapture = ({
  email,
  date,
  country_code,
}: {
  email: string;
  date: string;
  country_code: string;
}) => {
  let key = databaseRef.child("/EmailCaptureNode").push().key;
  try {
    firebase
      .database()
      .ref("/EmailCaptureNode/" + key)
      .set({ email, date, country_code });
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchIsNextChapterLocked = async (nextChapterKey: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const isLocked = firebase.database().ref(`/Nodes/${nextChapterKey}`);
      isLocked.once("value", (response: any) => {
        try {
          resolve(response.val().isLocked);
        } catch (e) {
          reject(false);
        }
      });
    } catch (e) {
      reject("Error fetching next chapter");
    }
  });
};

// export const firebaseFetchChapterData = (ID: string) => {
//   const nodeRef = databaseRef.child("/Nodes");
//   let chapter = [] as any;

//   nodeRef.once("value", snapshot => {
//     chapter.push(snapshot.val());
//   });
// };

// export const firebaseCheckIfDisabled = (ID: string) => {
//   store.dispatch(startFetchingNodeChildren);
//   const nodeRef = databaseRef.child("/Nodes");
//   let disabledWebLink = false;

//   return nodeRef
//     .once("value", (snapshot) => {
//       disabledWebLink = snapshot.val()[ID].disable_web_link;
//       console.log("check snapshot", snapshot.val());
//     })
//     .then(() => {
//       store.dispatch(disableWeb(disabledWebLink));
//     });
// };
