/* eslint-disable @typescript-eslint/no-unused-vars */
import anime from "animejs";
import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { logo } from "../../assets";
import { device } from "../../utils/variables";
import { SubscriptionDetail } from "./SubscriptionDetail";
import { SubscriptionMain } from "./SubscriptionMain";
import { UserSignUpForm } from "./UserSignUpForm";

interface Props {}

export const Subscription = (props: Props) => {
  const textTransition = React.createRef() as any;

  const [page, setPage] = useState("Main");
  const [subscriptionType, setSubscriptionType] = React.useState("yearly");

  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const resizeWindow = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  useEffect(() => {
    console.log(page);
    handleAnimation();
  }, [page]);

  console.log(page);

  const handleRemoveAnimation = () => {
    anime({
      targets: textTransition.current,
      duration: 500,
      translateX: [0, -20],
      translateY: [0, 0],
      opacity: [1, 0],
      direction: "linear",
      easing: "linear",
      complete: () => {},
    });
  };

  const handleAnimation = () => {
    anime({
      targets: textTransition.current,
      duration: 500,
      translateX: [0, 15],
      opacity: [0.2],
      direction: "reverse",
      easing: "linear",
    });
  };

  const handleClick = (
    e: any,
    page: string,
    subscriptionType: string = "yearly"
  ) => {
    e.preventDefault();
    setSubscriptionType(subscriptionType);
    setPage(page);
  };

  const renderComponent = () => {
    if (page === "Main") {
      return <SubscriptionMain handleClick={handleClick} />;
    }
    if (page === "Detail") {
      return <SubscriptionDetail handleClick={handleClick} />;
    }
    if (page === "Login") {
      return <UserSignUpForm subscriptionType={subscriptionType} />;
    }

    return <SubscriptionMain handleClick={handleClick} />;
  };

  return (
    <FlexContainer>
      <AppContainer height={windowSize.height}>
        <Logo src={logo} />
        <Container ref={textTransition}>{renderComponent()}</Container>
      </AppContainer>
    </FlexContainer>
  );
};

const FlexContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #eef2f6;
  @media ${device.mobileSize} {
    justify-content: flex-start;
  }
`;

const AppContainer = styled.div<{ height: number }>`
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 375px;
  margin: 0 auto;
  position: relative;
  height: 700px;

  @media ${device.mobileSize} {
    margin-top: 15%;
    max-height: 640px;
    width: 343px;
    height: ${(props) => (props.height ? `${props.height * 0.9}px` : "80vh")};
  }
`;

const Container = styled.div`
  height: 90%;
  width: 100%;
  position: relative;
`;

const Logo = styled.img`
  position: fixed;
  left: 5%;
  top: 5%;
  height: 24px;
  @media ${device.mobileSize} {
    left: auto;
    height: 21px;
    position: absolute;
    top: -5%;
  }
`;
