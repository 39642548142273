/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { questionData } from "../../assets/questionData";
import { Answers } from "./Answers";
import { questionType } from "./Questions";
import anime from "animejs";
import { device } from "../../utils/variables";

interface Props {
  is_current: boolean;
  question: questionType;
  lastQuestion: boolean;
  currentIndex: number;
  setNext: (Clickable: boolean) => void;
  handleSubmit: (Clickable: boolean) => void;
}

export class Question extends React.Component<
  Props,
  { username: string; unmount: boolean }
> {
  textTransition = React.createRef() as any;
  constructor(props: Props) {
    super(props);
    this.state = {
      username: "",
      unmount: false,
    };
  }

  componentDidMount() {
    if (
      this.props.question.question.length &&
      this.props.question.answer_type === "lotti"
    ) {
      const user = localStorage.getItem("username");
      if (user) {
        this.setState({ username: `${user}!` });
      }
    }
    this.handleAnimation();
  }

  componentDidUpdate() {
    if (this.state.unmount === true) {
      this.handleRemoveAnimation();
    }
  }

  handleAnimation = () => {
    anime({
      targets: this.textTransition.current,
      duration: 500,
      translateX: [20, 0],
      opacity: [0, 1],
      direction: "linear",
      easing: "linear",
    });
  };

  handleRemoveAnimation = () => {
    anime({
      targets: this.textTransition.current,
      duration: 500,
      translateX: [0, -20],
      translateY: [0, 0],
      opacity: [1, 0],
      direction: "linear",
      easing: "linear",
      complete: () => {
        this.props.handleSubmit(true);
      },
    });
  };

  handleAnswerSubmit = (check: boolean) => {
    this.setState({ unmount: check });
  };

  render() {
    return (
      <Container
        is_current={this.props.is_current}
        content_type={this.props.question.answer_type}
        lastQuestion={this.props.lastQuestion}
        ref={this.textTransition}
      >
        <QuestionContainer content_type={this.props.question.answer_type}>
          <QuestionStyle>
            {this.props.question.question}
            {this.state.username}
          </QuestionStyle>
          {this.props.question.answer_type.match("multiselect") && (
            <MultiSelectDescriptionStyle>
              (Select at least one)
            </MultiSelectDescriptionStyle>
          )}
          {this.props.question.answer_type.match("lotti") &&
            this.props.question.answers.length > 1 && (
              <HeaderDescriptionStyle>
                {this.props.question.answers[1]}
              </HeaderDescriptionStyle>
            )}
        </QuestionContainer>
        <Answers
          answer_type={this.props.question.answer_type}
          answers={this.props.question.answers}
          setNext={this.props.setNext}
          lastQuestion={this.props.lastQuestion}
          currentIndex={this.props.currentIndex}
          handleSubmit={this.handleAnswerSubmit}
          require_input={this.props.question.require_input}
          icons={this.props.question?.icons}
        />
      </Container>
    );
  }
}

// export const Question = ({
//   is_current,
//   question,
//   lastQuestion,
//   currentIndex,
//   setNext,
//   handleSubmit,
// }: Props) => {
//   const textTransition = React.createRef() as any;

//   const [username, setUsername] = useState("");

//   const handleAnimation = () => {
//     anime({
//       targets: textTransition.current,
//       duration: 1000,
//       translateX: [0, 20],
//       opacity: [1, 0],
//       direction: "reverse",
//       easing: "linear",
//     });
//   };

//   const handleRemoveAnimation = () => {
//     anime({
//       targets: textTransition.current,
//       duration: 1000,
//       translateX: [0, 20],
//       opacity: [0, 1],
//       direction: "linear",
//       easing: "linear",
//     });
//   };

//   // ADD EVENT LISTENER FOR RESIZING WINDOW

//   useEffect(() => {
//     if (question.question.length && question.answer_type === "lotti") {
//       const user = localStorage.getItem("username");
//       if (user) {
//         setUsername(`${user}!`);
//       }
//     }

//     handleAnimation();
//   }, [question]);

//   return (
//     <Container
//       is_current={is_current}
//       ref={textTransition}
//       content_type={question.answer_type}
//       lastQuestion={lastQuestion}
//     >
//       <QuestionContainer>
//         <QuestionStyle>
//           {question.question}
//           {username}
//         </QuestionStyle>
//         {question.answer_type.match("multiselect") && (
//           <MultiSelectDescriptionStyle>
//             (Select at least one)
//           </MultiSelectDescriptionStyle>
//         )}
//       </QuestionContainer>
//       <Answers
//         answer_type={question.answer_type}
//         answers={question.answers}
//         setNext={setNext}
//         lastQuestion={lastQuestion}
//         currentIndex={currentIndex}
//         handleSubmit={handleSubmit}
//         require_input={question.require_input}
//         icons={question?.icons}
//       />
//     </Container>
//   );
// };

interface ContainerProp {
  is_current: boolean;
  content_type: string;
  lastQuestion: boolean;
}

const Container = styled.div<ContainerProp>`
  display: ${(props) => (props.is_current ? "block" : "none")};
  height: 100%;
  margin-top: ${(props) =>
    props.content_type === "lotti" && props.lastQuestion ? 0 : "51px"};
  position: relative;
`;

const QuestionContainer = styled.div<{ content_type: string }>`
  width: 100%;
  position: ${(props) =>
    props.content_type === "lotti" ? "relative" : "absolute"};
`;

const QuestionStyle = styled.p`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  font-family: GothamSSm;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 285px;
  word-wrap: break-word;
  text-align: center;
  margin: auto;
  color: #292032;
`;

const MultiSelectDescriptionStyle = styled.p`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #292032;
`;

const HeaderDescriptionStyle = styled.p`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #292032;
  text-align: center;
  margin-bottom: 20px;
`;
