import {
  FETCH_NODE_CHILDREN,
  START_FETCHING_NODE_CHILDREN,
  AppActions,
  INCREASE_INDEX,
  DECREASE_INDEX,
  RESET_INDEX,
  DISABLED_WEB,
  SET_INDEX,
  SET_BRANCH_LINK,
  SET_ACTION_COUNT,
  DISMISS_PITCH,
  SET_CHAPTER,
  SET_BOOK,
  SET_BOOK_NODE,
  SHOW_PITCH,
  SET_BOOK_COVER,
  SET_WEB_USER,
  DISABLE_CARD_NAVIGATION,
  ENABLE_CARD_NAVIGATION,
  SET_DIRECTION,
  INCREASE_MULTI_CARD_COUNT,
  DECREASE_MULTI_CARD_COUNT,
  SET_COUNT_LIMIT,
  SET_MULTI_CARD_COUNT,
  SET_MULTI_CARD_LINE_COUNT,
  SET_OFFSET_X_POSITION,
} from "../types";

import { CardNode, ChapterNode, WebUser } from "../firebase/responseTypes";

export const startFetchingNodeChildren = (): AppActions => ({
  type: START_FETCHING_NODE_CHILDREN,
});

export const fetchNodeChildren = (childNodes: CardNode[]): AppActions => ({
  type: FETCH_NODE_CHILDREN,
  childNodes: childNodes,
});

export const increaseIndex = (): AppActions => ({
  type: INCREASE_INDEX,
});

export const decreaseIndex = (): AppActions => ({
  type: DECREASE_INDEX,
});

export const setIndex = (index: number): AppActions => ({
  type: SET_INDEX,
  index,
});

export const resetIndex = (): AppActions => ({
  type: RESET_INDEX,
});

export const disableWeb = (disabled: boolean): AppActions => ({
  type: DISABLED_WEB,
  disabled,
});

export const setBranchLink = (branch_link: string): AppActions => ({
  type: SET_BRANCH_LINK,
  branch_link,
});

export const setActionCount = (): AppActions => ({
  type: SET_ACTION_COUNT,
});

export const showPitch = (): AppActions => ({
  type: SHOW_PITCH,
});

export const dismissPitch = (): AppActions => ({
  type: DISMISS_PITCH,
});

export const setChapter = (chapter: ChapterNode): AppActions => ({
  type: SET_CHAPTER,
  chapter,
});

export const setBook = (book: ChapterNode[]): AppActions => ({
  type: SET_BOOK,
  book,
});

export const setBookNode = (book_node: ChapterNode): AppActions => ({
  type: SET_BOOK_NODE,
  book_node,
});

export const setBookCover = (book_cover: string): AppActions => ({
  type: SET_BOOK_COVER,
  book_cover,
});

export const setWebUser = (user: WebUser): AppActions => ({
  type: SET_WEB_USER,
  user,
});

export const disableCardNavigation = (): AppActions => ({
  type: DISABLE_CARD_NAVIGATION,
});

export const enableCardNavigation = (): AppActions => ({
  type: ENABLE_CARD_NAVIGATION,
});

export const setDirection = (direction: string): AppActions => ({
  type: SET_DIRECTION,
  direction: direction,
});

export const setOffsetXPosition = (offsetX: number): AppActions => ({
  type: SET_OFFSET_X_POSITION,
  offsetX,
});

// MULTI CARD ACTIONS

export const increaseMultiCardCount = (): AppActions => ({
  type: INCREASE_MULTI_CARD_COUNT,
});

export const decreaseMultiCardCount = (): AppActions => ({
  type: DECREASE_MULTI_CARD_COUNT,
});

export const setMultiCardCount = (count: number): AppActions => ({
  type: SET_MULTI_CARD_COUNT,
  count,
});

export const setCountLimit = (count: number): AppActions => ({
  type: SET_COUNT_LIMIT,
  count,
});

export const setMultiLineCardLineCount = (lines: number): AppActions => ({
  type: SET_MULTI_CARD_LINE_COUNT,
  lines,
});
