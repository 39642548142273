/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { SubscriptionDots } from "../../assets";
import { device, PRIVACY_LINK, TERMS_LINK } from "../../utils/variables";

interface Props {
  // handleSubscriptionDetailClick: (e: any) => void;
  handleClick: (e: any, page: string, subscriptionType: string) => void;
}

export const SubscriptionMain = ({ handleClick }: Props) => {
  // AFTER ELEMENT LOADED
  useEffect(() => {}, []);
  return (
    <Container>
      <HeaderContainer>
        YOUR FREE TRIAL WITH <b>IMPRINT</b>
      </HeaderContainer>
      <BodyContainer>
        <BodyFlexContainer>
          <DotContainer>
            <MainCircle />
            <SmallDotContainer>
              <img src={SubscriptionDots} alt="img" />
            </SmallDotContainer>
            <GreyCircle />
            <SmallDotContainer>
              <img src={SubscriptionDots} alt="img" />
            </SmallDotContainer>
            <GreyCircle />
          </DotContainer>

          <div>
            <BodyContext style={{ marginTop: "-4px" }}>
              <BodyMain>NOW</BodyMain>
              <BodyDesc>
                Start your trial to <b>unlock the full Imprint library</b> today.
              </BodyDesc>
            </BodyContext>
            <BodyContext style={{ marginTop: "26px" }}>
              <BodyMain>IN 5 DAYS</BodyMain>
              <BodyDesc>
                <b>Receive</b> an email reminderthat your free trial is ending.
              </BodyDesc>
            </BodyContext>

            <BodyContext style={{ marginTop: "25px" }}>
              <BodyMain>IN 7 DAYS</BodyMain>
              <BodyDesc>
                You’ll be <b>charged</b> for Imprint. Cancel anytime.
              </BodyDesc>
            </BodyContext>
          </div>
        </BodyFlexContainer>
      </BodyContainer>
      <PlanContainer>
        <TrialDetail>7-day free trial, then</TrialDetail>
        <TrialDetail>$99.99 per year ($8.33/month) </TrialDetail>
        <ButtonContainer onClick={(e) => handleClick(e, "Login", "yearly")}>
          Try free and subscribe
        </ButtonContainer>
        <ViewAllPlanText onClick={(e) => handleClick(e, "Detail", "yearly")}>
          View all plans
        </ViewAllPlanText>
      </PlanContainer>
      <TermsConditionContainer>
        <a href={TERMS_LINK}> Terms & Conditions </a>
        {"  "}•{"  "}
        <a href={PRIVACY_LINK}>Privacy Policy </a>
      </TermsConditionContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 51px;
`;

const HeaderContainer = styled.div`
  width: 225px;
  height: 56px;
  left: 78px;
  top: 88.35px;
  font-family: GothamSSm;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #2d7dd2;
  margin: auto;
`;

const BodyContainer = styled.div`
  width: 227px;
  height: 252px;
  margin: auto;
  margin-top: 31px;
`;

const BodyFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
`;

const BodyContext = styled.div`
  width: 200px;
  position: relative;
  margin: 0px 15px;
`;
const DotContainer = styled.div`
  display: flex;
  flex-direction: column;
  img {
    margin-bottom: 10px;
  }
`;

const BodyMain = styled.p`
  /* left: 5.26%;
  right: 73.68%;
  top: calc(50% - 20px / 2 - 22px); */
  font-family: GothamSSm;
  font-size: 14px;
  line-height: 20px;
  color: #26639b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 4px 0px;
`;

const BodyDesc = styled.p`
  font-family: GothamSSm;
  font-size: 12px;
  line-height: 20px;
  color: #292032;
  left: 10px;
  top: 34px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
`;

const MainCircle = styled.svg`
  width: 20px;
  height: 20px;
  left: 10px;
  top: 10px;
  border-radius: 100px;
  background: linear-gradient(
      90deg,
      rgba(115, 123, 253, 0.75) 1.68%,
      rgba(50, 123, 210, 0.75) 116.81%
    ),
    #1e85e2;
`;

const SmallDotContainer = styled.div`
  margin: 0px auto;
  margin-top: 5px;
  img {
    margin-bottom: 0px;
    height: 60px;
  }
`;
const GreyCircle = styled.svg`
  width: 20px;
  height: 20px;
  left: 10px;
  top: 10px;
  border-radius: 100px;
  background: #d2d5d8;
`;

const PlanContainer = styled.div`
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 182px;
  @media ${device.mobileSize} {
    position: absolute;
    bottom: 0%;
    height: auto;
  }
`;

const TrialDetail = styled.p`
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  display: block;
  text-align: center;
  color: #2d7dd2;
  margin: 2px auto;
`;

const ButtonContainer = styled.div`
  padding: 16px;
  width: 90%;
  height: 52px;
  background: linear-gradient(
      90deg,
      rgba(115, 123, 253, 0.75) 1.68%,
      rgba(50, 123, 210, 0.75) 116.81%
    ),
    #1e85e2;
  border-radius: 32px;
  margin: auto;
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #fefefe;
  cursor: pointer;
  margin-top: 10px;
`;

const ViewAllPlanText = styled.p`
  height: 20px;
  width: 100%;
  position: absolute;
  margin: auto;
  bottom: 18px;
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  text-align: center;
  color: #2d7dd2;
  cursor: pointer;
  @media ${device.mobileSize} {
    bottom: -35px;
  }
`;

const TermsConditionContainer = styled.div`
  margin: auto;
  width: 100%;
  position: absolute;
  bottom: -35px;
  text-align: center;
  font-family: GothamSSm;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #1e85e2;
  @media ${device.mobileSize} {
    bottom: -80px;
  }
`;
