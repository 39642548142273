import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { AppText } from "../UI";

interface Props {}

const MobileLandScapeComponent: FunctionComponent<Props> = () => {
  return (
    <MobileLandScapeContainer>
      <MobileLandScapeTextContainer>
        <MobileLandScapeText>
          For an optimal viewing experience, please go back to portrait mode
        </MobileLandScapeText>
      </MobileLandScapeTextContainer>
    </MobileLandScapeContainer>
  );
};

const MobileLandScapeContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #7b9be9 0%, #1c4dc1 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileLandScapeTextContainer = styled.div`
  width: 50%;
`;

const MobileLandScapeText = styled(AppText)`
  font-size: 24px;
  font-family: GothamSSm;
  font-weight: 600;
  color: #fff;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export { MobileLandScapeComponent };
