import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  answers: string[];
  handleClick: (e: any) => void;
};

export const BooleanTypeAnswer = ({ answers, handleClick }: Props) => {
  const [clicked, setClicked] = useState([false, false]);

  useEffect(() => {
    console.log("mounted");
  }, []);
  const handleAnswerClick = (value: string, index: number) => {
    const clickedState = clicked.slice().map((el, i) => {
      if (i === index) {
        return !el;
      }
      return el;
    });
    setClicked(clickedState);
    handleClick(value);
  };

  const textTransition = React.createRef() as any;

  return (
    <div>
      <BooleanQuestionAnswerContainer>
        <TextContainer src={answers[0]} />
      </BooleanQuestionAnswerContainer>
      <BooleanAnswerContainer>
        <BooleanAnswers>
          <BooleanAnswer
            onClick={(e) => handleAnswerClick("Yes", 0)}
            clicked={clicked[0]}
          >
            <p>✓ Yes</p>
          </BooleanAnswer>
          <BooleanAnswer
            onClick={(e) => handleAnswerClick("No", 1)}
            clicked={clicked[1]}
          >
            <p>× No</p>
          </BooleanAnswer>
        </BooleanAnswers>
      </BooleanAnswerContainer>
    </div>
  );
};

const BooleanQuestionAnswerContainer = styled.div`
  position: relative;
  margin-top: 40%;
`;

const TextContainer = styled.img`
  display: block;
  margin: auto;
  max-width: 300px;
`;

const BooleanAnswerContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 24px;
`;

const BooleanAnswers = styled.div`
  display: flex;
  justify-content: center;
`;

const BooleanAnswer = styled.div<{ clicked: boolean }>`
  height: 64px;
  width: 42%;
  font-family: GothamSSm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin: auto;
  background: #fefefe;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid #d2d5d8;
  border-color: ${(props) => (props.clicked ? "#5095EB66" : "#d2d5d8")};
  background: ${(props) =>
    props.clicked
      ? "linear-gradient(0deg,rgba(80, 149, 235, 0.4),rgba(80, 149, 235, 0.4)),#fafafa"
      : "#fafafa"};
`;
