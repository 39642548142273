import anime from "animejs";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

type Props = {
  image_src: string;
  selected: boolean;
  completed: boolean;
  image_index: number;
};

export const ImageAnswers = ({
  image_src,
  selected,
  completed,
  image_index,
}: Props) => {
  const imgRef = useRef(null);

  const [clicked, setClicked] = useState(false);

  const handleAnimation = () => {
    anime({
      targets: imgRef.current,
      duration: 500,
      translateX: [0, 0],
      opacity: [0.2, 0.8],
      direction: "reverse",
      easing: "linear",
    });
  };

  useEffect(() => {
    if (!selected && completed) {
      handleAnimation();
    }
  }, [selected, completed]);
  return (
    <ImageContainer
      ref={imgRef}
      clicked={clicked}
      alt={`image-${image_index}`}
      src={image_src}
      onClick={() => {
        setClicked(true);
      }}
    />
  );
};

type ImageContainerProps = {
  clicked: boolean;
};
const ImageContainer = styled.img<ImageContainerProps>`
  height: 184px;
  width: 263px;
  border: ${(props) => (props.clicked ? "2px solid #5095eb" : "none")};
  box-sizing: ${(props) => (props.clicked ? "border-box" : "none")};
  filter: ${(props) =>
    props.clicked ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.12))" : "none"};
  border-radius: ${(props) => (props.clicked ? "16px" : "none")};
`;
