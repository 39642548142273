import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { device } from "../../utils/variables";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types";
import { resetIndex } from "../../actions";
import { IoIosClose } from "react-icons/io";
import { End_Of_Chapter_Background, Download_White_Svg } from "../../assets";
import { AppText } from "../UI";
import { AppState } from "../../store/configureStore";
import { detect } from "detect-browser";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormInputField } from "../Forms";
import {
  createNewEmailCapture,
  fetchIsNextChapterLocked,
} from "../../firebase/actions";
import { formateDate } from "../../utils/formatCurrentDate";

const browser = detect();

interface LastPageProps {
  closeLastPage: () => void;
  disabled?: boolean;
  nodeID: string;
  current_chapter_title: string;
  book_node: string;
  showLastPage?: boolean;
  ios?: string;
}

interface RouterProps {
  nodeID: string;
  cardID?: string;
  ios?: string;
}

interface MyFormikProps {
  email: string;
}

const emailSchema = Yup.object().shape({
  email: Yup.string().nullable().email().required(" Email must be valid"),
});

interface LastPageState {
  emailSubmitted: boolean;
  emailSubmittedNote: string;
  isLocked: boolean | unknown;
  dynamicDownloadLink: string;
  EndofChapter_tracked: boolean;
}

type Props = LastPageProps & LinkDispatchProps & LinkStateProps;
class LastPage extends React.Component<Props, LastPageState> {
  containerRef = React.createRef() as any;
  constructor(props: Props) {
    super(props);
    this.state = {
      emailSubmitted: false,
      emailSubmittedNote: "",
      isLocked: null,
      dynamicDownloadLink: "",
      EndofChapter_tracked: false,
    };
  }

  componentDidUpdate = async (prevProps: LastPageProps) => {
    if (!!this.props.showLastPage && !this.state.EndofChapter_tracked) {
      mixpanel.track("EndofChapter", {
        node_id: this.props.nodeID,
        current_chapter: this.props.current_chapter_title,
        current_book: this.props.book_node,
      });
      this.setState({ EndofChapter_tracked: true });
    }

    if (this.props.nextChapterKey) {
      const isLocked = await fetchIsNextChapterLocked(
        this.props.nextChapterKey
      );
      this.setState({ isLocked: isLocked });

      if (
        !!this.props.book_node &&
        this.props.book_node !== this.state.dynamicDownloadLink
      ) {
        this.setState({
          dynamicDownloadLink: this.props.book_node,
        });
      }
    }
  };

  nextChapter = () => {
    mixpanel.track("NextChapter", {
      node_id: this.props.nodeID,
      current_chapter: this.props.current_chapter_title,
      current_book: this.props.book_node,
    });
  };

  downloadApp = () => {
    mixpanel.track("AppInstallButton", {
      node_id: this.props.nodeID,
      current_chapter: this.props.current_chapter_title,
      current_book: this.props.book_node,
    });
  };

  triggerCloseLastPage = () => {
    this.props.closeLastPage();
    mixpanel.track("CloseEOC", {
      node_id: this.props.nodeID,
      current_chapter: this.props.current_chapter_title,
      current_book: this.props.book_node,
    });
  };
  // handleAnimation = () => {
  //   anime({
  //     targets: this.containerRef.current,
  //     duration: 200,
  //     opacity: 1,
  //     easing: "linear",
  //   });
  // };
  createDynamicLink = (title: string) => {
    return ` https://apps.apple.com/app/apple-store/id1482780647?pt=119317604&ct=${title.replace(
      /\s(?!\s)/g,
      "%20"
    )}%20Web&mt=8`;
  };

  render() {
    // Replace spaces with %20. Add non-dynamic text.

    const { disabled, nextChapterKey } = this.props;
    return (
      <BackgroundDiv
        disabled={disabled ? true : false}
        showLastPage={this.props.showLastPage ? true : false}
      >
        <LastPageContainer
          ref={this.containerRef}
          disabled={disabled ? true : false}
          showLastPage={this.props.showLastPage ? true : false}
        >
          {!disabled && (
            <CloseContainer onClick={this.triggerCloseLastPage}>
              <IoIosClose size={50} color={"#fff"} />
            </CloseContainer>
          )}
          <LastPageContentContainer>
            {/* <HeaderContainer></HeaderContainer> */}
            {
              // browser?.os === "iOS" &&
              // COUNTRY_FILTER.includes(this.props.user_country) &&
              // this.props.ios !== "false"
              true ? (
                <DownloadContainer>
                  <DownloadTextWrapper>
                    <DownloadText
                      href={
                        this.props.branch_link ||
                        this.createDynamicLink(this.state.dynamicDownloadLink)
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={this.downloadApp}
                    >
                      Read more in
                      <br /> the Imprint App
                    </DownloadText>
                  </DownloadTextWrapper>
                  <AppLinkWrapper>
                    <AppLink
                      href={
                        this.props.branch_link ||
                        this.createDynamicLink(this.state.dynamicDownloadLink)
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={this.downloadApp}
                    >
                      <AppLinkImage src={Download_White_Svg} />
                    </AppLink>
                  </AppLinkWrapper>
                </DownloadContainer>
              ) : (
                <EmailCaptureContainer>
                  {!this.state.emailSubmitted ? (
                    <FormikContainer>
                      <EmailTextContainer>
                        <EmailText>
                          Sign up to get visual insights <br /> from a new book
                          every week{" "}
                        </EmailText>
                      </EmailTextContainer>
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={emailSchema}
                        onSubmit={async (values, { setFieldError }) => {
                          mixpanel.track("EmailSubmit", {
                            node_id: this.props.nodeID,
                            current_chapter: this.props.current_chapter_title,
                            current_book: this.props.book_node,
                          });
                          const success = createNewEmailCapture({
                            email: values.email,
                            date: formateDate(),
                            country_code: this.props.user_country,
                          });
                          if (success) {
                            this.setState({
                              emailSubmitted: true,
                              emailSubmittedNote: "Thank you!",
                            });
                            mixpanel.track("EmailSuccess", {
                              node_id: this.props.nodeID,
                              current_chapter: this.props.current_chapter_title,
                              current_book: this.props.book_node,
                            });
                          }
                          if (!success) {
                            mixpanel.track("EmailError", {
                              node_id: this.props.nodeID,
                              current_chapter: this.props.current_chapter_title,
                              current_book: this.props.book_node,
                            });
                            this.setState({
                              emailSubmitted: true,
                              emailSubmittedNote:
                                "Something went wrong, try again",
                            });
                            setTimeout(() => {
                              this.setState({
                                emailSubmitted: false,
                              });
                            }, 2000);
                          }
                        }}
                      >
                        {({
                          submitForm,
                          errors,
                          touched,
                          values,
                          isValid,
                        }: FormikProps<MyFormikProps>) => {
                          return (
                            <FormContainer>
                              <InputContainer>
                                <FormInputField
                                  name="email"
                                  width={70}
                                  placeholder="Enter email address"
                                  onFocus={() => {
                                    mixpanel.track("EmailFocus", {
                                      node_id: this.props.nodeID,
                                      current_chapter: this.props
                                        .current_chapter_title,
                                      current_book: this.props.book_node,
                                    });
                                  }}
                                />
                              </InputContainer>
                              {/* <ErrorText>{`${
                            errors.email ? errors.email : ""
                          } `}</ErrorText> */}
                              <SubmitContainer>
                                <SubmitButton
                                  disabled={!isValid}
                                  onClick={submitForm}
                                >
                                  Submit
                                </SubmitButton>
                              </SubmitContainer>
                            </FormContainer>
                          );
                        }}
                      </Formik>
                    </FormikContainer>
                  ) : (
                    <FormSubmissionDiv>
                      <FormSubmissionText>
                        {this.state.emailSubmittedNote}
                      </FormSubmissionText>
                    </FormSubmissionDiv>
                  )}
                </EmailCaptureContainer>
              )
            }
            <OtherTitleContainer
            // showEmailCapture={
            //   browser?.os === "iOS" &&
            //   COUNTRY_FILTER.includes(this.props.user_country) &&
            //   this.props.ios !== "false"
            // }
            >
              <OtherTitlesText>
                <strong>Get visual Insights from:</strong>
                <br />
                The 7 Habits of Highly Effective People, Thinking Fast and Slow,
                Grit, The Power of Habit, and many more
              </OtherTitlesText>
            </OtherTitleContainer>
            <LineContainer>
              <WhiteLine />
            </LineContainer>
            <DescriptionContainer>
              <DescriptionText>Stunning Animation</DescriptionText>
              <DescriptionText>Interactive Learning</DescriptionText>
              <DescriptionText>Huge Library of Titles</DescriptionText>
            </DescriptionContainer>
          </LastPageContentContainer>
          <BottomFooterContainer>
            {!!nextChapterKey && !this.state.isLocked && (
              <NextChapterLink
                href={`https://viewer.imprintapp.com/chapter/${nextChapterKey}`}
                onClick={this.nextChapter}
              >
                Or, click here to start the next chapter
              </NextChapterLink>
            )}
          </BottomFooterContainer>

          {disabled ? (
            <StyledText>
              This Chapter is not viewable, download the app!
            </StyledText>
          ) : (
            <div></div>
          )}
        </LastPageContainer>
      </BackgroundDiv>
    );
  }
}

// {`https://viewer.imprintapp.com/chapter/${nextChapterKey}`}

interface LinkStateProps {
  nextChapterKey: string;
  bookTitle: string;
  chapterCount: number;
  current_chapter: number;
  branch_link: string;
  user_country: string;
}

interface LinkDispatchProps {
  resetIndex: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  resetIndex: bindActionCreators(resetIndex, dispatch),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  nextChapterKey: state.nodeReducer.book.filter(
    (chapter) => chapter.order === state.nodeReducer.current_chapter.order + 1
  )[0]
    ? state.nodeReducer.book.filter(
        (chapter) =>
          chapter.order === state.nodeReducer.current_chapter.order + 1
      )[0].key
    : "",
  bookTitle: state.nodeReducer.current_chapter.title,
  chapterCount: state.nodeReducer.book.length,
  current_chapter: state.nodeReducer.current_chapter_index + 1,
  branch_link: state.nodeReducer.current_chapter.branch_link
    ? state.nodeReducer.current_chapter.branch_link
    : "",
  user_country: state.nodeReducer.web_user.country_code,
});

//= ===================
//= STYLED COMPONENTS
//= ===================

interface Disabled {
  disabled: boolean;
  showLastPage: boolean;
}

interface EmailCapture {
  showEmailCapture?: boolean;
}

const BackgroundDiv = styled.div<Disabled>`
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: ${(props) => (props.showLastPage ? 60 : 0)};
`;

const LastPageContainer = styled.div<Disabled>`
  display: flex;
  z-index: ${(props) => (props.showLastPage ? 60 : 0)};
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  width: 80vw;
  -webkit-box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.3) 0 1px 3px;
  left: 50%;
  top: 50%;
  margin-top: ${(props) => (props.disabled ? 0 : "-30vh")};
  margin-left: -50vw;
  position: absolute;
  background-color: white;
  /* Disables text highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: ${(props) => (props.showLastPage ? 1 : 0)};
  background-image: url(${End_Of_Chapter_Background});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  @media ${device.mobileS} {
    max-width: 100vh;
    height: 85vh;
  }
  @media ${device.mobileM} {
    max-width: 100vh;
    height: 85vh;
  }
  @media ${device.mobileL} {
    max-width: 100vh !important;
    height: 85vh !important;
    max-width: 400px !important;
    width: 90vw !important;
    height: 90% !important;
    margin-left: -45vw !important;
    margin-top: ${browser?.name === "fxios"
      ? "-38h !important"
      : "-41vh !important"};
    position: fixed !important;
  }
  @media ${device.laptop} {
    width: 400px;
    max-width: 400px;
    max-height: 800px;
    height: 85%;
    margin-left: -200px;
    margin-top: -47vh;
    /* margin-bottom: 10%; */
  }
  @media ${device.ipadPortrait} {
    margin-left: -26vw !important;
    height: 80%;
  }
  @media ${device.ipadProPortrait} {
    margin-top: -38vh !important;
  }
  @media ${device.ipad11Portrait} {
    margin-left: -29vw !important;
  }
  @media ${device.minDesktop} {
    width: 400px;
    max-width: 400px;
    max-height: 800px;
    height: 85%;
    margin-left: -200px;
    margin-top: -47vh;
  }
  @media ${device.ultraWidemin} {
    height: 70%;
    margin-top: -47vh;
    max-height: 730px;
  }
`;

const LastPageContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const LineContainer = styled.div`
  height: 1vh;
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const OtherTitleContainer = styled.div<EmailCapture>`
  height: 10vh;
  max-height: 150px;
  width: 100%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 10%;
  @media ${device.mobileL} {
    margin-top: ${(props) => (props.showEmailCapture ? "25%" : "25%")};
  }
  @media ${device.iphoneXR} {
    height: 12vh;
  }
`;

// const BookTitle = styled(AppText)`
//   font-family: GothamSSm;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 19px;
//   text-align: center;
//   color: #fff;
// `;

// const TitleCount = styled(AppText)`
//   font-family: GothamSSm;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 12px;
//   line-height: 24px;
//   text-align: center;
//   color: #fff;
// `;

// const StyledButtonLink = styled.a`
//   text-decoration: none;
//   width: 80%;
//   max-width: 280px;
// `;

// const ButtonContainer = styled.div`
//   width: 100%;
//   height: 55px;
//   padding: 0 10px;
//   background: #fefefe;
//   border-radius: 12px;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
// `;

// const ButtonText = styled(AppText)`
//   font-family: GothamSSm;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 14px;
//   line-height: 17px;
// `;

const DownloadContainer = styled.div`
  /* height: 10vh; */
  max-height: 100px;
  width: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const DownloadTextWrapper = styled.div`
  margin-top: 10px;
`;
const DownloadText = styled.a`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  color: #fff;
  text-decoration: none;
  text-align: center;
`;

const EmailCaptureContainer = styled.div`
  height: 10vh;
  min-height: 185px;
  width: 100%;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const EmailTextContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EmailText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #fff;
  text-decoration: none;
  text-align: center;
  @media screen {
  }
`;

const FormikContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.mobileL} {
    min-height: 100px;
    max-height: 170px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const FormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  background-color: ${(props) => (props.disabled ? "grey" : "#5095eb")};
  color: #fff;
  border-radius: 32px;
  width: 120px;
  height: 32px;
  border: none;
`;

const FormSubmissionDiv = styled.div`
  margin-top: 20px;
  width: 70%;
  height: 50px;
  background: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobileS} {
    margin-top: 10px;
  }
`;

const FormSubmissionText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 14px;
  color: #2d7dd2;
`;

// const ErrorText = styled(AppText)`
//   font-size: 14px;
//   color: red;
//   font-family: GothamSSm;
// `;

const DescriptionContainer = styled.div`
  height: 14vh;
  max-height: 150px;
  width: 100%;
  /* margin-top: 15%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const DescriptionText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
`;

const WhiteLine = styled.div`
  height: 1px;
  width: 20px;
  background: #fff;
  opacity: 0.5;
`;
const BottomFooterContainer = styled.div`
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  background: linear-gradient(0deg, #4350a4, #4350a4),
    linear-gradient(0deg, #4350a4 0%, #3c5acb 100%);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const OtherTitlesText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  padding: 0 10%;
  @media ${device.mobileL} {
    font-size: 12px !important;
  }
  @media ${device.desktop} {
    font-size: 16px;
  }
`;

const NextChapterLink = styled.a`
  text-decoration: underline;
  color: #fff;
  font-size: 14px;
  font-family: GothamSSm;
  @media ${device.mobileL} {
    font-size: 12px;
  }
`;
// const LogoContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 10%;
//   width: 80%;
//   height: 40%;
// `;

// const IconImg = styled.img`
//   width: 100%;
// `;
const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  position: absolute;
  left: 15px;
  top: 15px;
  :hover {
    cursor: pointer;
  }
`;

const AppLinkWrapper = styled.div`
  height: 14%;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 30px; */
`;

const AppLink = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 10%;
  :hover {
    cursor: pointer;
  }
`;

const AppLinkImage = styled.img`
  width: 100%;
`;

// const RestartButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 2px solid black;
//   height: 50px;
//   width: 50px;
//   border-radius: 50%;
//   margin-top: 10%;
// `;

const StyledText = styled(AppText)`
  margin-top: 50px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(LastPage);
