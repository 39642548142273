import axios from "axios";
import firebase from "../firebase/firebase";
axios.defaults.headers.post["Content-Type"] = "application/json";

export const redirectToStripe = async ({
  app_user_id,
  email,
  subscriptionType,
}: {
  app_user_id: string;
  email: string;
  subscriptionType: string;
}) => {
  const data = {
    email: email,
    app_user_id: app_user_id,
    subscription_type: subscriptionType,
  };
  const res = await axios.post(
    "https://us-central1-know-d8bc0.cloudfunctions.net/registerStripeUser",
    JSON.stringify(data)
  );

  return res.data;
};

export const createFBUser = (
  email: string,
  password: string,
  onboardingResponses?: any
) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential: any) => {
      // Signed in
      const user = userCredential.user;
      const uid = user.uid;

      return { app_user_id: uid };
    })
    .catch((error: { code: number; message: string }) => {
      const errorMessage = error.message;
      return { error: errorMessage };
    });
};
