/* eslint-disable @typescript-eslint/no-unused-vars */
import { detect } from "detect-browser";
import * as React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Redirect } from "react-router";
import styled from "styled-components";
import { logo, Onboarding1, Onboarding2, Onboarding3 } from "../../assets";
import { device } from "../../utils/variables";
import LottieVideo from "../Segments/LottieVideo";

const browser = detect();
const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
declare global {
  interface Window {
    prerenderReady: boolean;
  }
}

let animObj: any = null;

console.log(window.innerHeight);
class OnboardingPage extends React.Component<
  {},
  { animationDatas: any[]; currentIndex: number; height: number; width: number }
> {
  containerRef = React.createRef<HTMLDivElement>();
  animBox!: any;

  constructor(props: any) {
    super(props);
    this.state = {
      animationDatas: [],
      currentIndex: 0,
      height: 0,
      width: 0,
    };
  }

  componentDidMount() {
    this.setState({
      animationDatas: [Onboarding1, Onboarding2, Onboarding3],
      currentIndex: 0,
      height: window.innerHeight,
      width: window.innerWidth,
    });
    window.addEventListener("resize", this.resizeWindow);
  }

  handleSubmit = () => {
    this.setState({ currentIndex: this.state.animationDatas.length });
  };

  resizeWindow = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  setAnimeIndex = (index: number) => {
    this.setState({ currentIndex: index });
  };

  render() {
    if (
      this.state.animationDatas.length > 0 &&
      this.state.currentIndex >= this.state.animationDatas.length
    ) {
      return <Redirect to="/onboarding/questions" />;
    }
    console.log(this.state);
    return (
      <FlexContainer>
        <ChapterContainerDiv height={this.state.height}>
          <Logo src={logo} />

          <CarouselContainer
            autoPlay={false}
            showIndicators={true}
            onChange={this.setAnimeIndex}
            showStatus={false}
            showArrows={true}
            showThumbs={false}
          >
            {this.state.animationDatas.map((el, index) => {
              return (
                <LottieVideo
                  lottieJSON={el}
                  hardcoded={true}
                  animate_image={true}
                  selectedCard={this.state.currentIndex === index}
                  gif_loops={0}
                  key={index}
                />
              );
            })}
          </CarouselContainer>

          <ButtonContainer>
            <NextButton onClick={this.handleSubmit}>
              <NextFont>Get Started </NextFont>
            </NextButton>
          </ButtonContainer>
        </ChapterContainerDiv>
      </FlexContainer>
    );
  }
}

export default OnboardingPage;

interface ContainerProps {
  test: boolean;
  selectedCard: boolean;
}

interface Firefox {
  isFirefox?: boolean;
}

type CardSegmentStyleProps = ContainerProps;

const FlexContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #eef2f6;
`;

const ChapterContainerDiv = styled.div<{ height: number }>`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin: auto;
  position: relative;
  width: 90vw;
  height: 85vh;
  max-width: 343px;
  max-height: 640px;

  @media ${device.mobileSize} {
    max-height: 640px;
    margin-top: 15%;
    height: ${(props) => (props.height ? `${props.height * 0.8}px` : "80vh")};
    max-width: 343px;
    .carousel {
      max-height: ${(props) =>
        props.height ? `${props.height * 0.8 * 0.9}px` : "90%"};
    }
  }
  @media ${device.ipadProLandscape} {
    height: 65% !important;
  }

  @media ${device.ipadPortrait} {
    height: 70% !important;
  }
  @media ${device.ipadLandScape} {
    width: 360px;
    height: 640px !important;
  }

  @media ${device.ipadLandScape} {
    height: 75% !important;
  }
  @media ${device.smallDesktop} {
    max-width: 343px !important;
  }
`;

const CarouselContainer = styled(Carousel)`
  .carousel .control-dots {
    bottom: 0px;
  }
  @media ${device.mobileSize} {
    .carousel .control-dots {
      bottom: -10px;
    }
  }
  .carousel .control-dots .dot {
    transition: width 0.15s ease-in;
  }
  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    opacity: 1;
    background: #39d1ba;
    filter: alpha(opacity=100);
    width: 22px;
    border-radius: 5px;
  }
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    height: 100%;
    overflow: hidden;
  }
  .carousel .slider {
    height: 100%;
    width: 100%;
  }
  div {
    height: 99%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: -65px;

  @media ${device.mobileSize} {
    height: 52px;
  }
`;

const NextButton = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0 auto;
  background: linear-gradient(
      90deg,
      rgba(115, 123, 253, 0.75) 1.68%,
      rgba(50, 123, 210, 0.75) 116.81%
    ),
    #1e85e2;
  border-radius: 32px;
  :hover {
    cursor: pointer;
  }
  p {
    color: #fefefe;
  }
  position: relative;
  margin: auto;
  width: 343px;
  @media ${device.mobileSize} {
    width: 295px;
  }
`;

const NextFont = styled.p`
  height: 20px;
  left: calc(50% - 42px / 2);
  top: calc(50% - 20px / 2);
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 6px;
`;

const Logo = styled.img`
  position: fixed;
  left: 5%;
  top: 5%;
  height: 24px;
  @media ${device.mobileSize} {
    position: absolute;
    top: -5%;
    left: auto;
    height: 21px;
  }
`;
