import * as React from "react";
import styled from "styled-components";
import { device } from "../../utils/variables";
import { detect } from "detect-browser";
import { iOSSafari } from "../../utils/variables";
import { LottieVideo } from "../Segments";
const browser = detect();

interface VideoCardProps {
  mediaURL: string;
  firstPage: boolean;
  selectedCard: boolean;
  animate_image: boolean;
  lottieJSON: string;
  gif_loops: number;
}

interface VideoCardState {}

class VideoCard extends React.Component<VideoCardProps, VideoCardState> {
  videoRef = React.createRef() as any;
  constructor(props: VideoCardProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (
      this.props.mediaURL &&
      this.props.firstPage &&
      this.props.mediaURL.indexOf("json") === -1
    ) {
      this.videoRef.play();
    }
  }

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      this.props.mediaURL &&
      this.props.selectedCard &&
      prevProps.selectedCard !== this.props.selectedCard &&
      this.props.mediaURL.indexOf("json") === -1
    ) {
      this.videoRef.current.play();
    }
    if (
      prevProps.selectedCard !== this.props.selectedCard &&
      this.props.selectedCard
    ) {
      this.props.mediaURL &&
        this.props.animate_image &&
        this.props.mediaURL.indexOf("json") === -1 &&
        this.videoRef.current.play();
      if (
        this.props.mediaURL &&
        this.props.firstPage &&
        this.props.mediaURL.indexOf("json") === -1
      ) {
        this.videoRef.current.play();
      }
      if (
        this.props.mediaURL &&
        this.props.animate_image &&
        this.props.mediaURL.indexOf("json") === -1
      ) {
        this.videoRef.current.play();
      }
    }
  };
  render() {
    // console.log("VIDEO CARD PROPS", this.props.selectedCard && this.props);
    const {
      mediaURL,
      firstPage,
      selectedCard,
      animate_image,
      lottieJSON,
      gif_loops,
    } = this.props;
    const browserName = browser ? browser.name : "";
    return (
      <VideoCardContainer>
        <VideoContainer firstPage={firstPage}>
          {mediaURL.indexOf("json") > -1 ? (
            <LottieVideo
              mediaURL={mediaURL}
              animate_image={animate_image}
              image_y={0}
              lottieJSON={lottieJSON}
              gif_loops={gif_loops}
              selectedCard={selectedCard}
            />
          ) : (
            <VideoDiv
              ref={this.videoRef}
              browser={browserName}
              muted
                autoPlay={
                true
                // browserName === "safari" ||
                // browserName === "ios" ||
                // browserName === "crios"
                //   ? false
                //   : true
              }
              src={mediaURL}
              playsInline
            />
          )}
        </VideoContainer>
      </VideoCardContainer>
    );
  }
}

interface VideoProps {
  firstPage: boolean;
}

interface VideoDivProps {
  browser: string;
}

export const VideoCardContainer = styled.div`
  z-index: -1;
  width: 99%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VideoContainer = styled.div<VideoProps>`
  z-index: 0;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.laptop} {
    margin-top: ${(props) => (props.firstPage ? 0 : 0)};
    height: ${(props) => (props.firstPage ? "100%" : "100%")};
  }
  @media ${device.desktopL} {
    margin-top: ${(props) => (props.firstPage ? 0 : 0)};
    height: ${(props) => (props.firstPage ? "100%" : "100%")};
  }
  @media ${device.minDesktop} {
    margin-top: ${(props) => (props.firstPage ? 0 : 0)};
    height: ${(props) => (props.firstPage ? "100%" : "100%")};
  }
`;

export const VideoDiv = styled.video<VideoDivProps>`
  z-index: 0;
  backface-visibility: hidden;
  width: 95% !important;
  /* height: ${(props) =>
    props.browser === "ios" || props.browser === "crios" || iOSSafari
      ? "10%"
      : "auto"} !important; */
  /* BELOW CSS to make video shadow box dissappear on ios devices */
  -webkit-appearance: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`;

export const LineAnimationDiv = styled.div`
  width: 10%;
  border-bottom: 1px solid grey;
`;

export default VideoCard;
