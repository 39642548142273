import React, { Component, createRef } from "react";
import styled from "styled-components";
import anime from "animejs";
import { AppText } from "../UI";
import { Pitch_Logo, Download_White_Svg } from "../../assets";
import { connect } from "react-redux";
import { dismissPitch } from "../../actions";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types";
import { device } from "../../utils/variables";
import { ChapterNode } from "../../firebase/responseTypes";
import { AppState } from "../../store/configureStore";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormInputField } from "../Forms";
import { createNewEmailCapture } from "../../firebase/actions";
import { formateDate } from "../../utils/formatCurrentDate";
import { detect } from "detect-browser";
const browser = detect();

interface MobilePitchPopUpProps {
  nodeID: string;
  ios?: string;
}

const emailSchema = Yup.object().shape({
  email: Yup.string().nullable().email().required(" Email must be valid"),
});

interface MyFormikProps {
  email: string;
}

interface MobilePitchPopUpState {
  emailSubmitted: boolean;
  emailSubmittedNote: string;
}

type Props = MobilePitchPopUpProps & LinkDispatchProps & LinkStateProps;

class MobilePitchPopUp extends Component<Props, MobilePitchPopUpState> {
  containerRef = createRef() as any;

  constructor(props: Props) {
    super(props);

    this.state = {
      emailSubmitted: false,
      emailSubmittedNote: "",
    };
  }

  componentDidMount = () => {
    this.handleShowAnimation();
  };

  downloadApp = () => {
    mixpanel.track("AppInstallButton_PopUp", {
      node_id: this.props.nodeID,
      current_chapter: this.props.currentChapter.title,
      current_book: this.props.book_node.title,
    });
    mixpanel.track("AppInstallButton", {
      node_id: this.props.nodeID,
      current_chapter: this.props.currentChapter.title,
      current_book: this.props.book_node.title,
    });
  };

  handleShowAnimation = () => {
    anime({
      targets: this.containerRef.current,
      duration: 300,
      height: "40vh",
      easing: "cubicBezier(.5, .05, .1, .3)",
      // transition: "linear",
    });
  };

  handleHideAnimation = () => {
    anime({
      targets: this.containerRef.current,
      duration: 200,
      height: ["40vh", 0],
      transition: "easeInOutSine",
    });
  };

  render() {
    return (
      <MobilePitchContainer ref={this.containerRef}>
        <MobilePitchContentContainer>
          <MobilePitchLogoContainer>
            <MobilePitchLogo src={Pitch_Logo} />
          </MobilePitchLogoContainer>
          {
            // browser?.os === "iOS" &&
            // COUNTRY_FILTER.includes(this.props.user_country) &&
            // this.props.ios !== "false"
            true ? (
              <MobilePitchTextContainer>
                <TitleContainer>
                  <TitleText>
                    <strong>Imprint</strong> is <strong>better</strong> in the{" "}
                    <strong>App!</strong>
                  </TitleText>
                </TitleContainer>
                <DescriptionContainer>
                  <DescriptionText>
                    Imprint is your visual guide <br />
                    through key ideas from <br />
                    the world’s greatest thinkers.
                  </DescriptionText>
                </DescriptionContainer>
                <AppleLogoContainer
                  href={this.props.branch_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.downloadApp}
                >
                  <AppleImg src={Download_White_Svg} />
                </AppleLogoContainer>
              </MobilePitchTextContainer>
            ) : (
              <EmailCaptureContainer isAndroid={browser?.os === "Android OS"}>
                {!this.state.emailSubmitted ? (
                  <FormikContainer>
                    <EmailTextContainer>
                      <EmailText>
                        Sign up to get visual insights <br /> from a new book
                        every week{" "}
                      </EmailText>
                    </EmailTextContainer>
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={emailSchema}
                      onSubmit={async (values, { setFieldError }) => {
                        mixpanel.track("EmailSubmit", {
                          node_id: this.props.nodeID,
                          current_chapter: this.props.currentChapter.title,
                          current_book: this.props.book_node,
                        });
                        const success = createNewEmailCapture({
                          email: values.email,
                          date: formateDate(),
                          country_code: this.props.user_country,
                        });
                        if (success) {
                          this.setState({
                            emailSubmitted: true,
                            emailSubmittedNote: "Thank you!",
                          });
                          mixpanel.track("EmailSuccess", {
                            node_id: this.props.nodeID,
                            current_chapter: this.props.currentChapter.title,
                            current_book: this.props.book_node,
                          });
                        }
                        if (!success) {
                          mixpanel.track("EmailError", {
                            node_id: this.props.nodeID,
                            current_chapter: this.props.currentChapter.title,
                            current_book: this.props.book_node,
                          });
                          this.setState({
                            emailSubmitted: true,
                            emailSubmittedNote:
                              "Something went wrong, try again",
                          });
                          setTimeout(() => {
                            this.setState({
                              emailSubmitted: false,
                            });
                          }, 2000);
                        }
                      }}
                    >
                      {({
                        submitForm,
                        errors,
                        touched,
                        values,
                        isValid,
                      }: FormikProps<MyFormikProps>) => {
                        return (
                          <FormContainer>
                            <InputContainer>
                              <FormInputField
                                name="email"
                                // width={70}
                                placeholder="Enter email address"
                                onFocus={() => {
                                  mixpanel.track("EmailFocus", {
                                    node_id: this.props.nodeID,
                                    current_chapter: this.props.currentChapter
                                      .title,
                                    current_book: this.props.book_node,
                                  });
                                }}
                              />
                            </InputContainer>

                            {/* <ErrorText>{`${
                              errors.email ? errors.email : ""
                            } `}</ErrorText> */}
                            <SubmitContainer>
                              <SubmitButton
                                disabled={!isValid}
                                onClick={submitForm}
                              >
                                Submit
                              </SubmitButton>
                            </SubmitContainer>
                          </FormContainer>
                        );
                      }}
                    </Formik>
                  </FormikContainer>
                ) : (
                  <FormSubmissionDiv>
                    <FormSubmissionText>
                      {this.state.emailSubmittedNote}
                    </FormSubmissionText>
                  </FormSubmissionDiv>
                )}
              </EmailCaptureContainer>
            )
          }
          <DismissContainer
            onClick={() => {
              this.props.dismissPitch();
              mixpanel.track("ClosePopUp", {
                node_id: this.props.nodeID,
                current_chapter: this.props.currentChapter.title,
                current_book: this.props.book_node.title,
              });
            }}
          >
            <DismissText>Not Now</DismissText>
          </DismissContainer>
        </MobilePitchContentContainer>
      </MobilePitchContainer>
    );
  }
}

interface Android {
  isAndroid: boolean;
}

const MobilePitchContainer = styled.div`
  height: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #2d72dd;
  z-index: 101;
  @media ${device.ipadProPortrait} {
    /* max-width: 480px;s */
  }
`;

const MobilePitchContentContainer = styled.div`
  height: 100%;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobilePitchLogoContainer = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 10px;
  position: absolute;
  top: -25px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 2px -1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 2px -1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 2px -1px 3px;
`;

const MobilePitchLogo = styled.img`
  height: 55px;
  width: 55px;
`;

const MobilePitchTextContainer = styled.div`
  margin-top: 40px;
  padding: 0px 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media ${`(min-width: 583px)`} {
    padding: 0 30%;
  }
  @media ${device.ipadPortrait} {
    padding: 0 30%;
  }
  @media ${device.ipadProPortrait} {
    padding: 0 30%;
  }
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const TitleText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: white;
  line-height: 19px;
  text-align: center;
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const DescriptionText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: white;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-align: center;
`;

const DismissContainer = styled.div`
  width: 100%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #4350a4, #4350a4),
    linear-gradient(0deg, #4350a4 0%, #3c5acb 100%);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

const DismissText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: white;
  text-align: center;

  :hover {
    cursor: pointer;
    filter: drop-shadow(0 0 5px #5dcdf5);
  }
  :active {
    filter: drop-shadow(0 0 1px #5dcdf5);
  }
`;

const AppleLogoContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 40%;
  text-decoration: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  :hover {
    cursor: pointer;
  }
  @media ${device.ipadPortrait} {
    width: 80% !important;
  }
  @media ${device.ipadProPortrait} {
    width: 80% !important;
  }
`;

const AppleImg = styled.img`
  width: 100%;
`;

const EmailText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #fff;
  text-decoration: none;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  user-select: none; /* Standard */
  @media ${device.ipadPortrait} {
    font-size: 20px;
  }
  @media ${device.ipadProPortrait} {
    font-size: 20px;
  }
`;

const EmailTextContainer = styled.div`
  /* height: 100%; */
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FormikContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @media ${device.mobileM} {
    min-height: 100px;
    max-height: 185px;
  }
  @media ${device.mobileL} {
    min-height: 100px;
    max-height: 220px !important;
  }
  @media ${device.tablet} {
    min-height: 100px;
    max-height: 260px;
  }
`;

const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.div`
  min-height: 18vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  @media ${device.mobileS} {
    height: 50%;
    min-height: 0px;
  }
  @media ${device.mobileM} {
    justify-content: center !important;
    min-height: 18vh !important;
  }
  @media ${device.mobileL} {
    justify-content: space-evenly;
    min-height: 20vh;
  }
  @media ${device.ipadPortrait} {
    justify-content: space-evenly;
    min-height: 24vh;
  }
  @media ${device.ipadProPortrait} {
    justify-content: space-evenly;
    min-height: 26vh;
  }
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: ${(props) => (props.disabled ? "grey" : "#5095eb")};
  color: #fff;
  border-radius: 32px;
  width: 120px;
  height: 42px;
  border: none;
`;

const FormSubmissionDiv = styled.div`
  margin-top: 20px;
  width: 70%;
  height: 50px;
  background: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormSubmissionText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 16px;
  color: #2d7dd2;
`;

const EmailCaptureContainer = styled.div<Android>`
  margin-top: 40px;
  height: ${(props) => (props.isAndroid ? "160px" : "480px")};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

interface LinkStateProps {
  book_node: ChapterNode;
  currentChapter: ChapterNode;
  branch_link: string;
  user_country: string;
}
interface LinkDispatchProps {
  dismissPitch: () => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  dismissPitch: bindActionCreators(dismissPitch, dispatch),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  book_node: state.nodeReducer.book_node,
  currentChapter: state.nodeReducer.current_chapter,
  branch_link: state.nodeReducer.current_chapter.branch_link
    ? state.nodeReducer.current_chapter.branch_link
    : "",
  user_country: state.nodeReducer.web_user.country_code,
});

export default connect(mapStateToProps, mapDispatchToProps)(MobilePitchPopUp);
