/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useEffect, useState } from "react";
import { Redirect } from "react-router";
import styled from "styled-components";
import { logo } from "../../assets";
import { questionData } from "../../assets/questionData";
import { device } from "../../utils/variables";
import { OnboardingProgress } from "../Segments";
import { Question } from "./Question";

interface Props {}

export type questionType = {
  question: string;
  answer_type: string;
  answers: string | any[];
  require_input: boolean;
  icons?: string[];
};

export const Questions = () => {
  const textTransition = React.createRef() as any;

  const [questions, setQuestions] = useState<questionType[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [next, setNext] = useState<boolean>(false);

  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  const resizeWindow = () => {
    setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
    };
  }, []);

  useEffect(() => {
    setQuestions(questionData);
    if (currentIndex && questions[currentIndex]) {
      setNext(!questions[currentIndex].require_input);
    }
  }, [questions, currentIndex]);

  const handleNextButton = (Clickable = false) => {
    let nextIndex = currentIndex + 1;
    if (Clickable) {
      setCurrentIndex(nextIndex);
    }
  };

  const renderCurrentQuestion = () => {
    let question = questions[currentIndex];
    if (question) {
      return (
        <Question
          key={currentIndex}
          is_current={true}
          question={question}
          setNext={setNext}
          currentIndex={currentIndex}
          lastQuestion={currentIndex === questions.length - 1}
          handleSubmit={handleNextButton}
        />
      );
    }
  };

  if (questions.length && currentIndex > questions.length - 1) {
    return <Redirect to="/onboarding/subscription" />;
  }

  return (
    <FlexContainer>
      <ChapterContainerDiv height={windowSize.height}>
        <Logo src={logo} />
        <OnboardingProgress
          childrenLength={questions.length}
          currentIndex={currentIndex}
          firstIndex={currentIndex === 0}
        />
        {renderCurrentQuestion()}
      </ChapterContainerDiv>
    </FlexContainer>
  );
};
interface ContainerProps {
  test: boolean;
  selectedCard: boolean;
}

interface Firefox {
  isFirefox?: boolean;
}

type CardSegmentStyleProps = ContainerProps;

const FlexContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #eef2f6;
`;

const ChapterContainerDiv = styled.div<{ height: number }>`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 90vw;
  height: 85vh;
  margin: auto;
  position: relative;
  max-height: 640px;
  max-width: 343px;

  @media ${device.mobileSize} {
    max-height: 640px;
    margin-top: 15%;
    height: ${(props) => (props.height ? `${props.height * 0.9}px` : "80vh")};
  }
`;

const Logo = styled.img`
  position: fixed;
  left: 5%;
  top: 5%;
  height: 24px;
  @media ${device.mobileSize} {
    display: none;
  }
`;

interface ContainerProp {}

const Container = styled.div<ContainerProp>`
  height: 100%;
  position: relative;
`;

const QuestionDiv = styled.div`
  height: 100%;
  margin-top: 51px;
`;
