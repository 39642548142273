import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/configureStore";
import { RouteComponentProps } from "react-router";
import { Dispatch, bindActionCreators } from "redux";
import { AppActions } from "../../types";
import { firebaseFetchNodeChildren } from "../../firebase/actions";
import {
  increaseIndex,
  decreaseIndex,
  setIndex,
  setActionCount,
  dismissPitch,
  setWebUser,
  setDirection,
  enableCardNavigation,
  decreaseMultiCardCount,
  increaseMultiCardCount,
} from "../../actions";
import styled from "styled-components";
import { CardNode, ChapterNode, WebUser } from "../../firebase/responseTypes";
import { DEFAULT_META, device, PROMO_NAMES } from "../../utils/variables";
import {
  LastPage,
  LoadingSegment,
  ChapterContainer,
  MobileLandScapeComponent,
  MobilePitchPopUp,
} from "../Segments/";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { AppText } from "../UI";
import { DesktopBackground, Download_White_Svg } from "../../assets";
import { Helmet } from "react-helmet";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormInputField } from "../Forms";
import { createNewEmailCapture } from "../../firebase/actions";
import { formateDate } from "../../utils/formatCurrentDate";
import queryString from "query-string";
import metaData from "../../metadata.json";
import hardcodeMetaData from "../../hardcodedMetadata.json";
import LastPagePromo from "../Segments/LastPagePromo";

// import { detect } from "detect-browser";
// const browser = detect();

declare global {
  interface Window {
    prerenderReady: boolean;
  }
}

interface ChapterPageProps {}

const emailSchema = Yup.object().shape({
  email: Yup.string().nullable().email().required(" Email must be valid"),
});

interface ChapterPageState {
  windowHeight: number;
  windowWidth: number;
  nextPageAnimation: boolean;
  showLastPage: boolean;
  isLandscape: boolean;
  emailSubmitted: boolean;
  emailSubmittedNote: string;
}

interface MyFormikProps {
  email: string;
}

interface RouterProps {
  nodeID: string;
  cardID?: string;
  ios?: string;
}

type Props = ChapterPageProps &
  RouteComponentProps<RouterProps> &
  LinkDispatchProps &
  LinkStateProps;

class ChapterPage extends React.Component<Props, ChapterPageState> {
  containerRef = React.createRef() as any;

  constructor(props: Props) {
    super(props);

    this.state = {
      windowHeight: 0,
      windowWidth: 0,
      nextPageAnimation: true,
      showLastPage: false,
      isLandscape: false,
      emailSubmitted: false,
      emailSubmittedNote: "",
    };
    window.addEventListener("resize", this.updateWindowSize);
  }

  componentDidMount = async () => {
    const nodeID = this.props.match.params.nodeID;
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);

    setTimeout(() => (window.prerenderReady = true), 7000);
    // mixpanel.track(`Open`, { node_id: nodeID });
    if (!queryValues.screenshot) {
      mixpanel.identify();
      mixpanel.people.set({});
    }
    firebaseFetchNodeChildren(nodeID);

    fetch(`https://geolocation-db.com/json/`)
      .then((res) => res.json())
      .then((json) => {
        return this.props.setWebUser({
          country_code: json.country_code,
        });
      });

    this.updateWindowSize();
    window.addEventListener("orientationchange", () => {
      if (window.orientation === -90) {
        this.setState({
          isLandscape: true,
        });
      }
      if (window.orientation === 90) {
        this.setState({
          isLandscape: true,
        });
      }
      if (window.orientation === 0) {
        this.setState({
          isLandscape: false,
        });
      }
    });
    window.addEventListener("keydown", this.handleKeyDownEvents);
  };

  componentDidUpdate = (prevProps: Props) => {
    const nodeID = this.props.match.params.nodeID;
    const prevNodeId = prevProps.match.params.nodeID;
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);

    if (
      this.props.child_nodes.length > 1 &&
      !!this.props.book &&
      this.props.book !== prevProps.book
    ) {
      if (!queryValues.screenshot) {
        mixpanel.track(`Open`, {
          node_id: nodeID,
          current_chapter: this.props.currentChapter.title,
          current_book: this.props.book_node.title,
        });
      }
      fetch(`https://geolocation-db.com/json/`)
        .then((res) => res.json())
        .then((json) => {
          return this.props.setWebUser({
            country_code: json.country_code,
          });
        });
    }
    // if (
    //   !!this.props.book_node.title &&
    //   !!this.props.book_cover &&
    //   !!this.props.book_node.author
    // ) {
    //   console.log("window----1", window.prerenderReady);
    //   window.prerenderReady = true;
    //   console.log("window----2", window.prerenderReady);
    // }

    if (
      this.props.child_nodes.length > 1 &&
      this.props.match.params.cardID &&
      !this.props.setChapterIndex
    ) {
      this.viewChapterIndex(this.props.match.params.cardID);
    }
    if (nodeID !== prevNodeId) {
      firebaseFetchNodeChildren(nodeID);
      this.setState({
        nextPageAnimation: true,
        showLastPage: false,
        isLandscape: false,
      });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyDownEvents);
    window.removeEventListener("resize", this.updateWindowSize);
    window.removeEventListener("orientationchange", () => {
      if (window.orientation === -90) {
        this.setState({
          isLandscape: true,
        });
      }
      if (window.orientation === 90) {
        this.setState({
          isLandscape: true,
        });
      }
      if (window.orientation === 0) {
        this.setState({
          isLandscape: false,
        });
      }
    });
  };

  updateWindowSize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  handleKeyDownEvents = (e: KeyboardEvent) => {
    if (!this.state.showLastPage) {
      if (e.keyCode === 37) {
        this.decreaseIndex();
      }
      if (e.keyCode === 39) {
        this.increaseIndex();
      }
    }
  };

  increaseIndex = () => {
    const nodeID = this.props.match.params.nodeID;
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);
    const {
      increaseIndex,
      child_nodes,
      currentIndex,
      setActionCount,
      setDirection,
      disableCardNavigationState,
      multiLineCardLineCount,
    } = this.props;
    setActionCount();
    if (!queryValues.screenshot) {
      mixpanel.track("NextCard", {
        node_id: nodeID,
        current_chapter: this.props.currentChapter.title,
        current_book: this.props.book_node.title,
      });
    }
    if (disableCardNavigationState) {
      //going forward animation
      if (
        // THIS IS ONLY USED WITH OldMultiLineCard
        this.props.clickCount < this.props.limit &&
        multiLineCardLineCount > 0
      ) {
        let className = ".line" + (this.props.clickCount + 1);
        document
          .querySelector(className)
          ?.classList.remove("slideOutAnimation");
        document.querySelector(className)?.classList.add("slideInAnimation");
      }

      if (this.props.limit > this.props.clickCount + 1) {
        // +1 to include the first line showing by default
        this.props.increaseCount();
        return;
      }
      if (this.props.clickCount >= this.props.limit - 1) {
        this.props.enableCardNavigation();
      }
    }
    if (child_nodes.length - 1 > currentIndex) {
      this.setState({
        nextPageAnimation: true,
      });
      setDirection("right");
      increaseIndex();
    }
    if (child_nodes.length - 1 === currentIndex) {
      //show last page
      this.setState({
        showLastPage: true,
      });
    }
  };

  closeLastPage = () => {
    this.setState({
      showLastPage: false,
    });
  };

  decreaseIndex = () => {
    const nodeID = this.props.match.params.nodeID;
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);

    const {
      decreaseIndex,
      currentIndex,
      setActionCount,
      setDirection,
      enableCardNavigation,
      disableCardNavigationState,
      clickCount,
      multiLineCardLineCount,
      limit,
    } = this.props;
    if (disableCardNavigationState) {
      let lineNumber = limit - clickCount;

      if (clickCount === 0) {
        // THIS IS ONLY USED WITH OldMultiLineCard
        for (let i = 1, length = multiLineCardLineCount; i <= length; i++) {
          let className = ".line" + i;
          document
            .querySelector(className)
            ?.classList.remove("slideInAnimation");
          document
            .querySelector(className)
            ?.classList.remove("slideOutAnimation");
        }
        decreaseIndex();
        this.props.enableCardNavigation();
      }
      if (lineNumber <= this.props.limit) {
        let className = ".line" + this.props.clickCount;
        document.querySelector(className)?.classList.remove("slideInAnimation");
        document.querySelector(className)?.classList.add("slideOutAnimation");
      }
      if (this.props.clickCount > 0) {
        this.props.decreaseCount();
        // return;
      }
      if (this.props.clickCount === 1) {
        this.props.enableCardNavigation();
      }
      return;
    }
    setActionCount();
    if (!queryValues.screenshot) {
      mixpanel.track("PrevCard", {
        node_id: nodeID,
        current_chapter: this.props.currentChapter.title,
        current_book: this.props.book_node.title,
      });
    }
    if (currentIndex !== 0) {
      this.setState({
        nextPageAnimation: false,
      });
      setDirection("left");
      enableCardNavigation();
      decreaseIndex();
    }
  };
  viewChapterIndex = (cardID: string) => {
    this.props.child_nodes.forEach((obj, index) => {
      if (cardID === obj.key) {
        this.props.setIndex(index);
      }
    });
  };

  downloadApp = () => {
    const nodeID = this.props.match.params.nodeID;
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);

    if (!queryValues.screenshot) {
      mixpanel.track("AppInstallButton_PopUp", {
        node_id: nodeID,
        current_chapter: this.props.currentChapter.title,
        current_book: this.props.book_node,
      });
      mixpanel.track("AppInstallButton", {
        node_id: nodeID,
        current_chapter: this.props.currentChapter.title,
        current_book: this.props.book_node,
      });
    }
  };

  render() {
    // console.log("Chapter Page props:", this.props);
    // console.log("Chapter Page state:", this.state);
    const nodeID = this.props.match.params.nodeID;
    const {
      child_nodes,
      currentIndex,
      disabled,
      showPitch,
      currentChapter,
      book_node,
    } = this.props;
    const UrlQueryStrings = this.props.location.search;
    const queryValues = queryString.parse(UrlQueryStrings);
    const { nextPageAnimation } = this.state;

    if (this.state.windowWidth <= 853 && this.state.isLandscape) {
      return <MobileLandScapeComponent />;
    }
    if (
      !!this.props.book_node.title &&
      !!this.props.book_node.image_link &&
      !!this.props.book_node.author
    ) {
      // console.log("window----1", window.prerenderReady);
      window.prerenderReady = true;
      // console.log("window----2", window.prerenderReady);
    }

    // @ts-ignore
    const hardcodeMetaDataToUse = (hardcodeMetaData[this.props.match.params.nodeID] as any) || null;

    const metaDataToUse =
      // @ts-ignore
      (metaData[this.props.match.params.nodeID] as any) || DEFAULT_META;
    // console.log("metaDataToUse", metaDataToUse);

    const renderLastPage = (promo: string) => {
      return PROMO_NAMES.includes(promo) ? (
        <LastPagePromo
          closeLastPage={this.closeLastPage}
          nodeID={this.props.match.params.nodeID}
          current_chapter_title={currentChapter.title}
          book_node={book_node.title}
          promo={(queryValues.promo ?? "") as string}
        />
      ) : (
        <LastPage
          closeLastPage={this.closeLastPage}
          nodeID={this.props.match.params.nodeID}
          current_chapter_title={currentChapter.title}
          book_node={book_node.title}
          showLastPage={this.state.showLastPage}
          ios={this.props.match.params.ios}
        />
      );
    };

    return (
      <ChapterPageContainer>
        <Helmet>
          <title>
            {hardcodeMetaDataToUse
              ? hardcodeMetaDataToUse.title
              : `${
                  metaDataToUse.book_title
                    ? `${metaDataToUse.book_title}: An Illustrated Summary`
                    : "Imprint WebViewer"
                }`}
          </title>
          <meta name="fragment" content="!" />
          <meta
            property="og:title"
            content={
              hardcodeMetaDataToUse
                ? hardcodeMetaDataToUse.title
                : `${
                    metaDataToUse.book_title
                      ? `${metaDataToUse.book_title}${
                          metaDataToUse.author ? " by" : ""
                        } `
                      : "Imprint WebViewer"
                  }${metaDataToUse.author ? metaDataToUse.author : ""}`
            }
          />
          <meta
            property="og:description"
            content={
              hardcodeMetaDataToUse
                ? hardcodeMetaDataToUse.subtitle
                : `Read ${metaDataToUse.title} of Imprint's illustrated primer ${
                    metaDataToUse.subtitle ? ": " + metaDataToUse.subtitle : ""
                  }`
            }
          />
          {/* <meta
            name="apple-itunes-app"
            content="app-id=1482780647, app-clip-bundle-id=com.polywise.weekly.Clip"
          /> */}
          <meta
            property="og:image"
            content={
              hardcodeMetaDataToUse
                ? hardcodeMetaDataToUse.image_link
                : metaDataToUse.image_link
                ? metaDataToUse.image_link
                : ""
            }
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta
            name="twitter:title"
            content={
              hardcodeMetaDataToUse
                ? hardcodeMetaDataToUse.title
                : `${
                    metaDataToUse.book_title
                      ? `${metaDataToUse.book_title} by `
                      : "Imprint WebViewer"
                  }${metaDataToUse.author ? metaDataToUse.author : ""}`
            }
          />
          <meta
            name="twitter:description"
            content={
              hardcodeMetaDataToUse
                ? hardcodeMetaDataToUse.subtitle
                : `Read ${metaDataToUse.title} of Imprint's illustrated primer${
                    metaDataToUse.subtitle ? ": " + metaDataToUse.subtitle : ""
                  }`
            }
          />
          <meta
            name="twitter:image"
            content={
              hardcodeMetaDataToUse
                ? hardcodeMetaDataToUse.image_link
                : metaDataToUse.image_link
                ? metaDataToUse.image_link
                : ""
            }
          />
          {book_node.noFollow && (
            <meta name="robots" content="noindex,nofollow" />
          )}
        </Helmet>
        <ChapterPageContentContainer>
          {this.state.showLastPage &&
            renderLastPage((queryValues.promo ?? "") as string)}
          {this.state.windowWidth >= 854 && (
            <LeftContainer>
              <LeftPersistantContainer showPitch={showPitch}>
                {/* {browser?.os === "iOS" &&
                  COUNTRY_FILTER.includes(this.props.user_country) &&
                  this.props.match.params.ios !== "false" && ( */}
                <LeftBoldContainerCopy>
                  <LeftBoldContainerText>
                    Stunning Animations
                  </LeftBoldContainerText>
                  <LeftBoldContainerText>
                    Interactive Learning
                  </LeftBoldContainerText>
                  <LeftBoldContainerText>
                    Huge Library of Titles
                  </LeftBoldContainerText>
                </LeftBoldContainerCopy>
                {/* )} */}
                <LeftTitleContainer>
                  <LeftBoldContainerText>
                    Get visual Insights from:
                  </LeftBoldContainerText>
                  <LeftRegularContainerText>
                    The 7 Habits of Highly Effective People, Thinking Fast and
                    Slow, Grit, The Power of Habit, and many more
                  </LeftRegularContainerText>
                </LeftTitleContainer>
              </LeftPersistantContainer>

              {this.state.windowWidth >= 1024 && (
                <BackButtonContainer>
                  <StyledBackArrow onClick={this.decreaseIndex} size={100} />
                </BackButtonContainer>
              )}
            </LeftContainer>
          )}
          {disabled ? (
            <LastPage
              nodeID={this.props.match.params.nodeID}
              current_chapter_title={currentChapter.title}
              book_node={book_node.title}
              closeLastPage={this.closeLastPage}
              disabled={disabled}
              ios={this.props.match.params.ios}
            />
          ) : child_nodes.length > 0 ? (
            <ChapterWrapper>
              <ChapterContainer
                nodeChild={child_nodes[currentIndex]}
                //======
                // Video Transition Changes
                allNodes={child_nodes}
                //======
                firstPage={currentIndex === 0}
                backClick={this.decreaseIndex}
                nextClick={this.increaseIndex}
                lastPage={child_nodes.length - 1 === currentIndex}
                nextPageAnimation={nextPageAnimation}
                showPitch={showPitch}
                nodeID={this.props.match.params.nodeID}
                book_title={
                  this.props.book_node.title ? this.props.book_node.title : ""
                }
                author={
                  this.props.book_node.author ? this.props.book_node.author : ""
                }
                screenshotMode={
                  queryValues.screenshot
                    ? queryValues.screenshot === "true"
                    : false
                }
              />
            </ChapterWrapper>
          ) : (
            <LoadingSegment />
          )}

          {this.state.windowWidth >= 854 && (
            <RightContainer>
              {this.state.windowWidth >= 1024 && (
                <NextButtonContainer>
                  <StyledRightArrow onClick={this.increaseIndex} size={100} />
                </NextButtonContainer>
              )}
              {
                // browser?.os === "iOS" &&
                // COUNTRY_FILTER.includes(this.props.user_country) &&
                // this.props.match.params.ios !== "false"
                true ? (
                  <RightPersistantContainer showPitch={showPitch}>
                    <RightSideTitleLinkWrapper
                    // href={this.props.branch_link}
                    // target="_blank"
                    >
                      <RightSideTitleLink
                        href={this.props.branch_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.downloadApp}
                      >
                        <RightSideTitleContainer>
                          <RightSideTitle>
                            Read more <br /> in the Imprint App
                          </RightSideTitle>
                        </RightSideTitleContainer>
                      </RightSideTitleLink>
                    </RightSideTitleLinkWrapper>
                    <LogoDiv>
                      <LogoLink
                        href={this.props.branch_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.downloadApp}
                      >
                        <LogoContainer>
                          <IconImg src={Download_White_Svg} />
                        </LogoContainer>
                      </LogoLink>
                    </LogoDiv>
                  </RightPersistantContainer>
                ) : (
                  <EmailCaptureContainer showPitch={showPitch}>
                    <EmailText>
                      Sign up to get visual insights <br /> from a new book
                      every week
                    </EmailText>
                    {!this.state.emailSubmitted ? (
                      <Formik
                        initialValues={{
                          email: "",
                        }}
                        validationSchema={emailSchema}
                        onSubmit={async (values, { setFieldError }) => {
                          if (!queryValues.screenshot) {
                            mixpanel.track("EmailSubmit", {
                              node_id: nodeID,
                              current_chapter: this.props.currentChapter.title,
                              current_book: this.props.book_node,
                            });
                          }
                          const success = createNewEmailCapture({
                            email: values.email,
                            date: formateDate(),
                            country_code: this.props.user_country,
                          });
                          if (success) {
                            this.setState({
                              emailSubmitted: true,
                              emailSubmittedNote: "Thank you!",
                            });
                            if (!queryValues.screenshot) {
                              mixpanel.track("EmailSuccess", {
                                node_id: nodeID,
                                current_chapter:
                                  this.props.currentChapter.title,
                                current_book: this.props.book_node,
                              });
                            }
                          }
                          if (!success) {
                            if (!queryValues.screenshot) {
                              mixpanel.track("EmailError", {
                                node_id: nodeID,
                                current_chapter:
                                  this.props.currentChapter.title,
                                current_book: this.props.book_node,
                              });
                            }
                            this.setState({
                              emailSubmitted: true,
                              emailSubmittedNote:
                                "Something went wrong, try again",
                            });
                            setTimeout(() => {
                              this.setState({
                                emailSubmitted: false,
                              });
                            }, 2000);
                          }
                        }}
                      >
                        {({
                          submitForm,
                          errors,
                          touched,
                          values,
                          isValid,
                        }: FormikProps<MyFormikProps>) => {
                          return (
                            <FormContainer>
                              <FormInputField
                                name="email"
                                // width={70}
                                placeholder="Enter email address"
                                onFocus={() => {
                                  if (!queryValues.screenshot) {
                                    mixpanel.track("EmailFocus", {
                                      node_id: nodeID,
                                      current_chapter:
                                        this.props.currentChapter.title,
                                      current_book: this.props.book_node,
                                    });
                                  }
                                }}
                              />
                              {/* <ErrorText>{`${
                              errors.email ? errors.email : ""
                            } `}</ErrorText> */}
                              <SubmitButton
                                disabled={!isValid}
                                onClick={submitForm}
                              >
                                Submit
                              </SubmitButton>
                            </FormContainer>
                          );
                        }}
                      </Formik>
                    ) : (
                      <FormSubmissionDiv>
                        <FormSubmissionText>
                          {this.state.emailSubmittedNote}
                        </FormSubmissionText>
                      </FormSubmissionDiv>
                    )}
                  </EmailCaptureContainer>
                )
              }
            </RightContainer>
          )}
        </ChapterPageContentContainer>
        {showPitch && this.state.windowWidth <= 853 && (
          <MobilePitchDismissBackground
            onClick={() => this.props.dismissPitch()}
          />
        )}
        {showPitch &&
          this.state.windowWidth === 1024 &&
          this.state.windowHeight === 1366 && (
            <MobilePitchDismissBackground
              onClick={() => this.props.dismissPitch()}
            />
          )}
        {showPitch && this.state.windowWidth <= 853 && (
          <MobilePitchPopUp
            nodeID={this.props.match.params.nodeID}
            ios={this.props.match.params.ios}
          />
        )}
        {showPitch &&
          this.state.windowWidth === 1024 &&
          this.state.windowHeight === 1366 && (
            <MobilePitchPopUp nodeID={this.props.match.params.nodeID} />
          )}
      </ChapterPageContainer>
    );
  }
}

interface LinkDispatchProps {
  increaseIndex: () => void;
  decreaseIndex: () => void;
  setIndex: (index: number) => void;
  setActionCount: () => void;
  dismissPitch: () => void;
  setWebUser: (user: WebUser) => void;
  setDirection: (direction: string) => void;
  enableCardNavigation: () => void;
  increaseCount: () => void;
  decreaseCount: () => void;
}

interface LinkStateProps {
  child_nodes: CardNode[];
  fetchingChildren: boolean;
  currentIndex: number;
  disabled: boolean;
  setChapterIndex: boolean;
  branch_link: string;
  showPitch: boolean;
  book: ChapterNode[];
  book_node: ChapterNode;
  currentChapter: ChapterNode;
  book_cover: string;
  user_country: string;
  disableCardNavigationState: boolean;
  clickCount: number;
  limit: number;
  multiLineCardLineCount: number;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  increaseIndex: bindActionCreators(increaseIndex, dispatch),
  decreaseIndex: bindActionCreators(decreaseIndex, dispatch),
  setIndex: bindActionCreators(setIndex, dispatch),
  setActionCount: bindActionCreators(setActionCount, dispatch),
  dismissPitch: bindActionCreators(dismissPitch, dispatch),
  setWebUser: bindActionCreators(setWebUser, dispatch),
  setDirection: bindActionCreators(setDirection, dispatch),
  enableCardNavigation: bindActionCreators(enableCardNavigation, dispatch),
  increaseCount: bindActionCreators(increaseMultiCardCount, dispatch),
  decreaseCount: bindActionCreators(decreaseMultiCardCount, dispatch),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  child_nodes: state.nodeReducer.children,
  fetchingChildren: state.nodeReducer.is_fetching_node_children,
  currentIndex: state.nodeReducer.currentIndex,
  disabled: state.nodeReducer.disabled,
  setChapterIndex: state.nodeReducer.setChapterIndex,
  branch_link: state.nodeReducer.current_chapter.branch_link
    ? state.nodeReducer.current_chapter.branch_link
    : "",
  showPitch: state.nodeReducer.showPitch,
  book: state.nodeReducer.book,
  book_node: state.nodeReducer.book_node,
  currentChapter: state.nodeReducer.current_chapter,
  book_cover: state.nodeReducer.book_cover,
  user_country: state.nodeReducer.web_user.country_code,
  disableCardNavigationState: state.nodeReducer.disableCardNavigation,
  clickCount: state.nodeReducer.multiLineClickCount,
  limit: state.nodeReducer.multiLineLimit,
  multiLineCardLineCount: state.nodeReducer.multiLineLineCount,
});

//= ===================
//= STYLED COMPONENTS
//= ===================

const ChapterWrapper = styled.div`
  height: 100vh;
  padding-top: 146%;
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 400px;
  @media ${device.mobileL} {
    position: fixed;
  }
`;

const ChapterPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media ${device.laptop} {
  }
`;
const ChapterPageContentContainer = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(180deg, #7b9be9 0%, #1c4dc1 100%);
  background-image: url(${DesktopBackground});
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  @media ${device.laptop} {
  }

  @media ${device.laptop} {
    padding: 0px 30px;
    width: 100%;
  }
  @media ${device.minDesktop} {
    background-color: white;
    height: 100vh;
    width: 100%;
  }
  @media ${device.tablet} {
    height: 100vh;
    background: #edeeef;
  }
  @media ${device.ipadPortrait} {
    height: 100vh;
    background: #edeeef;
  }
  @media ${device.ipadProPortrait} {
    height: 100vh;
    background: #edeeef;
  }
`;

const MobilePitchDismissBackground = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 100;
  opacity: 0;
`;

interface PitchContainer {
  showPitch: boolean;
}
const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  @media ${`(min-width: 854) and (max-width: 1023)`} {
    width: 20vw;
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.laptop} {
    height: 85vh;
    margin-top: 5%;
  }
  @media ${device.laptopL} {
    height: 80vh;
  }
  @media ${device.minDesktop} {
    max-height: 90%;
  }
  @media ${device.ipadPortrait} {
    display: none;
  }
  @media ${device.ipadProPortrait} {
    display: none;
  }
`;

const LeftPersistantContainer = styled.div<PitchContainer>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 30vw;
  width: 25vw;
  height: 80vh;
  opacity: ${(props) => (props.showPitch ? 1 : 0)};
  z-index: ${(props) => (props.showPitch ? 1 : -1)};
  transition: opacity 1s ease;
  @media ${`(min-width: 854) and (max-width: 1023)`} {
    width: 10vw;
  }
  @media ${device.ipadLandScape} {
    width: 15vw;
  }
  @media ${device.ipadProLandscape} {
    width: 25vw;
  }
  @media ${device.minDesktopScreen} {
    width: 25vw;
  }
`;

const LeftBoldContainerCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 12vh;
`;

const LeftBoldContainerText = styled(AppText)`
  font-size: 24px;
  font-family: GothamSSm;
  font-weight: 600;
  color: #fff;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  @media ${device.laptop} {
    font-size: 18px;
  }
  @media ${device.ipadLandScape} {
    font-size: 16px;
  }
  @media ${device.ipadProLandscape} {
    font-size: 24px;
  }
  @media ${device.minDesktopScreen} {
    font-size: 24px;
  }
  @media ${device.desktopMaxHeightBreakPoint} {
    font-size: 20px;
  }
`;

const LeftRegularContainerText = styled(AppText)`
  font-size: 24px;
  font-family: GothamSSm;
  font-weight: 300;
  line-height: 40px;
  color: #fff;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  @media ${device.laptop} {
    font-size: 18px;
  }
  @media ${device.ipadLandScape} {
    font-size: 16px;
    line-height: 25px;
  }
  @media ${device.ipadProLandscape} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${device.minDesktopScreen} {
    font-size: 24px;
    line-height: 30px;
  }
  @media ${device.desktopMaxHeightBreakPoint} {
    font-size: 20px;
    line-height: 26px;
  }
`;

const LeftTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
  max-width: 20vw;
`;
const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
  @media ${`(min-width: 854) and (max-width: 1023)`} {
    width: 20vw;
  }
  @media ${device.tablet} {
    display: none;
  }
  @media ${device.laptop} {
    height: 85vh;
    margin-top: 5%;
  }
  @media ${device.laptopL} {
    height: 80vh;
  }
  @media ${device.minDesktop} {
    max-height: 90%;
  }
  @media ${device.ipadPortrait} {
    display: none;
  }
  @media ${device.ipadProPortrait} {
    display: none;
  }
`;

const RightPersistantContainer = styled.div<PitchContainer>`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  max-width: 25vw;
  width: 25vw;
  height: 25vh;
  opacity: ${(props) => (props.showPitch ? 1 : 0)};
  z-index: ${(props) => (props.showPitch ? 1 : -1)};
  transition: opacity 1s ease;
  @media ${device.ipadLandScape} {
    width: 15vw;
  }
  @media ${device.ipadProLandscape} {
    width: 25vw;
  }
  @media ${device.minDesktopScreen} {
    width: 25vw;
  }
  @media ${`(min-width: 1440px)`} {
    height: 35vh;
  }
`;

const RightSideTitleLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 3vh;
`;

const RightSideTitleLink = styled.a`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  text-decoration: none;
`;
const RightSideTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 3vh;
  :hover {
    cursor: pointer;
  }
`;

const LogoDiv = styled.div`
  width: 60%;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
`;

const LogoLink = styled.a`
  width: 100%;
  height: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  height: 100%;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  :hover {
    cursor: pointer;
  }
  @media ${device.ipadLandScape} {
    width: 80%;
  }
  @media ${device.ipadProLandscape} {
    width: 85%;
  }
  @media ${device.minDesktopScreen} {
    width: 100%;
    height: 100%;
  }
`;

const IconImg = styled.img`
  width: 100%;
  height: 100%;
`;

const RightSideTitle = styled(AppText)`
  font-size: 26px;
  font-family: GothamSSm;
  font-weight: 500;
  color: #fff;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  @media ${device.ipadLandScape} {
    font-size: 16px;
  }
  @media ${device.ipadProLandscape} {
    font-size: 26px;
  }
  @media ${device.minDesktopScreen} {
    font-size: 26px;
  }
  @media ${device.desktopMaxHeightBreakPoint} {
    font-size: 18px;
  }
`;
const NextButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-left: 0px;
  z-index: 1;
`;

const StyledRightArrow = styled(IoIosArrowForward)`
  color: #fff;
  transition: all 0.2s ease;
  :hover {
    filter: drop-shadow(0 0 5px #5dcdf5);
    transform: scale(1.1);
  }
  :active {
    transform: scale(1);
    filter: none;
  }
`;

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin-right: 0px;
  z-index: 1;
`;

const StyledBackArrow = styled(IoIosArrowBack)`
  color: #fff;
  transition: all 0.2s ease;
  :hover {
    filter: drop-shadow(0 0 5px #5dcdf5);
    transform: scale(1.1);
  }
  :active {
    transform: scale(1);
    filter: none;
  }
`;

// const ChapterContainer = styled.div`
//   z-index: 0;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
//   -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
//   box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
//   border-radius: 10px;
//   @media ${device.mobileS} {
//     max-width: 100vh;
//     max-height: 100%;
//   }
//   @media ${device.mobileM} {
//     max-width: 100vh;
//     max-height: 100%;
//   }
//   @media ${device.mobileL} {
//     max-width: 100vh;
//     max-height: 100%;
//   }
//   @media ${device.laptop} {
//     max-width: 100vh;
//     max-height: 100%;
//     margin-top: 10%;
//     margin-bottom: 10%;
//   }
//   @media ${device.laptopL} {
//     max-width: 100vh;
//     max-height: 100%;
//     margin-top: 10%;
//     margin-bottom: 10%;
//   }
//   @media ${device.minDesktop} {
//     width: 630px;
//     max-height: 100%;
//     margin: 0;
//   }
// `;

const EmailText = styled(AppText)`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  color: #fff;
  text-decoration: none;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  user-select: none; /* Standard */
  @media ${device.laptop} {
    font-size: 18px;
  }
`;

const FormContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${device.laptop} {
    width: 85%;
  }
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  background-color: ${(props) => (props.disabled ? "grey" : "#5095eb")};
  color: #fff;
  border-radius: 32px;
  width: 120px;
  height: 42px;
  border: none;
`;

const FormSubmissionDiv = styled.div`
  margin-top: 20px;
  width: 70%;
  height: 50px;
  background: white;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormSubmissionText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 20px;
  color: #2d7dd2;
`;

const EmailCaptureContainer = styled.div<PitchContainer>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  opacity: ${(props) => (props.showPitch ? 1 : 0)};
  z-index: ${(props) => (props.showPitch ? 1 : -1)};
`;

// const ErrorText = styled(AppText)`
//   font-size: 14px;
//   color: red;
//   font-family: GothamSSm;
// `;

export default connect(mapStateToProps, mapDispatchToProps)(ChapterPage);
