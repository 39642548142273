import * as React from "react";
import styled from "styled-components";

type Props = {
  el: string;
  width: string;
  height: string;
  index: number;
  selected: boolean;
  icon: string | null;
  handleClick: (index: number) => void;
};

export const MultiSelectAnswer = ({
  el,
  width,
  height,
  index,
  icon,
  selected,
  handleClick,
}: Props) => {
  return (
    <MultiAnswer width={width} height={height} key={index} selected={selected}>
      <MultiCheckBoxInput>
        <input
          type="checkbox"
          value={el}
          checked={selected}
          onChange={(e) => handleClick(index)}
        />
      </MultiCheckBoxInput>

      <TextContainer hasIcon={!!icon}>
        {icon && (
          <IconContainer>
            <img src={icon} alt="icon" />
          </IconContainer>
        )}
        <p>{el}</p>
      </TextContainer>
    </MultiAnswer>
  );
};

type MultiAnswerProp = {
  width: string;
  height: string;
  selected: boolean;
};

const MultiAnswer = styled.label<MultiAnswerProp>`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-radius: 8px;
  border: 2px solid #d2d5d8;
  border-color: ${(props) => (props.selected ? "#5095EB66" : "#d2d5d8")};
  background: ${(props) =>
    props.selected
      ? "linear-gradient(0deg,rgba(80, 149, 235, 0.4),rgba(80, 149, 235, 0.4)),#fafafa"
      : "#fafafa"};

  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const MultiCheckBoxInput = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  input {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`;
const TextContainer = styled.div<{ hasIcon: boolean }>`
  margin: auto;
  margin-left: ${(props) => (!props.hasIcon ? "34px" : "auto")};
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: GothamSSm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: #292032;
`;

const IconContainer = styled.div`
  margin: auto;
  justify-content: center;
  text-align: center;
  margin-right: 5px;
  img {
    height: 20px;
  }
`;
