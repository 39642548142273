/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import styled from "styled-components";
import { storageRef } from "../../firebase/firebase";
import { CardNode, ChapterNode } from "../../firebase/responseTypes";
import { device } from "../../utils/variables";
import { detect } from "detect-browser";
import {
  VideoCard,
  TextCard,
  TextVideoCard,
  MultilineCard,
  OldMultiLineCard,
} from "../Cards";
import anime from "animejs";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { AppActions } from "../../types";
import {
  showPitch,
  disableCardNavigation,
  enableCardNavigation,
  setDirection,
  setOffsetXPosition,
} from "../../actions";
import { AppState } from "../../store/configureStore";

const browser = detect();

const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

interface CardSegmentProps {
  nodeChild: CardNode;
  firstPage: boolean;
  backClick: () => void;
  nextClick: () => void;
  nextPageAnimation: boolean;
  showPopup: boolean | undefined;
  nodeID: string;
  lastPage: boolean;
  index: number;
  screenshotMode: boolean;
  //======
  // Video Transition Changes
  test: boolean;
  selectedCard: boolean;
  //======
}

interface CardSegmentState {
  mediaURL: string;
  lottieJSON: string;
  nextCardDisabled: boolean;
  offsetXPosition: number;
  hasFetched: boolean;
}

interface RouterProps {
  nodeID: string;
  cardID?: string;
}

type Props = CardSegmentProps & LinkDispatchProps & LinkStateProps;

class CardSegment extends React.Component<Props, CardSegmentState> {
  containerRef = React.createRef<HTMLDivElement>();
  videoRef = React.createRef() as any;
  constructor(props: Props) {
    super(props);

    this.state = {
      mediaURL: "",
      lottieJSON: "",
      nextCardDisabled: false,
      offsetXPosition: 0,
      hasFetched: false,
    };
  }

  componentDidMount = () => {
    this.handleAnimation();
    // if (
    //   this.props.currentIndex === this.props.index &&
    //   !!this.props.nodeChild.media &&
    //   !this.state.mediaURL
    // ) {
    //   this.getMediaUrl(this.props.nodeChild.media);
    // }
  };

  componentDidUpdate = (prevProps: Props, prevState: any) => {
    // console.log(this.props.currentIndex);
    const nodeID = this.props.nodeID;

    if ( // used for when the chapter loads on a specific card with lottie
      this.props.currentIndex === this.props.index &&
      !!this.props.nodeChild.media &&
      !this.state.mediaURL &&
      !this.state.hasFetched
    ) {
      console.log("loaded on index", this.props.currentIndex);
      this.setState({ hasFetched: true });
      this.getMediaUrl(this.props.nodeChild.media);
    }

    if (
      // prevProps.nodeChild.media !== this.props.nodeChild.media &&
      !this.state.hasFetched &&
      !!this.props.nodeChild.media &&
      !this.state.mediaURL &&
      (
        (this.props.currentIndex + 1 === this.props.index || this.props.currentIndex + 2 === this.props.index) || // get URL if it is within 2 cards of the current one
        (this.props.currentIndex - 1 === this.props.index) // get URL if it is the card before the current one (this is needed when loading into a specific card)
      ) 
    ) {
      this.setState({ hasFetched: true });
      this.getMediaUrl(this.props.nodeChild.media);
    }
    if (
      prevProps.nodeChild.media !== this.props.nodeChild.media &&
      !this.props.nodeChild.media
    ) {
      this.setState({
        mediaURL: "",
      });
    }
    if (
      this.props.selectedCard &&
      prevProps.selectedCard !== this.props.selectedCard &&
      !!this.props.showPopup
    ) {
      //set showpitch to true
      this.props.setShowPitch();
      mixpanel.track("ShowPopUp", {
        node_id: nodeID,
        current_chapter: this.props.currentChapter.title,
        current_book: this.props.book_node.title,
      });
    }
    if (this.props.selectedCard && this.props.nodeChild.is_multiline) {
      console.log("disable card navigation");
      this.props.disableCardNavigation();
    }
    if (this.props.selectedCard && this.props.nodeChild.is_multiline_card) {
      console.log("disable card navigation");
      this.props.disableCardNavigation();
    }
  };

  handleAnimation = () => {
    anime({
      targets: this.containerRef.current,
      duration: 850,
      translateY: [0, 0.6],
      easing: "easeOutElastic",
      elasticity: 200,
    });
  };

  getMediaUrl = (media: string) => {
    storageRef
      .child(media)
      .getDownloadURL()
      .then((url: any) => {
        this.setState({ mediaURL: url });
      })
      .then(() => {
        this.getJson();
      });
  };

  getJson = async () => {
    try {
      let response = await fetch(this.state.mediaURL);
      let responseJSON = await response.json();
      this.setState({
        lottieJSON: responseJSON,
      });
    } catch (error) {
      console.log(error);
    }
  };

  enableNextClick = () => {
    this.setState({
      nextCardDisabled: false,
    });
  };

  onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const { setOffsetXPosition } = this.props;
    setOffsetXPosition(event.nativeEvent.offsetX);
  };

  onContainerClick = () => {
    const { offSetX, backClick, nextClick } = this.props;
    const containerWidth = this.containerRef.current?.getBoundingClientRect()
      .width;
    const backNumber = containerWidth ? containerWidth / 2.5 : 0;
    if (offSetX > backNumber) {
      console.log("go forward");
      nextClick();
    } else {
      console.log("go back");
      backClick();
    }
  };

  renderCardType = ({ mediaURL }: { mediaURL: string }) => {
    const {
      firstPage,
      nextPageAnimation,
      selectedCard,
      nodeChild: {
        title,
        card_type,
        text_y,
        image_y,
        key,
        animate_image,
        animate_text,
        gif_loops,
        is_multiline,
        is_multiline_card,
        lines,
        highlights,
      },
      lastPage,
      enableCardNavigation,
      direction,
    } = this.props;
    const { lottieJSON } = this.state;

    // console.log("CARD SEGMENT PROP", this.props);

    // console.log("CARD SEGMENT STATE", this.state);

    // if (firstPage) {
    //   return (
    //     <VideoCard
    //       firstPage={true}
    //       mediaURL={mediaURL}
    //       key={key}
    //       selectedCard={selectedCard}
    //       animate_image={animate_image}
    //       lottieJSON={lottieJSON}
    //       gif_loops={gif_loops}
    //     />
    //   );
    // }
    switch (card_type) {
      case "image_only":
        return (
          <VideoCard
            firstPage={false}
            mediaURL={mediaURL}
            key={key}
            selectedCard={selectedCard}
            animate_image={animate_image}
            lottieJSON={lottieJSON}
            gif_loops={gif_loops}
          />
        );
      case "text_only":
        if (is_multiline && !is_multiline_card) {
          //old multiline style
          return (
            <OldMultiLineCard
              key={key}
              title={title}
              nextPageAnimation={nextPageAnimation}
              selectedCard={selectedCard}
              // animate_text={animate_text}
              lastPage={lastPage}
              enableCardNavigation={enableCardNavigation}
              backClick={this.props.backClick}
              nextClick={this.props.nextClick}
              directionFlow={direction}
              setDirection={this.props.setDirection}
              showAllLines={this.props.screenshotMode}
            />
          );
        }
        if (is_multiline && is_multiline_card) {
          const linesToUse = lines ? lines : [];
          console.log("NEW MULTILINE");
          return (
            <MultilineCard
              key={key}
              title={title}
              nextPageAnimation={nextPageAnimation}
              selectedCard={selectedCard}
              // animate_text={animate_text}
              lastPage={lastPage}
              lines={linesToUse}
              enableCardNavigation={enableCardNavigation}
              backClick={this.props.backClick}
              nextClick={this.props.nextClick}
              directionFlow={direction}
              setDirection={this.props.setDirection}
              showAllLines={this.props.screenshotMode}
            />
          );
        }
        return (
          <TextCard
            key={key}
            title={title}
            nextPageAnimation={nextPageAnimation}
            selectedCard={selectedCard}
            // animate_text={animate_text}
            lastPage={lastPage}
            highlights={highlights}
          />
        );
      case "both":
        return (
          <TextVideoCard
            key={key}
            text_y={text_y}
            image_y={image_y}
            title={title}
            mediaURL={mediaURL}
            nextPageAnimation={nextPageAnimation}
            selectedCard={selectedCard}
            // animate_text={animate_text}
            animate_image={animate_image}
            lottieJSON={lottieJSON}
            gif_loops={gif_loops}
            lastPage={lastPage}
            highlights={highlights}
          />
        );
      default:
        return <div></div>;
    }
  };

  render() {
    // console.log("CardSegment Props", this.props);
    // console.log("CardSegment State", this.state);
    // console.log("BROWSER NAME", browserName);
    const { mediaURL } = this.state;
    const { test, selectedCard, disableCardNavigationState } = this.props;
    if (selectedCard) {
      // console.log("CardSegment Props", this.props);
    }

    return (
      <CardSegmentContainer
        test={test}
        selectedCard={selectedCard ? true : false}
        ref={this.containerRef}
        isFirefox={browser?.name === "fxios"}
        // onMouseMove={this.onMouseMove}
        // onClick={this.onContainerClick}
      >
        <HiddenBackContainer onClick={this.props.backClick}/>
        <HiddenNextContainer onClick={this.props.nextClick}/>
        {this.renderCardType({
          mediaURL: mediaURL,
        })}
      </CardSegmentContainer>
    );
  }
}

interface LinkDispatchProps {
  setShowPitch: () => void;
  disableCardNavigation: () => void;
  enableCardNavigation: () => void;
  setDirection: (direction: string) => void;
  setOffsetXPosition: (offSetX: number) => void;
}

interface LinkStateProps {
  book_node: ChapterNode;
  currentChapter: ChapterNode;
  disableCardNavigationState: boolean;
  currentIndex: number;
  direction: string;
  offSetX: number;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setShowPitch: bindActionCreators(showPitch, dispatch),
  disableCardNavigation: bindActionCreators(disableCardNavigation, dispatch),
  enableCardNavigation: bindActionCreators(enableCardNavigation, dispatch),
  setDirection: bindActionCreators(setDirection, dispatch),
  setOffsetXPosition: bindActionCreators(setOffsetXPosition, dispatch),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  book_node: state.nodeReducer.book_node,
  currentChapter: state.nodeReducer.current_chapter,
  disableCardNavigationState: state.nodeReducer.disableCardNavigation,
  currentIndex: state.nodeReducer.currentIndex,
  direction: state.nodeReducer.direction,
  offSetX: state.nodeReducer.offSetXPosition,
});

//= ===================
//= STYLED COMPONENTS
//= ===================

interface ContainerProps {
  test: boolean;
  selectedCard: boolean;
}

interface VideoProps {
  firstPage: boolean;
}

interface VideoDivProps {
  browser: string;
}

interface Firefox {
  isFirefox?: boolean;
}

type CardSegmentStyleProps = ContainerProps & Firefox;
export const CardSegmentContainer = styled.div<CardSegmentStyleProps>`
  position: ${(props) => (props.selectedCard ? "static" : "absolute")};
  opacity: ${(props) => (props.selectedCard ? 1 : 0)};
  z-index: -1;
  display: ${(props) => (props.selectedCard ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  border-radius: 10px;
  height: ${(props) =>
    props.isFirefox ? "85vh !important " : "75vh !important"};
  width: 90vw;

  @media ${device.ipadPortrait} {
    max-height: 800px;
    max-width: 400px;
    height: 100% !important;
  }
  @media ${device.smallDesktop} {
    max-width: 380px !important;
  }
  @media ${device.ipadProPortrait} {
    height: 100% !important;
  }
  @media ${device.laptop} {
    max-height: 800px;
    max-width: 400px;
  }
  @media ${device.minDesktop} {
    height: 100%;
    width: 400px;
  }
  @media ${device.ipadLandScape} {
    width: 360px;
    height: 640px !important;
  }
  @media ${device.ultraWidemin} {
    height: 65vh;
    max-height: 730px;
  }
`;

export const CardContainer = styled.div`
  z-index: -1;
  width: 100%;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  /* height: 40%; */
  width: 100%;
  /* background-color: lightcoral; */
  @media ${device.laptop} {
    margin-top: 25%;
  }
  @media ${device.minDesktop} {
    margin-top: 25%;
  }
`;

export const TextDiv = styled.div`
  z-index: 0;
`;

export const TextWrapper = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  padding: 0px 20px;
`;

export const FirstLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 2.5vh;
  position: absolute;
`;

export const SecondLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 85vh;
  position: absolute;
`;

export const VideoContainer = styled.div<VideoProps>`
  z-index: 0;
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    margin-top: ${(props) => (props.firstPage ? 0 : "35%")};
    height: ${(props) => (props.firstPage ? "100%" : "60%")};
  }
  @media ${device.desktopL} {
    margin-top: ${(props) => (props.firstPage ? 0 : "35%")};
    height: ${(props) => (props.firstPage ? "100%" : "60%")};
  }
  @media ${device.minDesktop} {
    margin-top: ${(props) => (props.firstPage ? 0 : "25%")};
    height: ${(props) => (props.firstPage ? "100%" : "60%")};
  }
`;

// export const VideoDiv = styled.video<VideoDivProps>`
//   z-index: 0;
//   backface-visibility: hidden;
//   width: 95% !important;
//   height: ${props =>
//     props.browser === "ios" || props.browser === "crios" || iOSSafari
//       ? "10%"
//       : "auto"} !important;
//   /* BELOW CSS to make video shadow box dissappear on ios devices */
//   -webkit-appearance: none;
//   -webkit-mask-image: -webkit-radial-gradient(white, black);
//   -webkit-backface-visibility: hidden;
//   -moz-backface-visibility: hidden;
// `;

const HiddenContainer = styled.div`
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media ${device.minDesktop} {
    left: 50%;
    width: 400px;
    margin-left: -200px;
  }
  @media ${device.ipadLandScape} {
    width: 360px;
  }
`;
const HiddenNextContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  height: 100%;
  width: 63%;
  background-color: transparent;
`;
const HiddenBackContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  z-index: 50;
  height: 100%;
  width: 37%;
  background: transparent;
`;

export default connect(mapStateToProps, mapDispatchToProps)(CardSegment);

// export default CardSegment;
