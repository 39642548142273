import styled from "styled-components";
import * as React from "react";
import anime from "animejs";
import { detect } from "detect-browser";
import { iOSSafari } from "../../utils/variables";
const browser = detect();

interface GreenLineProps {
  direction: boolean;
  selectedCard: boolean;
  disabled?: boolean;
}

interface GreenLineState {}

class GreenLine extends React.Component<GreenLineProps, GreenLineState> {
  greenLine = React.createRef() as any;

  componentDidMount() {
    this.handleAnimation();
  }
  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      this.props.selectedCard &&
      !this.props.disabled &&
      prevProps.selectedCard !== this.props.selectedCard
    ) {
      this.handleAnimation();
    }
  };

  handleAnimation = () => {
    const { direction } = this.props;
    const browserName = browser ? browser.name : "";

    const start = browserName === "crios" || iOSSafari ? [75, -75] : [125, -75];
    const end = browserName === "crios" || iOSSafari ? [-75, 75] : [-75, 125];
    anime({
      targets: this.greenLine.current,
      duration: 2000,
      translateX: direction ? start : end,
      opacity: [1, 0],
      // easing: "easeOutSine"
    });
  };

  render() {
    return <GreenLineDiv ref={this.greenLine} />;
  }
}

export default GreenLine;

interface GreenLineDivProps {
  // setTarget: (element: any) => void;
}

//= ===================
//= STYLED COMPONENTS
//= ===================

const GreenLineDiv = styled.div<GreenLineDivProps>`
  width: 60px;
  border-bottom: solid 4px #28bda4;
  border-radius: 3px;
  /* position: relative; */
  z-index: 10;
`;
