import * as React from "react";
import styled from "styled-components";
import { device } from "../../utils/variables";
import { AppText } from "../UI";
import RLoading from "react-spinkit";

class SubmitLoading extends React.Component {
  render() {
    return (
      <LoadingSegmentContainer>
        <LoadingImgContainer>
          <RLoading
            name="line-spin-fade-loader"
            color={"#26639B"}
            fadeIn="none"
          />
        </LoadingImgContainer>
        <TextDiv>
          <AppText
            style={{
              fontFamily: "GothamSSm",
              width: "100%",
              textAlign: "center",
            }}
            fontSize={"30px"}
          >
            Loading...
          </AppText>
        </TextDiv>
      </LoadingSegmentContainer>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

const LoadingSegmentContainer = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
`;

const LoadingImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30%;
  margin-top: 10%;
`;

const TextDiv = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
`;

export default SubmitLoading;
