import * as React from "react";
import styled from "styled-components";
import { AppState } from "../../store/configureStore";
import { AppActions } from "../../types";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  device,
  GREEN_ACCENT,
  BOOK_COLOR,
  LIGHT_GREY,
} from "../../utils/variables";
import { AppText } from "../UI";

interface ProgressBarProps {
  childrenLength: number;
  firstCard: boolean;
  book_title: string;
  author: string;
}

interface ProgressBarState {}

type Props = LinkDispatchProps & LinkStateProps & ProgressBarProps;

class ProgressBar extends React.Component<Props, ProgressBarState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { firstCard, book_title, amazonLink } = this.props;
    if (firstCard) {
      return (
        <TitleDiv>
          {/* <TitleContent href={amazonLink} target="_blank">{`${
            book_title ? book_title : ""
          }`}</TitleContent> */}
          <TitleContentText>
            {book_title && (
              <a href={amazonLink} target="_blank" rel="noopener noreferrer">
                {book_title}
              </a>
            )}
            {/* {` ${book_title ? ": An Illustrated Summary" : ""}`} */}
          </TitleContentText>
        </TitleDiv>
      );
    }
    return (
      <ProgressBarDiv>
        <FillerDiv
          childrenLength={this.props.childrenLength}
          currentIndex={this.props.currentIndex}
        />
      </ProgressBarDiv>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

interface FillerProps {
  childrenLength: number;
  currentIndex: number;
}

const ProgressBarDiv = styled.div`
  position: absolute;
  height: 10px;
  width: 100%;
  z-index: 100;
  border-radius: 50px;
  background-color: ${LIGHT_GREY};
  bottom: -5%;
  @media ${device.mobileS} {
    max-width: 100%;
  }
  @media ${device.mobileM} {
    max-width: 100%;
  }
  @media ${device.mobileL} {
    max-width: 100%;
    bottom: -6%;
  }
  @media ${device.ipadPortrait} {
    max-width: 100%;
    bottom: -5%;
  }
  @media ${device.ipadLandScape} {
    bottom: -15% !important;
  }

  @media ${device.ipadProLandscape} {
    max-width: 100%;
    bottom: -20% !important;
  }
  @media ${device.minDesktop} {
    max-width: 100%;
    width: 400px;
    bottom: -5%;
  }
  @media ${device.ultraWidemin} {
    bottom: -5% !important;
  }
  /* @media ${device.laptopL} {
    max-width: 100%;
    bottom: 2%;
  } */
`;

const TitleDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 100%;
  z-index: 100;
  bottom: -7%;
  @media ${device.ipadProLandscape} {
    max-width: 100%;
    bottom: -20% !important;
  }
  @media ${device.mobileS} {
    max-width: 100%;
  }
  @media ${device.mobileM} {
    max-width: 100%;
  }
  @media ${device.ipadLandScape} {
    bottom: -8%;
  }
  @media ${device.mobileL} {
    max-width: 100%;
    bottom: -10%;
  }
  @media ${device.ipadPortrait} {
    max-width: 100%;
    bottom: -10%;
  }
  @media ${device.ipadLandScape} {
    bottom: -15% !important;
  }

  @media ${device.minDesktop} {
    max-width: 100%;
    width: 400px;
    bottom: -7%;
  }
  @media ${device.ultraWidemin} {
    bottom: -7% !important;
  }
`;

const TitleContentText = styled(AppText)`
  font-family: GothamSSm;
  color: #716b78;
  font-size: 20px;
  text-align: center;
  @media ${device.mobileL} {
    color: #716b78 !important;
    font-size: 14px;
    > a {
      color: #716b78 !important;
    }
  }
  @media ${device.tablet} {
    color: #716b78 !important;
    > a {
      color: #716b78 !important;
    }
  }
  @media ${device.ipadProPortrait} {
    color: #716b78 !important;
    font-size: 20px;
    > a {
      color: #716b78 !important;
    }
  }
  @media ${device.ipadLandScape} {
    color: #fff;
    > a {
      color: #fff;
    }
  }
  @media ${device.desktop} {
    color: #fff;
    > a {
      color: #fff;
    }
  }
  @media ${device.minDesktop} {
    color: #fff;
    > a {
      color: #fff;
    }
  }
  @media ${device.ipadLandScape} {
    font-size: 18px;
  }
`;

const FillerDiv = styled.div<FillerProps>`
  background-color: ${(props) =>
    props.currentIndex === props.childrenLength - 1
      ? BOOK_COLOR
      : GREEN_ACCENT};
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  /* -webkit-transition: width 0.2s ease-in; */
  width: ${(props) =>
    `${(props.currentIndex / (props.childrenLength - 1)) * 100}%`};
`;

interface LinkDispatchProps {}

interface LinkStateProps {
  currentIndex: number;
  amazonLink: string;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  currentIndex: state.nodeReducer.currentIndex,
  amazonLink: state.nodeReducer.book_node.amazon_url,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
