import * as React from "react";
import styled from "styled-components";
import { GREEN_ACCENT, LIGHT_GREY } from "../../utils/variables";

interface ProgressBarProps {
  childrenLength: number;
  currentIndex: number;
  firstIndex: boolean;
}

interface ProgressBarState {}

type Props = ProgressBarProps;

class OnboardingProgress extends React.Component<Props, ProgressBarState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ProgressBarDiv>
        <FillerDiv
          childrenLength={this.props.childrenLength}
          currentIndex={this.props.firstIndex ? 0.5 : this.props.currentIndex}
        />
      </ProgressBarDiv>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

interface FillerProps {
  childrenLength: number;
  currentIndex: number;
}

const ProgressBarDiv = styled.div`
  position: absolute;
  height: 10px;
  width: 100%;
  z-index: 100;
  border-radius: 50px;
  background-color: ${LIGHT_GREY};
  top: -5%;
`;

const FillerDiv = styled.div<FillerProps>`
  background-color: ${GREEN_ACCENT};
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
  /* -webkit-transition: width 0.2s ease-in; */
  width: ${(props) =>
    `${(props.currentIndex / (props.childrenLength - 1)) * 100}%`};
`;

export default OnboardingProgress;
