import React, { Component } from "react";
import { Provider } from "react-redux";
import { createGlobalStyle } from "styled-components";
import { store } from "./store/configureStore";
import AppRouter from "./routers/AppRouter";
import GlobalFonts from "./Fonts/Fonts";

// * Styled Components Global Styles
const GlobalStyles = createGlobalStyle`
  html{
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap');
    margin: 0;
    min-height: 100vh;
    height: 100%;
    /* height: 100vh; */
    
  }
  body, #root {
    /* min-height: 100%; */
    height: inherit;
    min-height: inherit;
    margin: 0;
    overflow: hidden;
  }
  div {
    box-sizing: border-box;
  }
`;

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/* // <PersistGate loading={null} persistor={persistor}> */}
        <>
          <GlobalStyles />
          <GlobalFonts />
          {/* <ThemeProvider theme={theme}> */}
          <AppRouter />
        </>
      </Provider>
    );
  }
}

export default App;
