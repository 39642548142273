/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import lottie from "lottie-web";
import { AnimationItem } from "lottie-web/build/player/lottie_light";

interface LottieMediaProps {
  mediaURL?: string;
  // firstPage: boolean;
  // selectedCard: boolean;
  animate_image?: boolean;
  image_y?: number;
  lottieJSON: string;
  gif_loops: number;
  selectedCard: boolean;
  hardcoded?: boolean;
  setNextButtonState?: (flag: boolean) => void;
}

//gif loops
// 0 === play once
// 1 === loop
// 2 === Static
// 3 === show end

interface LottieMediaState {
  mediaURL: string;
  played: boolean;
  count: number;
  animation: any;
}

let animObj: AnimationItem | null | void = null;

class LottieMedia extends React.Component<LottieMediaProps, LottieMediaState> {
  animBox!: any;
  constructor(props: LottieMediaProps) {
    super(props);
    this.state = {
      mediaURL: "",
      played: false,
      count: 0,
      animation: null,
    };
  }

  componentDidUpdate(
    prevProps: { lottieJSON: string; selectedCard: boolean },
    prevState: { count: number }
  ) {
    if (this.props.lottieJSON && !this.state.animation) {
      animObj = lottie.loadAnimation({
        container: this.animBox, // the dom element that will contain the animation
        renderer: "svg",
        loop: 0,
        autoplay: false,
        animationData: this.props.lottieJSON, // the path to the animation json
      }) as AnimationItem;
      this.setState({
        animation: animObj
      });
    }

    if (this.props.selectedCard && this.state.animation) {
      this.state.animation.goToAndPlay(0);
    }
  };

  render() {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        ref={(ref) => (this.animBox = ref)}
      ></div>
    );
  }
}

export default LottieMedia;
