const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "834px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileSize: "screen and (max-width: 768px)",
  laptopSize: "screen and (max-width: 992px)",
  desktopSize: "screen and (max-width: 1280px)",
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  minMobile: `(min-width: ${size.mobileL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(only screen max-width: ${size.desktop})`,
  minDesktop: `(min-width: ${size.laptop})`,
  minDesktopScreen: `(min-width: ${size.laptop + 1})`,
  ipadPortrait: `only screen and (width : 768px) and (height : 1024px) and (orientation:portrait)`,
  ipadLandScape: `only screen and (width : 1024px) and (height : 768px) and (orientation:landscape)`,
  ipad11Portrait: `only screen and (width : 834px) and (height : 1112px) and (orientation:portrait)`,
  ipad11LandScape: `only screen and (width : 1112px) and (height : 834px) and (orientation:landscape)`,
  ipadProPortrait: `screen and (min-width: 1024px) and (min-height: 1320px) and (orientation:portrait)`,
  ipadProLandscape: `screen and (min-width: 1320px) and (min-height: 1024px) and (orientation:landscape)`,
  desktopMaxHeightBreakPoint: `(max-height: 660px)`,
  smallDesktop: `(min-width: 600px) and (max-width: 1440px)`,
  ultraWidemin: `(min-width: ${size.desktop})`,
  iphoneXR: `only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2) }`,
};

const ua = window.navigator.userAgent;
export const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
export const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

//=***COLORS***=//
export const THEME_COLOR = "#1E85E2";
export const GREEN_ACCENT = "#28BDA4";
export const ORANGE = "#EC8F58";
export const BOOK_COLOR = "#F8D365";
export const LIGHT_GREY = "#E0E0E0";

export const COUNTRY_FILTER = ["US", "NZ", "CA", "AU"];

export const DEFAULT_META = {
  title: "Imprint",
  subtitle: "",
  parentid: "",
  book_title: "",
  author: "Imprint",
  image_link:
    "https://firebasestorage.googleapis.com/v0/b/know-d8bc0.appspot.com/o/1582064921584-image-KeyArt_DW_01.png?alt=media&token=8c8db0fc-6e87-4451-bb44-141182847cdb",
};

export const PROMO_NAMES = [
  "happy-plant-social",
  "happy-plant-email",
  "linkedin",
];

export const TERMS_LINK =
  "https://docs.google.com/document/d/1CF6JLDUCvQpdKegxKuM4Sm96QFlfwhuORv1sEGMfHPw/edit";
export const PRIVACY_LINK =
  "https://docs.google.com/document/d/1XwD2NWvdf4zZhRKYjBvPD4Q7S0f4-OhnaK8orj0GpJM/edit";
