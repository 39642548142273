import styled from "styled-components";

export interface AppInputProps {
  width?: number;
  fontSize?: number;
}

export const AppInput = styled.input<AppInputProps>`
  width: ${(props) => (props.width ? `${props.width}%` : `100%`)};
  height: 32px;
  max-width: 250px;
  border-width: 0;
  border-radius: 12px;
  border-color: transparent;
  padding-left: 20px;
  font-size: ${(props) => (!!props.fontSize ? props.fontSize : 12)}px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  font-family: GothamSSm;
  -moz-appearance: textfield;
  :focus {
    outline: none;
  }
  ::placeholder {
    color: #7dc4ff;
  }
`;
