import { Highlight } from "../firebase/responseTypes";

export const findAndReplaceHighlight = (
  HTMLString: string,
  highlights: { [name: string]: Highlight }
) => {
  let classList = "";
  let highlightClassList = "";
  let finalHTML = HTMLString;

  for (const value in highlights) {
    classList += "." + highlights[value].id + ",";
    highlightClassList += "." + highlights[value].id + ".highlight,";
    finalHTML = finalHTML.replace(
      `<select id="${highlights[value].id}"><option>${highlights[value].id}</option></select>`,
      highlights[value].html
    );
  }

  classList = classList.slice(0, -1);
  highlightClassList = highlightClassList.slice(0, -1);
  // highlights.forEach((highlight, index) => {
  //   if (index !== highlights.length) {
  //     classList += "." + highlight.id + ",";
  //     highlightClassList += "." + highlight.id + ".highlight" + ",";
  //   } else {
  //     classList += "." + highlight.id;
  //     highlightClassList += "." + highlight.id + ".highlight";
  //   }
  //   finalHTML = finalHTML.replace(
  //     `<select id=${highlight.id}><option>${highlight.id}</option></select>`,
  //     highlight.html
  //   );
  // });

  let highlightedTextContainerDiv = `
            <div class="highlightedTextContainer">
               <font face="SentinelSSm-Book">
                  ${finalHTML}
               </font>
            </div>
            `;

  let highlightCSS = `
  <style>
  .highlightedTextContainer {
     text-align: center;
  }
  a {
     position: relative;
     font-size: 19px;
     margin: 0;
  }
  ${classList} {
     background-size: 200%;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  ${highlightClassList} {
    background-position: -100%;
  }
  </style>
  `;

  // console.log("highlightedTextContainerDiv", highlightCSS);

  return highlightedTextContainerDiv + highlightCSS;
};
