import * as React from "react";
import styled from "styled-components";
import { CardNode } from "../../firebase/responseTypes";
import { device } from "../../utils/variables";
import { CardSegment, ProgressBar } from "../Segments/";
import anime from "animejs";
import { AppState } from "../../store/configureStore";
import { AppActions } from "../../types";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { detect } from "detect-browser";
const browser = detect();

const innerHeight = window.innerHeight * 0.01;

interface ChapterContainerProps {
  nodeChild: CardNode;
  firstPage: boolean;
  backClick: () => void;
  nextClick: () => void;
  nextPageAnimation: boolean;
  showPitch: boolean;
  nodeID: string;
  lastPage: boolean;
  book_title: string;
  author: string;
  screenshotMode: boolean;
  //======
  // Video Transition Changes
  allNodes: CardNode[];
  //======
}

interface ChapterContainerState {}

type Props = LinkDispatchProps & LinkStateProps & ChapterContainerProps;

class ChapterContainer extends React.Component<Props, ChapterContainerState> {
  containerRef = React.createRef() as any;
  arrowRef = React.createRef() as any;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: Props) {
    super(props);
  }

  componentDidMount = () => {
    //animate function
    // this.handleAnimation();
    this.handleArrowAnimation();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.showPitch) {
      this.handleArrowAnimation();
    }
  };

  handleAnimation = () => {
    anime({
      targets: this.containerRef.current,
      duration: 1500,
      scale: [0, 1],
      easing: "cubicBezier(.5, .05, .1, .3)",
    });
  };

  handleArrowAnimation = () => {
    anime({
      targets: this.arrowRef.current,
      duration: 500,
      translateY: [10, 20],
      easing: "spring(1, 80, 10, 0)",
      loop: true,
    });
  };

  render() {
    const {
      backClick,
      nextClick,
      nextPageAnimation,
      allNodes,
      currentIndex,
      lastPage,
      book_title,
      author,
    } = this.props;
    // console.log("NODES", allNodes.slice(currentIndex, currentIndex + 3));
    return (
      <ChapterContainerDiv
        ref={this.containerRef}
        isFirefox={browser?.name === "fxios"}
      >
        {allNodes

          .slice()
          .map((node, index) => {
            return (
              <CardSegment
                key={node.key}
                index={index}
                nodeChild={node}
                firstPage={index === 0}
                backClick={backClick}
                nextClick={nextClick}
                nextPageAnimation={nextPageAnimation}
                selectedCard={currentIndex === index}
                test={true}
                showPopup={node.show_popup}
                nodeID={this.props.nodeID}
                lastPage={lastPage}
                screenshotMode={this.props.screenshotMode}
              />
            );
          })}
        {/* <CardSegment
          //======
          // Video Transition Changes
          allNodes={allNodes}
          test={false}
          //======
          nodeChild={nodeChild}
          firstPage={firstPage}
          backClick={backClick}
          nextClick={nextClick}
          nextPageAnimation={nextPageAnimation}
        /> */}
        {/* {showPitch && (
          <ShowPitchArrowContainer ref={this.arrowRef}>
            <FiChevronsDown size={30} color={"#2D7DD2"} />
          </ShowPitchArrowContainer>
        )} */}
        {!this.props.screenshotMode && (
          <ProgressBar
            childrenLength={allNodes.length}
            firstCard={currentIndex === 0}
            book_title={book_title}
            author={author}
          />
        )}
      </ChapterContainerDiv>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

interface Firefox {
  isFirefox?: boolean;
}

const ChapterContainerDiv = styled.div<Firefox>`
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  position: relative;
  @media ${device.mobileS} {
    max-width: 100vh;
    height: ${(props) =>
      props.isFirefox
        ? "85vh !important "
        : `${85 * innerHeight}px !important`};
  }
  @media ${device.mobileM} {
    max-width: 100vh;
    height: ${(props) =>
      props.isFirefox
        ? "85vh !important "
        : `${85 * innerHeight}px !important`};
  }
  @media ${device.mobileL} {
    max-width: 100vh;
    height: ${(props) =>
      props.isFirefox
        ? "85vh !important "
        : `${85 * innerHeight}px !important`};
    top: 2%;
    position: fixed !important;
  }
  @media ${device.laptop} {
    max-width: 100vh;
    height: 85vh;
    /* margin-top: 5%; */
    /* margin-bottom: 10%; */
  }

  @media ${device.ipadProPortrait} {
    top: 12% !important;
    height: 65% !important;
  }
  @media ${device.ipadProLandscape} {
    height: 65% !important;
    top: 14% !important;
  }

  @media ${device.ipadPortrait} {
    height: 70% !important;
    top: 12% !important;
  }
  @media ${device.ipadLandScape} {
    width: 360px;
    height: 640px !important;
    top: 10% !important;
  }

  @media ${device.ipadLandScape} {
    height: 75% !important;
  }
  @media ${device.smallDesktop} {
    max-width: 380px !important;
  }
  @media ${device.laptopL} {
    max-width: 400px;
    max-height: 800px;
    height: 85%;
    position: absolute;
    top: 3%;
    /* margin-top: 5%; */
    /* margin-bottom: 10%; */
  }

  @media ${device.minDesktop} {
    max-width: 400px;
    max-height: 800px;
    height: 85%;
    position: absolute;
    top: 3%;
  }
  @media ${device.ultraWidemin} {
    height: 70% !important;
    top: 3% !important;
    max-height: 730px;
  }
`;

// const ShowPitchArrowContainer = styled.div`
//   position: absolute;
//   display: none;
//   flex-direction: row;
//   justify-content: center;
//   align-content: center;
//   bottom: 20px;
//   @media ${device.laptop} {
//     display: flex;
//     bottom: 12%;
//   }
// `;

interface LinkDispatchProps {}

interface LinkStateProps {
  currentIndex: number;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  currentIndex: state.nodeReducer.currentIndex,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChapterContainer);
