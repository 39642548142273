import { ADD_ANSWER } from "../types";

const onboardingDefaultState = [
  { name: "" },
  { welcome: "viewed" },
  { age: "" },
  { howDoYouLearn: "" },
  { whichTopicsDoYouWant_1: "" },
  { whichTopicsDoYouWant_2: "" },
  { doYouAgree_rememberBetter: "" },
  { doYouAgree_spaceOut: "" },
  { doYouAgree_lessTimeOnSocialMedia: "" },
  { doYouAgree_moreTimeToReadBooks: "" },
  { topicSelection_meditationOrMacroeconomics: "" },
  { topicSelection_existentialismOrNegotiation: "" },
  { topicSelection_nutritionOrProductivity: "" },
  { startingSlide: "viewed" },
];

const onboardingReducer = (state = onboardingDefaultState, action: any) => {
  switch (action.type) {
    case ADD_ANSWER:
      const { answer, index }: { answer: string; index: number } = action;
      const keys = Object.keys(onboardingDefaultState[index]);
      const first_key = keys[0];
      if (first_key) {
        (onboardingDefaultState[index] as any)[first_key] = answer;
      }

      console.log(onboardingDefaultState);
      return onboardingDefaultState;
    default:
      return onboardingDefaultState;
  }
};

export default onboardingReducer;
