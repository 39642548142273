/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { device } from "../../utils/variables";
import { LottieVideo } from "../Segments";
import { BooleanTypeAnswer } from "./BooleanTypeAnswer";
import { ImageAnswers } from "./ImageAnswer";
import { MultiSelectAnswer } from "./MultiSelectAnswer";
import { SingleSelectAnswer } from "./SingleSelectAnswer";
import { TextTypeAnswer } from "./TextTypeAnswer";

type Props = {
  answer_type: string;
  answers: string | any[];
  currentIndex: number;
  require_input: boolean;
  icons?: string[];
  lastQuestion: boolean;
  setNext: (Clickable: boolean) => void;
  handleSubmit: (Clickable: boolean) => void;
};

export const Answers = ({
  answers,
  answer_type,
  require_input,
  currentIndex,
  icons,
  setNext,
  lastQuestion,
  handleSubmit,
}: Props) => {
  const [answer, setAnswer] = useState("");
  const [checkBox, setCheckBox] = useState<boolean[] | []>([]);
  const [completed, setCompleted] = useState<boolean>(false);
  const [nextButtonState, setNextButtonState] = useState<boolean>(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      answer_type === "select" ||
      answer_type === "multiselect" ||
      answer_type === "multiselect_two_row" ||
      answer_type === "image_select"
    ) {
      if (typeof answers === "object") {
        setCheckBox(answers.map((el) => false));
      }
    }
    if (
      answer_type !== "boolean" &&
      answer_type !== "image_select" &&
      answer_type !== "lotti"
    ) {
      setNextButtonState(true);
    }
  }, [answers, answer_type]);

  // On Answer Submition
  useEffect(() => {
    if (answer_type === "image_select" || answer_type === "boolean") {
      setTimeout(() => {
        handleAnswerSubmit();
      }, 200);
    }
  }, [answer]);

  const multiSelectType = (group: string) => {
    if (typeof answers === "object") {
      let width = "295px";
      let height = "48px";

      if (group === "Multi2") {
        width = "140px";
        height = "64px";
      }

      return answers.map((el, index) => {
        return (
          <MultiSelectAnswer
            key={index}
            el={el}
            width={width}
            height={height}
            index={index}
            icon={icons ? icons[index] : null}
            selected={checkBox[index] || false}
            handleClick={handleMultiClick}
          />
        );
      });
    }
  };

  const singleSelectType = () => {
    if (typeof answers === "object") {
      return answers.map((el, i) => {
        return (
          <SingleSelectAnswer
            el={el}
            key={i}
            selected={checkBox[i]}
            index={i}
            handleClick={handleSingleClick}
          />
        );
      });
    }
  };

  const booleanSelectType = () => {
    if (typeof answers === "object") {
      return <BooleanTypeAnswer answers={answers} handleClick={handleClick} />;
    }
  };

  const imageSelectType = () => {
    if (typeof icons === "object") {
      return icons.map((el, i) => {
        return (
          <div key={i} onClick={(e) => handleImageClick(e, i, answers[i])}>
            <ImageAnswers
              image_src={el}
              selected={checkBox[i]}
              completed={completed}
              image_index={i}
            />
          </div>
        );
      });
    }
  };

  const handleCheckBoxChange = (
    index: number,
    single: boolean | undefined = false
  ) => {
    const data = checkBox.slice().map((el, i) => {
      if (single) {
        if (i === index) {
          return true;
        } else {
          return false;
        }
      } else {
        if (i === index) {
          return !el;
        } else {
          return el;
        }
      }
    });
    setNext(true);
    setCheckBox(data);
    setObjectTypeAnswer(data);
  };

  const setObjectTypeAnswer = (data: boolean[]) => {
    let new_answer: string[] = [];
    data.forEach((el, i) => {
      if (data[i]) {
        new_answer.push(answers[i]);
      }
      return false;
    });
    const new_answer_data = new_answer.join(", ");
    setAnswer(new_answer_data);
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setAnswer(e.target.value);
  };

  const handleClick = (value: string) => {
    if (value) {
      setAnswer(value);
    }
  };

  const handleSingleClick = (index: number) => {
    console.log(index);
    handleCheckBoxChange(index, true);
  };

  const handleMultiClick = (index: number) => {
    handleCheckBoxChange(index);
  };

  const handleImageClick = (e: any, index: number, value: string) => {
    e.preventDefault();
    const data = checkBox.slice().map((el, i) => {
      if (i === index) {
        return true;
      } else {
        return false;
      }
    });
    setCheckBox(data);
    setCompleted(true);
    setAnswer(value);
  };

  const isNext = () => {
    return (
      !require_input ||
      !!answer.length ||
      !!checkBox.filter((el) => el === true).length ||
      completed
    );
  };

  const handleAnswerSubmit = () => {
    if (answer_type === "text") {
      localStorage.setItem("username", answer);
    }

    if (answer_type !== "lottie") {
      dispatch({
        type: "ADD_ANSWER",
        index: currentIndex,
        answer: answer,
      });
    }

    handleSubmit(!require_input || !!answer.length || completed);
  };

  const buttonDisplay = () => {
    if (nextButtonState === true) {
      return "flex";
    }
    return "none";
  };

  const renderAnswer = () => {
    switch (answer_type) {
      case "lotti":
        return (
          <LottieVideo
            lottieJSON={answers[0]}
            hardcoded={true}
            animate_image={false}
            selectedCard={true}
            gif_loops={0}
            setNextButtonState={setNextButtonState}
          />
        );
      case "text":
        return (
          <TextTypeAnswer
            answers={typeof answers === "string" ? answers : ""}
            handleFormChange={handleFormChange}
          />
        );
      case "select":
        return <SingleContainer>{singleSelectType()}</SingleContainer>;

      case "multiselect":
        return <MultiContainer>{multiSelectType("Multi1")}</MultiContainer>;
      case "multiselect_two_row":
        return <MultiContainer>{multiSelectType("Multi2")}</MultiContainer>;
      case "image_select":
        return <ImageSelectContainer>{imageSelectType()}</ImageSelectContainer>;
      case "boolean":
        return <div>{booleanSelectType()}</div>;
    }
  };

  return (
    <AnswerContainer answer_type={answer_type} lastQuestion={lastQuestion}>
      {renderAnswer()}
      <ButtonContainer displayType={buttonDisplay()}>
        <NextButton next={isNext()} onClick={handleAnswerSubmit}>
          <NextFont>Next </NextFont>
        </NextButton>
      </ButtonContainer>
    </AnswerContainer>
  );
};
const AnswerContainer = styled.div<{
  answer_type: string;
  lastQuestion: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: ${(props) =>
    props.answer_type === "lotti" && !props.lastQuestion
      ? "initial"
      : "relative"};
  height: ${(props) =>
    props.answer_type === "lotti" && !props.lastQuestion ? "auto" : "100%"};
  width: 343px;
`;

const SingleContainer = styled.div`
  margin-top: 20%;
`;

const MultiContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 40%;
`;

const ImageSelectContainer = styled.div`
  margin: auto;
  margin-top: 40%;
`;

const ButtonContainer = styled.div<{ displayType: string }>`
  display: ${(props) => props.displayType};
  width: 100%;
  position: absolute;
  bottom: -65px;

  @media ${device.mobileSize} {
    height: 52px;
    bottom: 30px;
  }
`;

const NextButton = styled.div<{ next: boolean }>`
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: 0 auto;

  background: ${(props) =>
    props.next === true
      ? "linear-gradient(90deg,rgba(115, 123, 253, 0.75) 1.68%,rgba(50, 123, 210, 0.75) 116.81%),#1e85e2"
      : "#E3E4E5"};
  border-radius: 32px;
  :hover {
    cursor: ${(props) => (props.next === true ? "pointer" : "default")};
  }
  p {
    color: ${(props) => (props.next === true ? "#fefefe" : "#524D57")};
  }
  position: relative;
  margin: auto;

  width: 343px;
  @media ${device.mobileSize} {
    width: 295px;
  }
`;

const NextFont = styled.p`
  height: 20px;
  left: calc(50% - 42px / 2);
  top: calc(50% - 20px / 2);
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 6px;
`;
