import React, { Component } from "react";
import styled from "styled-components";
import anime from "animejs";
// import { detect } from "detect-browser";
import { device } from "../../utils/variables";
import { FiChevronsUp } from "react-icons/fi";
import { AppText } from "../UI";
import { Download_White_Svg } from "../../assets";

// const browser = detect();

// const ua = window.navigator.userAgent;
// const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
// const webkit = !!ua.match(/WebKit/i);
// const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

interface MobilePitchSegmentProps {}

interface MobilePitchSegmentState {}

type Props = MobilePitchSegmentProps;

class MobilePitchSegment extends Component<Props, MobilePitchSegmentState> {
  arrowRef = React.createRef() as any;
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.handleArrowAnimation();
  };

  handleArrowAnimation = () => {
    anime({
      targets: this.arrowRef.current,
      duration: 500,
      translateY: [10, 20],
      easing: "linear",
      loop: true,
    });
  };

  render() {
    return (
      <MobilePitchContainer>
        <MobilePitchContentWrapper>
          <MobilePitchContentContainer>
            <FiChevronsUp size={30} color={"#fff"} />
            <StyledHeaderText>Read more in the Imprint app for:</StyledHeaderText>
            <StyledBoldedText>Better Animation</StyledBoldedText>
            <StyledBoldedText>Interactive Learning</StyledBoldedText>
            <StyledBoldedText>Large Library of Titles</StyledBoldedText>
            <StyledDownloadText>Read more in the Imprint app</StyledDownloadText>
            <ImprintAppDownloadContainer>
              <AppLinkImg src={Download_White_Svg} />
            </ImprintAppDownloadContainer>
            <StyledCopyText>
              Get visual Insights from: <br />7 Habits of Highly Successful
              People, Thinking Fast and Slow, Grit, Power of Habit, and many
              more
            </StyledCopyText>
          </MobilePitchContentContainer>
        </MobilePitchContentWrapper>
        <LogoContainer></LogoContainer>
      </MobilePitchContainer>
    );
  }
}

const MobilePitchContainer = styled.div`
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #7b9be9 0%, #1c4dc1 100%);
  display: none;
  @media ${device.laptop} {
    /* margin-top: 40px; */
    display: flex;
  }
  @media ${device.tablet} {
    /* margin-top: 40px; */
  }
  @media ${device.ipadProPortrait} {
    display: flex;
  }
`;

const MobilePitchContentWrapper = styled.div`
  padding: 25px 20px 10px 20px;
  position: relative;
  width: 100%;
  height: 70vh;
`;

const MobilePitchContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  justify-content: space-around;
  height: 100%;
`;

const StyledHeaderText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-top: 10px;
  text-align: center;
`;

const StyledBoldedText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 20px;
  line-height: 23px;
  color: #fff;
  font-weight: 800;
  margin-top: 10px;
  text-align: center;
`;

const StyledDownloadText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin-top: 40px;
  text-align: center;
`;

const ImprintAppDownloadContainer = styled.div`
  max-width: 120px;
  margin-top: 15px;
`;

const AppLinkImg = styled.img`
  width: 100%;
`;

const StyledCopyText = styled(AppText)`
  font-family: GothamSSm;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  margin-top: 20px;
  text-align: left;
`;

const LogoContainer = styled.div`
  background: #4350a4;
  height: 30vh;
  width: 100%;
`;

export default MobilePitchSegment;
