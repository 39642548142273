import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ChapterPage, Redirect as AppleRedirect } from "../Components/Pages";
import Onboarding from "../Components/Onboarding/Onboarding";
import { Questions } from "../Components/Onboarding/Questions";
import { Subscription } from "../Components/Onboarding/Subscription";
import { EmailNotification } from "../Components/Onboarding/EmailNotification";
import { SubscriptionComplete } from "../Components/Onboarding/SubscriptionComplete";
export const history = createBrowserHistory({ forceRefresh: true });

const AppRouter = () => (
  <BrowserRouter>
    <>
      <Switch>
        <Route exact path="/chapter/:nodeID" component={ChapterPage} />
        <Route exact path="/chapter/:nodeID/:cardID" component={ChapterPage} />
        {/* <Route
          exact
          path="/chapter/:nodeID/:cardID?/:ios?"
          component={ChapterPage}
        /> */}
        <Route path="/redirect/:nodeID" component={AppleRedirect} />
        <Route
          path="/appstore"
          component={() => {
            window.location.href =
              "https://apps.apple.com/us/app/imprint-learn-visually/id1482780647";
            return null;
          }}
        />

        <Route path="/onboarding" exact component={Onboarding} />
        <Route path="/onboarding/questions" exact component={Questions} />
        <Route path="/onboarding/subscription" exact component={Subscription} />
        <Route
          path="/onboarding/complete"
          exact
          component={SubscriptionComplete}
        />
        <Route
          path="/onboarding/emailnotification"
          exact
          component={EmailNotification}
        />

        <Route
          path="/"
          exact
          component={() => {
            window.location.replace("https://imprintapp.com/");
            return null;
          }}
        />
        <Route
          path="/chapter"
          exact
          component={() => {
            window.location.replace("https://imprintapp.com/");
            return null;
          }}
        />
        <Route
          path="/"
          exact
          component={() => {
            window.location.replace("https://imprintapp.com/");
            return null;
          }}
        />
      </Switch>
    </>
  </BrowserRouter>
);

export default AppRouter;
