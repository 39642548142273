import React, { useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../../utils/variables';
import { IoIosClose } from "react-icons/io";
import { PRIMARY_GRADIENT } from '../../utils/theme';


interface LastPagePromoProps {
  closeLastPage: () => void;
  // disabled?: boolean;
  nodeID: string;
  current_chapter_title: string;
  book_node: string;
  promo: string;
  // showLastPage?: boolean;
  // ios?: string;
};

const LastPagePromo: React.FC<LastPagePromoProps> = ({ closeLastPage, nodeID, current_chapter_title, book_node, promo }) => {
  useEffect(() => {
    mixpanel.track("EndofChapter", {
      node_id: nodeID,
      current_chapter: current_chapter_title,
      current_book: book_node,
    });
  }, [nodeID, current_chapter_title, book_node]);

  const triggerCloseLastPage = () => {
    closeLastPage();
    mixpanel.track("CloseEOC", {
      node_id: nodeID,
      current_chapter: current_chapter_title,
      current_book: book_node,
    });
  };

  const getBodyCopy = () => {
    switch (promo) {
      case "happy-plant-social" || "happy-plant-email":
        return "Read the rest of Horti's plant care guide.";
      case "linkedin":
        return "Special discounted rate for LinkedIn employees.";
      default:
        return "";
    }
  }

  const getRedirectLink = () => {
    const WEB_URL = "https://imprintapp.com/";
    // const WEB_URL = "http://localhost:8000";
    switch (promo) {
      case "happy-plant-social" || "happy-plant-email":
        return `${WEB_URL}/subscription/?cid=6z5Cdlh8&promo=${promo}`;
      case "linkedin":
        return `${WEB_URL}/subscription/?cid=6z5Cdlh8&trial=true&promo=${promo}`;
      default:
        return "#";
    }
  }

  return (
    <BackgroundDiv>
      <LastPageContainer>
        <CloseContainer onClick={triggerCloseLastPage}>
          <IoIosClose size={50} color="#c8c8c8" />
        </CloseContainer>
        <TextDiv>
          <LastPageTitle>Join Imprint, the #1 visual learning platform.</LastPageTitle>
          <LastPageText>{getBodyCopy()}</LastPageText>
          <LastPageText>Explore Imprint's library of interactive courses.</LastPageText>
          <LastPageText>Psychology, philosophy, management, science, and more.</LastPageText>
        </TextDiv>
        <GetStartedButton href={getRedirectLink()}>Get started</GetStartedButton>
      </LastPageContainer>
    </BackgroundDiv>
  )
};

// interface Disabled {
//   disabled: boolean;
//   showLastPage: boolean;
// }

const BackgroundDiv = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 60;
`;

const LastPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;

  width: 400px;
  max-width: 400px;
  max-height: 800px;
  height: 85%;
  z-index: 60;
  border-radius: 12px;
  padding: 24px;
  padding-bottom: 56px;
  text-align: center;
  font-family: GothamSSm;

  -webkit-box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.65),
    rgba(0, 0, 0, 0.3) 0 1px 3px;
  background-color: white;
  /* Disables text highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media ${device.mobileSize} {
    width: 90vw;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  
  :hover {
    cursor: pointer;
  }
`;

const LastPageTitle = styled.h1`
  margin-bottom: 40px;
  font-size: 20px;
  background: ${PRIMARY_GRADIENT};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TextDiv = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 18px;
  @media ${device.mobileSize} {
    padding: 6px;
  }
`;

const LastPageText = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  color: #605B66;
`;

const GetStartedButton = styled.a`
  padding: 16px;
  border-radius: 32px;
  background: ${PRIMARY_GRADIENT};
  color: white;
  text-decoration: none;
  font-weight: 400;
`;

export default LastPagePromo;