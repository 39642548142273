import {
  // FetchNodeChildren,
  // StartFetchingNodeChildren,
  NodeActionType,
} from "../types";

import { CardNode, ChapterNode, WebUser } from "../firebase/responseTypes";

const nodeReducerDefaultState = {
  children: [] as CardNode[],
  branch_link: "",
  is_fetching_node_children: false,
  currentIndex: 0,
  disabled: false,
  setChapterIndex: false,
  actionCount: 0,
  pitchDismissed: false,
  current_chapter: {} as ChapterNode,
  book: [] as ChapterNode[],
  book_node: {} as ChapterNode,
  current_chapter_index: 0,
  showPitch: false,
  book_cover: "",
  web_user: {
    country_code: "",
  } as WebUser,
  disableCardNavigation: false,
  direction: "right",
  multiLineClickCount: 0,
  multiLineLimit: 0,
  multiLineLineCount: 0,
  offSetXPosition: 0,
};

const nodeReducer = (
  state = nodeReducerDefaultState,
  action: NodeActionType
) => {
  switch (action.type) {
    case "START_FETCHING_NODE_CHILDREN":
      return {
        ...state,
        is_fetching_node_children: true,
      };
    case "FETCH_NODE_CHILDREN":
      return {
        ...nodeReducerDefaultState,
        children: action.childNodes.filter((node) => {
          if (node.isShareCard) {
            return false;
          }
          if (node.is_invite_card) {
            return false;
          }
          return true;
        }),
        is_fetching_node_children: false,
      };
    case "INCREASE_INDEX":
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      };
    case "DECREASE_INDEX":
      return {
        ...state,
        currentIndex: state.currentIndex - 1,
      };
    case "SET_INDEX":
      return {
        ...state,
        currentIndex: action.index,
        setChapterIndex: true,
      };

    case "RESET_INDEX":
      return {
        ...state,
        currentIndex: 0,
      };
    case "DISABLED_WEB":
      return {
        ...state,
        disabled: action.disabled,
      };
    case "SET_BRANCH_LINK":
      return {
        ...state,
        branch_link: action.branch_link,
      };
    case "SET_ACTION_COUNT":
      return {
        ...state,
        actionCount: state.actionCount + 1,
      };
    case "SHOW_PITCH":
      return {
        ...state,
        showPitch: true,
      };
    case "DISMISS_PITCH":
      return {
        ...state,
        showPitch: false,
      };
    case "SET_BOOK":
      return {
        ...state,
        book: action.book,
      };
    case "SET_BOOK_NODE":
      return {
        ...state,
        book_node: action.book_node,
      };
    case "SET_CHAPTER":
      return {
        ...state,
        current_chapter: action.chapter,
        current_chapter_index: action.chapter.order,
      };
    case "SET_BOOK_COVER":
      return {
        ...state,
        book_cover: action.book_cover,
      };
    case "SET_WEB_USER":
      return {
        ...state,
        web_user: action.user,
      };
    case "DISABLE_CARD_NAVIGATION":
      return {
        ...state,
        disableCardNavigation: true,
      };
    case "ENABLE_CARD_NAVIGATION":
      return {
        ...state,
        disableCardNavigation: false,
      };
    case "SET_DIRECTION":
      return {
        ...state,
        direction: action.direction,
      };
    case "SET_COUNT_LIMIT":
      return {
        ...state,
        multiLineLimit: action.count,
      };
    case "INCREASE_MULTI_CARD_COUNT":
      return {
        ...state,
        multiLineClickCount: state.multiLineClickCount + 1,
      };
    case "SET_MULTI_CARD_COUNT":
      return {
        ...state,
        multiLineClickCount: action.count,
      };
    case "DECREASE_MULTI_CARD_COUNT":
      if (state.multiLineClickCount > 0) {
        return {
          ...state,
          multiLineClickCount: state.multiLineClickCount - 1,
        };
      } else {
        return {
          ...state,
        };
      }
    case "SET_MULTI_CARD_LINE_COUNT":
      return {
        ...state,
        multiLineLineCount: action.lines,
      };
    case "SET_OFFSET_X_POSITION":
      return {
        ...state,
        offSetXPosition: action.offsetX,
      };
    default:
      return nodeReducerDefaultState;
  }
};

export default nodeReducer;
