import { createGlobalStyle } from "styled-components";

import GothamSSmBold from "./GothamSSm-Bold.otf";
import GothamSSmBook from "./GothamSSm-Book.otf";
import SentinelSSmBook from "./SentinelSSm-Book.otf";
import SentinelSSmBold from "./SentinelSSm-Bold.otf";

export default createGlobalStyle`
  @font-face {
    font-family: 'GothamSSm';
    src: local('GothamSSm'), url(${GothamSSmBold});
    font-weight: bold;
  }
  @font-face {
    font-family: 'GothamSSm';
    src: local('SentinelSSm-Book'), url(${GothamSSmBook});
  }
  @font-face {
    font-family: 'SentinelSSm-Book';
    src: local('SentinelSSm-Book'), url(${SentinelSSmBook});
  }
  @font-face {
    font-family: 'SentinelSSm-Book';
    src: local('SentinelSSm-Book'), url(${SentinelSSmBold});
    font-weight: bold;
  }
`;
