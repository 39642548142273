import * as React from "react";
import { isIOS, osName } from "react-device-detect";
import { RouteComponentProps, Redirect } from "react-router";

interface RedirectProps {}

interface RedirectState {
  redirect: boolean;
  loading: boolean;
}

interface RouterProps {
  nodeID: string;
}

type Props = RedirectProps & RouteComponentProps<RouterProps>;

class RedirectClass extends React.Component<Props, RedirectState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: false,
      loading: true,
    };
  }
  componentDidMount() {
    //redirect logic
    if (osName === "Mac OS" || isIOS) {
      this.setState({
        redirect: true,
        loading: false,
      });
      mixpanel.track("IOS redirected");
    } else {
      this.setState({
        loading: false,
      });
      mixpanel.track("Non-IOS redirected");
    }
  }

  render() {
    const redirectURL = `/chapter/${this.props.match.params.nodeID}`;
    const { redirect, loading } = this.state;

    if (loading)
      return (
        <div>
          {/* <p>IS IOS?={isIOS ? "true" : "false"}</p>
        <p>OS NAME= {osName}</p> */}
        </div>
      );
    if (redirect) return <Redirect to="/appstore" />;
    if (!redirect) return <Redirect to={redirectURL} />;
  }
}

export default RedirectClass;
