/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import styled from "styled-components";
import anime from "animejs";
import { device } from "../../utils/variables";
import { detect } from "detect-browser";
import { GreenLine } from "../UI";
import { Highlight } from "../../firebase/responseTypes";
import DOMPurify from "dompurify";
const browser = detect();

interface TextCardProps {
  title: string;
  nextPageAnimation: boolean;
  selectedCard: boolean;
  // animate_text: boolean;
  lastPage: boolean;
  highlights: { [name: string]: Highlight } | undefined;
}

interface TextCardState {
  animated: boolean;
}

class TextCard extends React.Component<TextCardProps, TextCardState> {
  textTransition = React.createRef() as any;

  constructor(props: TextCardProps) {
    super(props);
    this.state = {
      animated: false,
    };
  }

  // componentDidMount() {
  //   this.props.animate_text && this.handleAnimation();
  // }
  componentDidUpdate = (prevProps: any, prevState: any) => {
    //===== Below does not do anything when ====//
    if (this.props.selectedCard && !this.state.animated) {
      this.setState({
        animated: true,
      });
      // this.props.animate_text && this.handleAnimation();
    }
    if (
      prevProps.selectedCard !== this.props.selectedCard &&
      this.props.selectedCard
    ) {
      this.setState({
        animated: true,
      });
      // this.props.animate_text && this.handleAnimation();
    }
    const { highlights } = this.props;

    if (
      prevProps.selectedCard !== this.props.selectedCard &&
      !this.props.selectedCard
    ) {
      if (!!highlights) {
        for (const value in highlights) {
          const highLightId = highlights[value].id;
          let highlightDom = document.getElementsByClassName(highLightId);
          console.log("highlightDom", highlightDom);
          if (highlightDom[0].classList.contains("highlight")) {
            highlightDom[0].classList.remove("highlight");
          }
        }
      }
    }
  };

  handleAnimation = () => {
    anime({
      targets: this.textTransition.current,
      duration: 150,
      translateX: this.props.nextPageAnimation ? [0, 15] : [0, -15],
      direction: "reverse",
      easing: "linear",
    });
  };

  render() {
    const {
      title,
      nextPageAnimation,
      lastPage,
      highlights,
      selectedCard,
    } = this.props;

    if (selectedCard) {
      if (!!highlights) {
        setTimeout(() => {
          for (const value in highlights) {
            const highLightId = highlights[value].id;
            let highlightDom = document.getElementsByClassName(highLightId);
            console.log("highlightDom", highlightDom);
            if (!highlightDom[0].classList.contains("highlight")) {
              // highlightDom[0].classList.remove("highlight");
              highlightDom[0].className += " highlight";
            }
            // highlightDom;
          }
        }, 100);
      }
    }
    return (
      <TextContainer>
        <FirstLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
          />
        </FirstLineAnimationDiv>
        {/* <Box ref={this.textTransition} /> */}
        <TextWrapper ref={this.textTransition}>
          {title && (
            <TextDiv
              lastPage={lastPage}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(title),
              }}
            />
          )}
        </TextWrapper>
        <SecondLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
          />
        </SecondLineAnimationDiv>
      </TextContainer>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

export const Box = styled.div`
  height: 50px;
  width: 50px;
  background-color: red;
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: -1;
  position: relative;
  /* height: 40%; */
  width: 100%;
  height: 100%;
  align-items: center;
  /* background-color: lightcoral; */
  /* @media ${device.laptop} {
    margin-top: 45vh;
  }
  @media ${device.minDesktop} {
    margin-top: 45vh;
  } */
`;

interface TextDivProps {
  lastPage: boolean;
}

export const TextDiv = styled.div<TextDivProps>`
  z-index: -1;
  height: 100%;
  font-family: SentinelSSm-Book;
  /* Disables text highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

export const TextWrapper = styled.div`
  z-index: -1;
  position: absolute;
  width: 90%;
  padding: 0px 20px;
`;

export const FirstLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  top: 2.5vh;
  position: absolute;
`;

export const SecondLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  /* margin-top: 375px; */
  position: absolute;
  bottom: 2.5vh;
  /* @media ${device.ultraWidemin} {
    margin-top: 30vh;
  }
  @media ${device.ultraWidemin +
  "and (min-height: 1500px)"} {
    margin-top: 18vh;
  } */
`;

export default TextCard;
