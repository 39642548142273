import firebase from "firebase";

const config = {
  apiKey: "AIzaSyDLJqtNsgdDnWJHbQrFUAYHt9q8COFjj5I",
  authDomain: "know-d8bc0.firebaseapp.com",
  databaseURL: "https://know-d8bc0.firebaseio.com",
  projectId: "know-d8bc0",
  storageBucket: "know-d8bc0.appspot.com",
  messagingSenderId: "196279047175",
};

firebase.initializeApp(config);

export default firebase;

export const databaseRef = firebase.database().ref();

export const storageRef = firebase.storage().ref();
