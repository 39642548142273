import React, { ReactNode, FC } from "react";
import styled from "styled-components";
import { AppText } from ".";

// * I have to have this component for Formik's ErrorField component

interface Props {
  children?: ReactNode;
  color?: string;
}

export const ErrorText: FC<Props> = ({ children, color = "red" }) => (
  <AppTextError style={{ color }}>{children}</AppTextError>
);

const AppTextError = styled(AppText)`
  font-size: 12;
  font-family: GothamSSm;
`;
