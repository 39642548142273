/* eslint-disable @typescript-eslint/no-unused-vars */
import anime from "animejs";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { SubscriptionDots } from "../../assets";
import { Link } from "react-router-dom";
import { PRIVACY_LINK, TERMS_LINK } from "../../utils/variables";

interface Props {
  handleClick: (e: any, page: string, subscriptionType: string) => void;
}

export const SubscriptionDetail = ({ handleClick }: Props) => {
  return (
    <Container>
      <HeaderContainer>
        <b>
          SPECIAL OFFER
          <br /> FOR EARLY ADOPTERS
        </b>
      </HeaderContainer>
      <BodyContainer>
        <BodyContext>
          <BodySpan>Unlock</BodySpan> the full Imprint library. <br />
          <BodySpan>Discover</BodySpan> new titles, added <br />
          frequently. <br />
          <BodySpan>Build</BodySpan> a learning habit
        </BodyContext>
        <SubscriptionOptionsContainer>
          <ButtonContainer
            subType="yearly"
            onClick={(e) => handleClick(e, "Login", "yearly")}
          >
            <MostPopular> Most Popular </MostPopular>
            <ButtonText>
              <b>Start Your Free Trial</b>
            </ButtonText>
            <ButtonText>$99.99</ButtonText>
            <ButtonText>Billed annually after 1 week</ButtonText>
          </ButtonContainer>
          <ButtonContainer
            subType="monthly"
            onClick={(e) => handleClick(e, "Login", "monthly")}
          >
            <ButtonText>
              <b>Start Your Free Trial</b>
            </ButtonText>
            <ButtonText>$15.99</ButtonText>
            <ButtonText>Billed monthly</ButtonText>
          </ButtonContainer>
        </SubscriptionOptionsContainer>
        <TermsConditionContainer>
          <p>Recurring billing. Cancel anytime.</p>
          <div>
            <a href={TERMS_LINK}> Terms & Conditions </a>
            {"  "}•{"  "}
            <a href={PRIVACY_LINK}>Privacy Policy </a>
          </div>
        </TermsConditionContainer>
      </BodyContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 51px;
  height: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 56px;
  left: 78px;
  top: 88.35px;
  font-family: GothamSSm;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #2d7dd2;
  margin: auto;
`;

const BodyContainer = styled.div`
  width: 100%;
  height: 252px;
  margin: auto;
  margin-top: 31px;
`;

const BodyFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
`;

const BodyContext = styled.div`
  height: 96px;
  position: relative;
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #292032;
  text-align: center;
`;

const BodySpan = styled.span`
  color: #2d7dd2;
`;
const PlanContainer = styled.div`
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 182px;
`;

const TrialDetail = styled.p`
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  display: block;
  text-align: center;
  color: #2d7dd2;
  margin: 2px auto;
`;

const SubscriptionOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  justify-content: center;
`;

type ButtonType = {
  subType: string;
};

const ButtonContainer = styled.div<ButtonType>`
  width: 90%;
  height: 88px;
  position: relative;
  margin: auto;
  background: ${(props) =>
    props.subType === "yearly"
      ? "linear-gradient(90deg,rgba(115, 123, 253, 0.75) 1.68%,rgba(50, 123, 210, 0.75) 116.81%),#1e85e2;"
      : "#716B78"};
  border-radius: 12px;
  cursor: pointer;
  font-family: GothamSSm;
  margin-bottom: 8px;
  padding: 10px;
  :hover {
    filter: brightness(130%);
  }
`;

const MostPopular = styled.div`
  position: absolute;
  width: 88px;
  height: 22px;
  font-family: GothamSSm;
  font-size: 10px;
  line-height: 22px;
  color: #fefefe;
  background: #4350a4;
  border-radius: 8px;
  right: 8px;
  top: 5px;
  text-align: center;
`;

const ButtonText = styled.p`
  font-family: GothamSSm;
  font-size: 14px;
  line-height: 24px;
  color: #fefefe;
  height: 24px;
  margin: 0px;
  margin-left: 5px;
`;

const TermsConditionContainer = styled.div`
  margin: auto;
  width: 100%;
  position: absolute;
  bottom: -35px;
  text-align: center;
  font-family: GothamSSm;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #1e85e2;
  p {
    margin: 0;
    color: #787880;
  }
  div {
    margin-top: 5px;
  }
`;
