/* eslint-disable @typescript-eslint/no-unused-vars */
import anime from "animejs";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Download_White_Svg, Wordmark } from "../../assets";
import { device } from "../../utils/variables";

interface Props {}

export const SubscriptionComplete = (props: Props) => {
  const textTransition = React.createRef() as any;

  console.log("hit here on email notification ");
  useEffect(() => {
    handleAnimation();
  });

  const handleAnimation = () => {
    anime({
      targets: textTransition.current,
      duration: 500,
      translateX: [0, 15],
      opacity: [0.2],
      direction: "reverse",
      easing: "linear",
    });
  };

  return (
    <AppContainer ref={textTransition}>
      <TextContainer>
        <Logo src={Wordmark} alt="image" />
        <Header>
          Great! <br />
          Thanks for subscribing.{" "}
        </Header>
        <Body>Download the Imprint app and login to get started!</Body>
        <Body>
          With your premium subscription, you now have full access to our
          library.{" "}
        </Body>
        <ImageContainer href="https://apps.apple.com/app/apple-store/id1482780647">
          <img src={Download_White_Svg} alt="download_image" />
        </ImageContainer>
      </TextContainer>
    </AppContainer>
  );
};

const AppContainer = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: #eef2f6;
`;

const TextContainer = styled.div`
  margin: 0 24px;
  margin-top: 5%;
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const Logo = styled.img`
  display: flex;
  margin: auto;
  margin-bottom: 100px;
  @media ${device.mobileSize} {
    display: none;
  }
`;

const Header = styled.h3`
  font-family: GothamSSm;
  font-size: 24px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  color: #2d7dd2;
`;

const Body = styled.p`
  font-family: GothamSSm;
  font-size: 15px;
  line-height: 24px;
  color: #292032;
  text-align: center; ;
`;

const ImageContainer = styled.a`
  display: flex;
  margin: auto;
  margin-top: 30px;
  img {
    width: 200px;
    margin: 0 auto;
  }
`;
