/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import styled, { keyframes } from "styled-components";
import anime from "animejs";
import { device } from "../../utils/variables";
import { detect } from "detect-browser";
import { GreenLine } from "../UI";
import { MultiLineCardsType } from "../../firebase/responseTypes";
import { Dispatch, bindActionCreators } from "redux";
import { AppState } from "../../store/configureStore";
import { connect } from "react-redux";
import { AppActions } from "../../types";
import {
  setCountLimit,
  increaseMultiCardCount,
  decreaseMultiCardCount,
} from "../../actions";
import DOMPurify from "dompurify";
const browser = detect();

interface MultiLineCardProps {
  title: string;
  nextPageAnimation: boolean;
  selectedCard: boolean;
  // animate_text: boolean;
  lastPage: boolean;
  lines: MultiLineCardsType[];
  enableCardNavigation: () => void;
  backClick: () => void;
  nextClick: () => void;
  directionFlow: string;
  setDirection: (direction: string) => void;
  showAllLines: boolean;
}

interface MultiLineCardState {
  limit: number;
  clickCount: number;
}

type Props = MultiLineCardProps & LinkDispatchProps & LinkStateProps;

class MultiLineCard extends React.Component<Props, MultiLineCardState> {
  textTransition = React.createRef() as any;

  constructor(props: Props) {
    super(props);
    this.state = {
      limit: this.props.lines.length,
      clickCount:
        this.props.directionFlow === "left" ? this.props.lines.length : 0,
    };
  }

  componentDidMount() {
    const { setCountLimit } = this.props;
    setCountLimit(this.props.lines.length);
  }
  componentDidUpdate = (
    prevProps: MultiLineCardProps,
    prevState: MultiLineCardState
  ) => {};

  // handleAnimation = () => {
  //   anime({
  //     targets: this.textTransition.current,
  //     duration: 150,
  //     translateX: this.props.nextPageAnimation ? [0, 15] : [0, -15],
  //     direction: "reverse",
  //     easing: "linear",
  //   });
  // };

  increaseClickCount = () => {
    if (this.props.limit > this.props.clickCount) {
      this.props.increaseCount();
    }
  };

  decreaseClickCount = () => {
    if (this.props.clickCount > 0) {
      this.props.decreaseCount();
    }
  };

  clickNextHandler = () => {
    this.props.setDirection("right");
    // this.increaseClickCount();
    if (this.props.clickCount >= this.props.limit) {
      this.props.nextClick();
      this.props.enableCardNavigation();
    }
  };

  clickBackHandler = () => {
    this.props.setDirection("left");

    // this.decreaseClickCount();
    if (this.props.clickCount === 1) {
      this.props.backClick();
      this.props.enableCardNavigation();
    }
  };

  render() {
    const {
      title,
      nextPageAnimation,
      lastPage,
      lines,
      directionFlow,
      clickCount,
      limit,
    } = this.props;
    // console.log("multieLine state", this.state);
    console.log("multieLine props", this.props);

    return (
      <TextContainer>
        <HiddenContainer>
          <HiddenBackContainer onClick={this.clickBackHandler} />
          <HiddenNextContainer onClick={this.clickNextHandler} />
        </HiddenContainer>
        <FirstLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
            disabled={clickCount !== 0 || clickCount < limit}
          />
        </FirstLineAnimationDiv>
        <TextWrapper ref={this.textTransition}>
          {lines.length > 0 && (
            <MultiLineTextContainer>
              {lines.map((line, index) => {
                return (
                  <MultiLineText
                    directionFlow={directionFlow}
                    visible={
                      index === 0 || this.props.showAllLines
                        ? true
                        : directionFlow === "right"
                          ? clickCount + 1 > index
                          : clickCount >= index
                    }
                    index={index}
                    count={clickCount}
                    overrideVisible={this.props.showAllLines}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(line.line_text),
                    }}
                  >
                  </MultiLineText>
                );
              })}
            </MultiLineTextContainer>
          )}
        </TextWrapper>
        <SecondLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
            disabled={clickCount !== 0 || clickCount < limit}
          />
        </SecondLineAnimationDiv>
      </TextContainer>
    );
  }
}

const HiddenContainer = styled.div`
  z-index: 55;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media ${device.minDesktop} {
    left: 50%;
    width: 400px;
    margin-left: -200px;
  }
  @media ${device.ipadLandScape} {
    width: 360px;
  }
`;
const HiddenNextContainer = styled.div`
  z-index: 55;
  height: 100%;
  width: 63%;
  /* background-color: lightgreen;
  opacity: 0.5; */
  @media ${device.mobileL} {
    width: 63%;
  }
  @media ${device.minDesktop} {
    width: 66%;
  }
`;
const HiddenBackContainer = styled.div`
  z-index: 55;
  height: 100%;
  width: 37%;
  background: transparent;
  /* background-color: lightpink;
  opacity: 0.5; */
  @media ${device.mobileL} {
  }
  @media ${device.minDesktop} {
    width: 34%;
  }
`;

//= ===================
//= STYLED COMPONENTS
//= ===================

export const Box = styled.div`
  height: 50px;
  width: 50px;
  background-color: red;
`;
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: -1;
  position: relative;
  /* height: 40%; */
  width: 100%;
  height: 100%;
  align-items: center;
  /* background-color: lightcoral; */
  /* @media ${device.laptop} {
    margin-top: 45vh;
  }
  @media ${device.minDesktop} {
    margin-top: 45vh;
  } */
`;

interface TextDivProps {
  lastPage: boolean;
}

export const TextDiv = styled.div<TextDivProps>`
  z-index: -1;
  height: 100%;
  font-family: SentinelSSm-Book;
  /* Disables text highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
`;

export const TextWrapper = styled.div`
  z-index: -1;
  position: absolute;
  width: 90%;
  padding: 0px 20px;
`;

export const FirstLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  top: 2.5vh;
  position: absolute;
`;

export const SecondLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  /* margin-top: 375px; */
  position: absolute;
  bottom: 2.5vh;
  /* @media ${device.ultraWidemin} {
    margin-top: 30vh;
  }
  @media ${device.ultraWidemin +
  "and (min-height: 1500px)"} {
    margin-top: 18vh;
  } */
`;

interface FadeInOrOut {
  directionFlow: string;
  visible: boolean;
  index: number;
  count: number;
  overrideVisible: boolean;
}

const fadeIn = keyframes`
  0% {
    /* transform: translateX(130px); */
    opacity: 0
  }
  100% {
    /* transform: translateX(0); */
    opacity: 1
  }
`;

const fadeOut = keyframes`
  0% {
    /* transform: translateX(0); */
    opacity: 1;
  }
  100% {
    /* transform: translateX(130px); */
    opacity: 0;
  }
`;

const MultiLineTextContainer = styled.div`
  font-size: 19px;
  text-align: left;
  position: absolute;
  top: 50%;
  left: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: SentinelSSm-Book;
`;

const MultiLineText = styled.p<FadeInOrOut>`
  opacity: 0;
  font-size: 19px;
  width: 100%;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  animation-duration: 0.3s;
  animation-name: ${(props) =>
    props.visible
      ? props.directionFlow === "right"
        ? fadeIn
        : props.index === props.count+1
          ? fadeOut
          : fadeIn
    : "none"};
  animation-fill-mode: forwards;
`;

interface LinkDispatchProps {
  setCountLimit: (count: number) => void;
  increaseCount: () => void;
  decreaseCount: () => void;
}

interface LinkStateProps {
  clickCount: number;
  limit: number;
}

const mapDispatchToProps = (
  dispatch: Dispatch<AppActions>
): LinkDispatchProps => ({
  setCountLimit: bindActionCreators(setCountLimit, dispatch),
  increaseCount: bindActionCreators(increaseMultiCardCount, dispatch),
  decreaseCount: bindActionCreators(decreaseMultiCardCount, dispatch),
});

const mapStateToProps = (state: AppState): LinkStateProps => ({
  clickCount: state.nodeReducer.multiLineClickCount,
  limit: state.nodeReducer.multiLineLimit,
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiLineCard);
