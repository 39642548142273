import * as React from "react";
import styled from "styled-components";
import { device } from "../../utils/variables";
import { AppText } from "../UI";
import RLoading from "react-spinkit";

class LoadingSegment extends React.Component {
  render() {
    return (
      <LoadingSegmentContainer>
        <LoadingImgContainer>
          <RLoading
            name="line-spin-fade-loader"
            color={"#26639B"}
            fadeIn="none"
          />
        </LoadingImgContainer>
        <TextDiv>
          <AppText style={{ fontFamily: "GothamSSm" }} fontSize={"30px"}>
            Loading...
          </AppText>
        </TextDiv>
      </LoadingSegmentContainer>
    );
  }
}

//= ===================
//= STYLED COMPONENTS
//= ===================

const LoadingSegmentContainer = styled.div`
  z-index: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  height: 80vh;
  width: 90vw;
  border-radius: 10px;
  max-width: 400px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  @media ${device.mobileS} {
    max-width: 100vh;
    height: 75vh;
  }
  @media ${device.mobileM} {
    max-width: 100vh;
    height: 75vh;
  }
  @media ${device.mobileL} {
    max-width: 100vh;
    height: 75vh !important;
    top: 5% !important;
    position: absolute;
  }
  @media ${device.laptop} {
    max-width: 100vh;
    height: 85vh;
    /* margin-top: 5%; */
    /* margin-bottom: 10%; */
  }

  @media ${device.ipadProPortrait} {
    top: 12% !important;
  }
  @media ${device.ipadProLandscape} {
    height: 65% !important;
    top: 14% !important;
  }
  @media ${device.ipadPortrait} {
    height: 70% !important;
    top: 12% !important;
  }

  @media ${device.ipadLandScape} {
    width: 360px;
    height: 640px !important;
  }
  @media ${device.smallDesktop} {
    max-width: 380px !important;
  }
  @media ${device.laptopL} {
    max-width: 400px;
    max-height: 800px;
    height: 85%;
    position: absolute;
    top: 3%;
    /* margin-top: 5%; */
    /* margin-bottom: 10%; */
  }

  @media ${device.minDesktop} {
    max-width: 400px;
    max-height: 800px;
    height: 85%;
    position: absolute;
    top: 3%;
  }
  @media ${device.ultraWidemin} {
    height: 70%;
    max-height: 730px;
  }
`;

const LoadingImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 30%;
  margin-top: 10%;
`;

const TextDiv = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
`;

export default LoadingSegment;
