import {
  Meditation,
  Macroeconomics,
  Existentialism,
  Negotiation,
  Nutrition,
  Productivity,
  OnboardingName,
  OnboardingComplete,
  Quote1,
  Quote2,
  Quote3,
  Quote4,
  History,
  Philosophy,
  Business,
  Leadership,
  Psychology,
  Politics,
  ProductivityIcon,
  Art,
  Design,
  Economics,
} from ".";

export const questionData = [
  {
    question: "What is your name?",
    answer_type: "text",
    require_input: true,
    answers: "Name",
  },
  {
    question: "Hi, ",
    answer_type: "lotti",
    require_input: false,
    answers: [
      OnboardingName,
      "To get started, answer a few quick questions to help us personalize your experience.",
    ],
  },
  {
    question: "What is your age?",
    answer_type: "select",
    require_input: true,
    answers: ["15-24", "25-34", "35-44", "45-54", "55+", "Prefer not to say"],
  },
  {
    question: "What are your favorite ways to learn?",
    answer_type: "multiselect_two_row",
    require_input: true,
    answers: [
      "Books",
      "Audiobooks",
      "Podcasts",
      "Videos",
      "Courses",
      "Articles",
    ],
  },
  {
    question: "Which of these topics interest you?",
    answer_type: "multiselect",
    require_input: true,
    answers: ["History", "Philosophy", "Business", "Leadership", "Psychology"],
    icons: [History, Philosophy, Business, Leadership, Psychology],
  },
  {
    question: "Which of these topics interest you?",
    answer_type: "multiselect",
    require_input: true,
    answers: ["Politics", "Productivity", "Art", "Design", "Economics"],
    icons: [Politics, ProductivityIcon, Art, Design, Economics],
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote1],
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote2],
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote3],
  },
  {
    question: "Do you agree with this statement?",
    answer_type: "boolean",
    require_input: true,
    answers: [Quote4],
  },
  {
    question: "Which do you want to learn about more?",
    answer_type: "image_select",
    require_input: true,
    answers: ["Meditation", "Macroeconomics"],
    icons: [Meditation, Macroeconomics],
  },
  {
    question: "Which do you want to learn about more?",
    answer_type: "image_select",
    require_input: true,
    answers: ["Existentialism", "Negotiation"],
    icons: [Existentialism, Negotiation],
  },
  {
    question: "Which do you want to learn about more?",
    answer_type: "image_select",
    require_input: true,
    answers: ["Nutrition", "Productivity"],
    icons: [Nutrition, Productivity],
  },
  {
    question: "",
    answer_type: "lotti",
    require_input: false,
    answers: [OnboardingComplete],
  },
];
