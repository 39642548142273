import * as React from "react";
import styled from "styled-components";
import anime from "animejs";
import { device } from "../../utils/variables";
import { detect } from "detect-browser";
import { iOSSafari } from "../../utils/variables";
import { GreenLine } from "../UI";
import { LottieVideo } from "../Segments";
import { Highlight } from "../../firebase/responseTypes";
import DOMPurify from "dompurify";

const browser = detect();

interface TextVideoCardProps {
  title: string;
  mediaURL: string;
  text_y: number;
  image_y: number;
  nextPageAnimation: boolean;
  selectedCard: boolean;
  // animate_text: boolean;
  animate_image: boolean;
  lottieJSON: string;
  gif_loops: number;
  lastPage: boolean;
  highlights: { [name: string]: Highlight } | undefined;
}

//gif loops
// 0 === play once
// 1 === loop
// 2 === Static
// 3 === show end

interface TextVideoCardState {
  load: boolean;
}

class TextVideoCard extends React.Component<
  TextVideoCardProps,
  TextVideoCardState
> {
  textTransition = React.createRef() as any;
  videoRef = React.createRef() as any;
  constructor(props: TextVideoCardProps) {
    super(props);
    this.state = {
      load: false,
    };
  }

  componentDidMount() {
    const browserName = browser ? browser.name : "";

    // this.props.animate_text && this.handleAnimation();
    // if (browserName === "safari") {
    //   console.log("SAFARI LOG");
    //   !this.props.animate_image &&
    //     this.videoRef.current &&
    //     this.videoRef.current.addEventListener("canplay", () => {
    //       console.log("SAFARI event LISTENER", this.videoRef.current.duration);
    //       this.videoRef.current.currentTime =
    //         this.videoRef.current.duration - 0.1;
    //     });
    // }
    if (browserName === "crios") {
      !this.props.animate_image &&
        this.videoRef.current &&
        this.videoRef.current.addEventListener("loadeddata", () => {
          this.videoRef.current.currentTime =
            this.videoRef.current.duration - 0.1;
        });
    }
  }

  componentDidUpdate = (prevProps: TextVideoCardProps, prevState: any) => {
    const browserName = browser ? browser.name : "";
    const { highlights } = this.props;

    if (
      prevProps.selectedCard !== this.props.selectedCard &&
      !this.props.selectedCard
    ) {
      if (!!highlights) {
        for (const value in highlights) {
          const highLightId = highlights[value].id;
          let highlightDom = document.getElementsByClassName(highLightId);
          console.log("highlightDom", highlightDom);
          if (highlightDom[0].classList.contains("highlight")) {
            highlightDom[0].classList.remove("highlight");
          }
        }
      }
    }
    if (
      prevProps.selectedCard !== this.props.selectedCard &&
      this.props.selectedCard
    ) {
      // console.log("DOES CARD IMAGE ANIMATE?", this.props.animate_image);
      // console.log("browserName?", browserName);
      // console.log("iOSSafari", iOSSafari);
      // this.props.animate_text && this.handleAnimation();
      if (
        this.videoRef.current &&
        !this.props.animate_image &&
        !isNaN(this.videoRef.current.duration)
      ) {
        // console.log("ANIMATE VIDEO");
        if (!iOSSafari && browserName !== "safari") {
          const videoDuration = this.videoRef.current.duration - 0.1;
          this.videoRef.current.currentTime = videoDuration;
        }
      }
      if (
        this.props.mediaURL &&
        this.props.gif_loops === 0 &&
        this.props.mediaURL.indexOf("json") === -1
      ) {
        console.log("animate mp4");
        this.videoRef.current.load();
        this.videoRef.current.play();
      }

      // if (
      //   this.props.mediaURL &&
      //   !this.props.animate_image &&
      //   this.videoRef.current && this.videoRef.current.currentTime !== 0
      // ) {
      //   console.log("VALUE --", this.videoRef.current.currentTime);
      //   this.videoRef.current &&
      //     (this.videoRef.current.currentTime = this.videoRef.current.duration);
      // }
    }
  };

  handleAnimation = () => {
    anime({
      targets: this.textTransition.current,
      duration: 150,
      translateX: this.props.nextPageAnimation ? [0, 15] : [0, -15],
      opacity: [1, 0.6],
      direction: "reverse",
      easing: "linear",
    });
  };

  render() {
    const {
      title,
      mediaURL,
      image_y,
      text_y,
      nextPageAnimation,
      selectedCard,
      animate_image,
      lottieJSON,
      gif_loops,
      highlights,
    } = this.props;
    const browserName = browser ? browser.name : "";
    if (selectedCard) {
      if (!!highlights) {
        setTimeout(() => {
          for (const value in highlights) {
            const highLightId = highlights[value].id;
            let highlightDom = document.getElementsByClassName(highLightId);
            console.log("highlightDom", highlightDom);
            if (!highlightDom[0].classList.contains("highlight")) {
              // highlightDom[0].classList.remove("highlight");
              highlightDom[0].className += " highlight";
            }
            // highlightDom;
          }
        }, 100);
      }
    }

    return (
      <TextVideoCardContainer>
        <FirstLineAnimationDiv>
          <GreenLine
            direction={nextPageAnimation}
            selectedCard={this.props.selectedCard}
          />
        </FirstLineAnimationDiv>
        <TextContainer>
          <TextWrapper text_y={text_y} ref={this.textTransition}>
            <TextDiv
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(title),
              }}
            />
          </TextWrapper>
          <LineAnimationDiv>
            <GreenLine
              direction={nextPageAnimation}
              selectedCard={this.props.selectedCard}
            />
          </LineAnimationDiv>
        </TextContainer>
        <VideoContainer>
          {mediaURL.indexOf("json") > -1 ? (
            <div style={{ zIndex: -1 }}></div>
          ) : (
            <VideoDiv
              ref={this.videoRef}
              browser={browserName}
              image_y={image_y}
              muted
              autoPlay={
                false
                // browserName === "safari"
                //   ? // browserName === "crios"
                //     false
                //   : true
              }
              src={mediaURL}
              playsInline
              style={{ zIndex: -1 }}
            />
          )}
          {mediaURL.indexOf("json") > -1 ? (
            <LottieVideo
              mediaURL={mediaURL}
              animate_image={animate_image}
              image_y={image_y}
              lottieJSON={lottieJSON}
              gif_loops={gif_loops}
              selectedCard={selectedCard}
            />
          ) : (
            <VideoDiv
              ref={this.videoRef}
              browser={browserName}
              image_y={image_y}
              muted
              autoPlay={
                false
                // browserName === "safari"
                //   ? // browserName === "crios"
                //     false
                //   : true
              }
              src={mediaURL}
              playsInline
            />
          )}
        </VideoContainer>
      </TextVideoCardContainer>
    );
  }
}

interface VideoDivProps {
  browser: string;
  image_y: number;
}

interface TextProps {
  text_y: number;
}

//= ===================
//= STYLED COMPONENTS
//= ===================

export const TextVideoCardContainer = styled.div`
  z-index: -1;
  width: 100%;
  height: 95vh;
  @media ${device.mobileL} {
    height: 90vh;
  }
  @media ${device.ultraWidemin} {
    height: 65vh;
    max-height: 730px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  /* height: 40%; */
  width: 100%;
  margin-top: 2.5vh;
  height: 24vh;
  max-height: 250px;

  /* background-color: lightcoral; */
  /* @media ${device.laptop} {
    margin-top: 25%;
  }
  @media ${device.minDesktop} {
    margin-top: 25%;
  } */
  span {
    max-width: 100%;
    padding-right: 20px;
    position: relative;
    text-align: inherit;
  }
`;

export const TextDiv = styled.div`
  z-index: 0;
  font-family: SentinelSSm-Book;
  /* Disables text highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const TextWrapper = styled.div<TextProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  width: 90%;
  padding: 0px 20px;
  /* Y TEXT POSITON BELOW, multiply value by two*/
  height: ${(props) => (!props.text_y ? "100%" : props.text_y * 2 + "%")};
  /* @media ${device.laptop} {
    margin-top: 25%;
  }
  @media ${device.minDesktop} {
    margin-top: 25%;
  } */
`;

export const VideoContainer = styled.div`
  z-index: 0;
  display: flex;
  justify-content: center;
  position: relative;
  height: 50%;
  margin: 0 10px;
  margin-top: 3%;
  @media ${device.minDesktop} {
    height: 50%;
  }
  @media ${device.minDesktop + `and (min-height: 1200px)`} {
    height: 43%;
  }
  @media ${device.ipadProPortrait} {
    height: 50% !important;
  }
  /* @media ${device.laptop} {
    margin-top: 35%;
    height: 50%;
  }
  @media ${device.desktopL} {
    margin-top: 35%;
    height: 50%;
  }
  @media ${device.minDesktop} {
    margin-top: 35%;
    height: 50%;
  } */
`;

export const VideoDiv = styled.video<VideoDivProps>`
  z-index: 0;
  backface-visibility: hidden;
  position: absolute;
  /* height: ${(props) =>
    props.browser === "ios" || props.browser === "crios" || iOSSafari
      ? "10%"
      : "auto"} !important; */
  /* BELOW CSS to make video shadow box dissappear on ios devices */
  -webkit-appearance: none;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  /* width: 95% !important; */
  @media ${device.laptop} {
    width: 100%;
    height: 100%;
    margin-top: ${(props) =>
      !props.image_y ? "0" : props.image_y / 2 + -25 + "%"};
  }
  @media ${device.minDesktop} {
    width: 100%;
    height: 100%;
    margin-top: ${(props) =>
      !props.image_y ? "0%" : props.image_y + -50 + "%"};
  } ;
`;

export const FirstLineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 2.5vh;
  position: absolute;
  left: 50%;
  margin-left: -5vw;
`;

export const LineAnimationDiv = styled.div`
  width: 10vw;
  border-bottom: 1px solid #f5f5f5;
  position: absolute;
  bottom: 0;
`;

export default TextVideoCard;
