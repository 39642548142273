/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Formik, FormikProps, ErrorMessage } from "formik";
import firebase from "../../firebase/firebase";
import { AppInput, AppInputProps, ErrorText } from "../UI";
import { history } from "../../routers/AppRouter";
import { useDispatch, useStore } from "react-redux";
import { redirectToStripe, createFBUser } from "../../actions/stripeAction";
import axios from "axios";
import { strictEqual } from "assert";
import { Redirect } from "react-router";
import { SubmitLoading } from "../Segments";

interface Props {
  subscriptionType: string;
}

const userSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email")
    .required("Email must be valid"),
  password: Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("Password is required "),
});

export const UserSignUpForm = ({ subscriptionType }: Props) => {
  const store = useStore();

  const [form, setForm] = useState({
    submit: "Create Account",
    notification: "",
    initiallySubmiited: false,
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (
    e: any,
    formik: FormikProps<{
      email: string;
      password: string;
      isIOSUser: boolean;
    }>
  ) => {
    e.preventDefault();
    const ready = readyForSubmit(formik);
    if (ready) {
      const { email, password, isIOSUser } = formik.values;
      if (isIOSUser) {
        const onboardingData = store.getState().onboardingReducer;
        const formattedOnboardingData = onboardingData.reduce(
          (acc: any, el: { [key: string]: string }) => {
            for (const [key, value] of Object.entries(el)) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );
        const response = await createFBUser(email, password);
        if (response.error as any) {
          formik.setErrors({ email: response.error });
        } else {
          setLoading(true);
          const url = await redirectToStripe({
            app_user_id: response.app_user_id,
            email: email,
            subscriptionType: subscriptionType,
          });
          window.location.href = url;
          setLoading(false);
        }
      } else {
        if (form.initiallySubmiited && form.submit !== "Create Account") {
          history.push("/onboarding/emailnotification");
        }
        setForm({
          submit: "Sign Up for Notifications",
          notification:
            "Imprint is only available on iOS devices at this time. Click here to sign up to be notified about Android and other platforms.",
          initiallySubmiited: true,
        });
      }
    }
  };

  const handleIOSButtonChange = (
    formik: FormikProps<{
      email: string;
      password: string;
      isIOSUser: boolean;
    }>
  ) => {
    const value = !formik.values.isIOSUser;
    formik.setFieldValue("isIOSUser", value);
    if (form.initiallySubmiited) {
      if (value) {
        setForm({ ...form, submit: "Create Account", notification: "" });
      } else {
        setForm({
          ...form,
          submit: "Sign Up for Notifications",
          notification:
            "Imprint is only available on iOS devices at this time. Click here to sign up to be notified about Android and other platforms.",
        });
      }
    }
  };
  const readyForSubmit = (
    formik: FormikProps<{
      email: string;
      password: string;
      isIOSUser: boolean;
    }>
  ) => {
    const { email, password } = formik.values;
    const { email: emailError, password: passwordError } = formik.errors;

    if (!!email && !!password && !emailError && !passwordError) {
      return true;
    }
    return false;
  };

  if (loading) {
    return <SubmitLoading />;
  }
  return (
    <Container>
      <HeaderContainer>
        <Header>
          <b>Create an Account</b>
        </Header>
        <SubHeader>
          Sync your progress, library, saved cards, and more.
        </SubHeader>
      </HeaderContainer>
      <Formik
        initialValues={{
          email: "",
          password: "",
          isIOSUser: false,
        }}
        validationSchema={userSchema}
        onSubmit={(values) => {}}
      >
        {(
          formik: FormikProps<{
            email: string;
            password: string;
            isIOSUser: boolean;
          }>
        ) => (
          <BodyContainer>
            <InputContainer>
              <Label hasValue={!!formik.values.email}>Email</Label>
              <StyledInput
                placeholder={formik.values.email ? "" : "Email"}
                name="email"
                type="text"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </InputContainer>
            <ErrorContainer>
              <ErrorText>{`${
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              } `}</ErrorText>
            </ErrorContainer>

            <InputContainer>
              <Label hasValue={!!formik.values.password}>Password</Label>
              <StyledInput
                type="password"
                value={formik.values.password}
                placeholder={formik.values.password ? "" : "Password"}
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </InputContainer>

            <ErrorContainer>
              <ErrorText>{`${
                formik.touched.password && !!formik.errors.password
                  ? formik.errors.password
                  : ""
              } `}</ErrorText>
            </ErrorContainer>
            <CheckBoxContainer>
              <CheckBoxLabel>
                <CheckBoxInput
                  type="checkbox"
                  checked={formik.values.isIOSUser}
                  onChange={() => handleIOSButtonChange(formik)}
                />
                <p>I own an iPad or iPhone</p>
              </CheckBoxLabel>
            </CheckBoxContainer>
            {form.notification && (
              <NonIOSContainer>{form.notification}</NonIOSContainer>
            )}
            <PlanContainer>
              <ButtonContainer
                onClick={(e) => handleSubmit(e, formik)}
                ready={readyForSubmit(formik)}
              >
                {form.submit}
              </ButtonContainer>
            </PlanContainer>
          </BodyContainer>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 48px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  font-family: GothamSSm;
  margin: 0px 24px;
  margin-top: -10px;
  p {
    margin: 0;
  }
`;

const ErrorContainer = styled.div`
  width: 90%;
  margin: auto;
`;
const Header = styled.h3`
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #2d7dd2;
  height: 37px;
  margin-bottom: 8px;
`;

const NonIOSContainer = styled.div`
  width: 90%;
  border-radius: 8px;
  padding: 16px 8px 16px 8px;
  background: #edeeef;
  margin: auto;
  font-family: GothamSSm;
  font-size: 12px;
  line-height: 14px;
  margin-top: 50px;
  color: #292032;
`;
const SubHeader = styled.p`
  max-width: 243px;
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  height: 48px;
  color: #716b78;
  font-size: 16px;
`;

const BodyContainer = styled.form`
  width: 100%;
  margin: auto;
  margin-top: 31px;
`;

const InputContainer = styled.div`
  height: 64px;
  width: 90%;
  border-radius: 8px;
  background: #fafafa;
  border: 2px solid #d2d5d8;
  border: 2px solid #716b78;

  box-sizing: border-box;
  border-radius: 8px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > * {
    margin: 0 16px;
  }
  margin-bottom: 8px;
`;

type StyledLabelProps = {
  hasValue: boolean;
};

const Label = styled.label<StyledLabelProps>`
  display: ${(props) => (props.hasValue ? "block" : "none")};
  font-family: GothamSSm;
  font-size: 13px;
  line-height: 13px;
  color: #8d8794;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  height: 13px;
  border: none;
  background: none;
  outline: none;
  :active {
    border: none;
    outline: none;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  height: 24px;
  width: 90%;
  margin: auto;
`;

const CheckBoxLabel = styled.label`
  display: inline-flex;
  flex-direction: row;
  margin-top: 16px;
  input {
    display: inline-block;
    height: 24px;
    width: 24px;
    margin: auto 0px;
    margin-right: 5px;
  }
  p {
    display: inline-block;
    font-family: GothamSSm;
    margin-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #8d8794;
    height: 20px;
    margin: auto 0;
  }
`;

const CheckBoxInput = styled.input`
  background: #fafafa;
  border: 2px solid #d2d5d8;
  box-sizing: border-box;
  border-radius: 4px;
`;
type ButtonType = {
  ready: boolean;
};

const PlanContainer = styled.div`
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 100px;
`;

const ButtonContainer = styled.div<ButtonType>`
  padding: 16px;
  width: 90%;
  height: 52px;
  background: linear-gradient(
      90deg,
      rgba(115, 123, 253, 0.75) 1.68%,
      rgba(50, 123, 210, 0.75) 116.81%
    ),
    #1e85e2;
  border-radius: 32px;
  margin: auto;
  font-family: GothamSSm;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  opacity: ${(props) => (props.ready ? 1 : 0.5)};
  color: #fefefe;
  cursor: ${(props) => (props.ready ? "pointer" : "default")};
  margin-top: 10px;
`;
