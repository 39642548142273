import * as React from "react";
import styled from "styled-components";
import { device } from "../../utils/variables";

type Props = {
  el: string;
  index: number;
  selected: boolean;
  handleClick: (index: number) => void;
};

export const SingleSelectAnswer = ({
  el,
  index,
  handleClick,
  selected,
}: Props) => {
  return (
    <SingleSelectContainer
      selected={selected}
      key={index}
      onClick={(e) => handleClick(index)}
    >
      <SingleSelectElement>
        <p>{el}</p>
      </SingleSelectElement>
    </SingleSelectContainer>
  );
};

type SingleSelectProp = {
  selected: boolean;
};

const SingleSelectContainer = styled.div<SingleSelectProp>`
  height: 64px;
  width: 295px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(0deg,rgba(80, 149, 235, 0.4),rgba(80, 149, 235, 0.4)),#fafafa"
      : "#fafafa"};
  border: 2px solid #d2d5d8;
  border-color: ${(props) => (props.selected ? "#5095EB66" : "#d2d5d8")};
  box-sizing: border-box;
  border-radius: 8px;
  margin: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media ${device.mobileSize} {
    height: 48px;
    width: 295px;
  }
  margin-bottom: 16px;
`;

const SingleSelectElement = styled.div`
  font-family: GothamSSm;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  border: none;
  text-align: center;
  background: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.mobileSize} {
  }
  outline: none;
`;
