import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
// import {
//   persistStore,
//   persistReducer,
//   persistCombineReducers,
//   createTransform,
//   PersistedState,
//   Persistor
// } from "redux-persist";
// import storage from "redux-persist/lib/storage";
import { AppActions } from "../types";
import { nodeReducer, onboardingReducer } from "../reducers";
// Used for Typescript
// -------------------
export const reducers = combineReducers({
  // place reducers here
  nodeReducer: nodeReducer,
  onboardingReducer: onboardingReducer,
});

export type AppState = ReturnType<typeof reducers>;
// -------------------
// Combine reduces
// const reducers = persistCombineReducers(persistConfig, {

// });

// create redux store
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
const store = createStore(
  reducers,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);
// Create redux-persist instance

export { store };
