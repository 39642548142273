import * as React from "react";
import styled from "styled-components";

type Props = {
  answers: string;
  handleFormChange: (e: any) => void;
};

export const TextTypeAnswer = ({ answers, handleFormChange }: Props) => {
  return (
    <AnswerStyleContainer>
      <AnswerStyle
        type="text"
        placeholder={answers}
        onChange={handleFormChange}
      />
    </AnswerStyleContainer>
  );
};

const AnswerStyleContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const AnswerStyle = styled.input`
  margin: auto;
  width: 295px;
  height: 64px;
  background: #fafafa;
  border: 2px solid #d2d5d8;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: GothamSSm;
  font-size: 16px;
  /* line-height: 13px; */
  letter-spacing: 0em;
  text-align: left;
  padding: 10px;
  margin-top: 30%;
  -webkit-appearance: none;
  :focus {
    border: none;
  }
`;
