/* eslint-disable @typescript-eslint/no-unused-vars */
import anime from "animejs";
import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { device } from "../../utils/variables";

interface Props {}

export const EmailNotification = (props: Props) => {
  const textTransition = React.createRef() as any;

  console.log("hit here on email notification ");
  useEffect(() => {
    handleAnimation();
  });

  const handleAnimation = () => {
    anime({
      targets: textTransition.current,
      duration: 500,
      translateX: [0, 15],
      opacity: [0.2],
      direction: "reverse",
      easing: "linear",
    });
  };

  return (
    <FlexContainer>
      <AppContainer>
        <Container ref={textTransition}>
          <TextContainer>
            <Header>Done!</Header>
            <Body>Your notification preferences have been set.</Body>
          </TextContainer>
        </Container>
      </AppContainer>
    </FlexContainer>
  );
};

const Logo = styled.img`
  position: absolute;
  top: -5%;
`;

const TextContainer = styled.div`
  width: 85%;
  margin: auto;
  margin-top: 200px;
`;

const Header = styled.h3`
  height: 37px;
  left: 0px;
  top: 0px;
  font-family: GothamSSm;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 130.6%;
  color: #2d7dd2;
  margin: 0;
`;

const Body = styled.p`
  height: 48px;
  font-family: GothamSSm;
  font-size: 16px;
  line-height: 24px;
  color: #716b78;
`;
const FlexContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #eef2f6;
`;

const AppContainer = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 375px;
  margin: 0 auto;
  position: relative;
  height: 780px;

  @media ${device.mobileSize} {
    width: 343px;
    height: 640px;
  }
  & > * {
    max-height: 640px;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;
