import { CardNode, ChapterNode, WebUser } from "../firebase/responseTypes";

//= ========================
// Action types
//= ========================

//= ========================
// Node Action types
//= ========================

export const FETCH_NODE_CHILDREN = "FETCH_NODE_CHILDREN";
export const START_FETCHING_NODE_CHILDREN = "START_FETCHING_NODE_CHILDREN";
export const INCREASE_INDEX = "INCREASE_INDEX";
export const DECREASE_INDEX = "DECREASE_INDEX";
export const SET_INDEX = "SET_INDEX";
export const RESET_INDEX = "RESET_INDEX";
export const DISABLED_WEB = "DISABLED_WEB";
export const SET_BRANCH_LINK = "SET_BRANCH_LINK";
export const SET_ACTION_COUNT = "SET_ACTION_COUNT";
export const SHOW_PITCH = "SHOW_PITCH";
export const DISMISS_PITCH = "DISMISS_PITCH";
export const SET_BOOK = "SET_BOOK";
export const SET_CHAPTER = "SET_CHAPTER";
export const SET_BOOK_NODE = "SET_BOOK_NODE";
export const SET_BOOK_COVER = "SET_BOOK_COVER";
export const SET_WEB_USER = "SET_WEB_USER";
export const DISABLE_CARD_NAVIGATION = "DISABLE_CARD_NAVIGATION";
export const ENABLE_CARD_NAVIGATION = "ENABLE_CARD_NAVIGATION";
export const SET_DIRECTION = "SET_DIRECTION";
export const SET_OFFSET_X_POSITION = "SET_OFFSET_X_POSITION";
export const ADD_ANSWER = "ADD_ANSWER";

export interface FetchNodeChildren {
  type: typeof FETCH_NODE_CHILDREN;
  // node children type
  childNodes: CardNode[];
}

export interface StartFetchingNodeChildren {
  type: typeof START_FETCHING_NODE_CHILDREN;
}

export interface IncreaseIndex {
  type: typeof INCREASE_INDEX;
}

export interface DecreaseIndex {
  type: typeof DECREASE_INDEX;
}

export interface ResetIndex {
  type: typeof RESET_INDEX;
}

export interface SetIndex {
  type: typeof SET_INDEX;
  index: number;
}

export interface SetBranchLink {
  type: typeof SET_BRANCH_LINK;
  branch_link: string;
}

export interface DisableWeb {
  type: typeof DISABLED_WEB;
  disabled: boolean;
}

export interface SetActionCount {
  type: typeof SET_ACTION_COUNT;
}

export interface ShowPitch {
  type: typeof SHOW_PITCH;
}
export interface DismissPitch {
  type: typeof DISMISS_PITCH;
}

export interface SetBook {
  type: typeof SET_BOOK;
  book: ChapterNode[];
}

export interface SetBookNode {
  type: typeof SET_BOOK_NODE;
  book_node: ChapterNode;
}
export interface SetChapter {
  type: typeof SET_CHAPTER;
  chapter: ChapterNode;
}

export interface SetBookCover {
  type: typeof SET_BOOK_COVER;
  book_cover: string;
}

export interface SetWebUser {
  type: typeof SET_WEB_USER;
  user: WebUser;
}

export interface EnableCardNavigation {
  type: typeof ENABLE_CARD_NAVIGATION;
}

export interface DisableCardNavigation {
  type: typeof DISABLE_CARD_NAVIGATION;
}

export interface SetDirection {
  type: typeof SET_DIRECTION;
  direction: string;
}

export interface setOffsetXPosition {
  type: typeof SET_OFFSET_X_POSITION;
  offsetX: number;
}

//MultiCard Actions

export const DECREASE_MULTI_CARD_COUNT = "DECREASE_MULTI_CARD_COUNT";
export const INCREASE_MULTI_CARD_COUNT = "INCREASE_MULTI_CARD_COUNT";
export const SET_MULTI_CARD_COUNT = "SET_MULTI_CARD_COUNT";
export const SET_COUNT_LIMIT = "SET_COUNT_LIMIT";
export const SET_MULTI_CARD_LINE_COUNT = "SET_MULTI_CARD_LINE_COUNT";

export interface decreaseMultiCardCount {
  type: typeof DECREASE_MULTI_CARD_COUNT;
}

export interface increaseMultiCardCount {
  type: typeof INCREASE_MULTI_CARD_COUNT;
}

export interface setMultiCardCount {
  type: typeof SET_MULTI_CARD_COUNT;
  count: number;
}

export interface setCountLimit {
  type: typeof SET_COUNT_LIMIT;
  count: number;
}

export interface setMultiCardLineCount {
  type: typeof SET_MULTI_CARD_LINE_COUNT;
  lines: number;
}

export type NodeActionType =
  | FetchNodeChildren
  | StartFetchingNodeChildren
  | DecreaseIndex
  | IncreaseIndex
  | SetIndex
  | ResetIndex
  | DisableWeb
  | SetBranchLink
  | SetActionCount
  | ShowPitch
  | DismissPitch
  | SetBook
  | SetChapter
  | SetBookNode
  | SetBookCover
  | SetWebUser
  | EnableCardNavigation
  | DisableCardNavigation
  | SetDirection
  | decreaseMultiCardCount
  | increaseMultiCardCount
  | setMultiCardCount
  | setMultiCardLineCount
  | setCountLimit
  | setOffsetXPosition;

export type AppActions = NodeActionType;
